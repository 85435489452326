<template>
    <div class="relative comments-container mx-auto w-2/3">
        <div class="flex flex-col justify-start">                
            <div class="gap-5 px-12 pt-0 pb-5 pt-8 comment-form" v-if="!disabled">
                <div class="w-full flex gap-5">
                    <FormulateInput
                        type="textarea"
                        class="w-full"
                        placeholder="Write a note..."
                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                        v-model="newComment"
                        :rows="1"
                    />
                    
                </div>
                <div class="float-right">
                    <base-button size="sm" theme="dark" label="Save" :disabled="buttonDisabled" @action="addComment" class="float-right mt-5 ml-3 font-bold" />    
                    
                    <div class="float-right file-form mt-6">

                        <FormulateInput
                            type="image"
                            name="file"
                            validation="mime:image/jpeg,image/png,image/gif"
                            :uploader="uploadCommentFileHandler"
                            @file-removed="removeFile"
                            :key="imageKey"
                        />
                    </div>
                </div>
            </div>

            <div class="flex justify-between px-12 notes-title">
                <div class="text-h4 mb-5 font-bold text-purple-m-secondary">
                    Notes
                    <span class="text-gray-m-disable">({{ comments ? comments.length : 0 }})</span>
                </div>
            </div>
            <div class="flex flex-col gap-10 pb-10 px-12 overflow-y-auto" style="max-height: 30rem;" v-if="comments.length" >
                <comment v-for="comment in comments" :disabled="disabled" :key="comment.id" @updateComment="updateCommentFn" :currentUser="user" :comment="comment"/>
            </div>
            <section-empty-state v-if="!comments.length" icon="comments" message="This section has no comments yet."/>
        </div>

        <!-- <div class="comment-form absolute bg-white bottom-0 w-full h-20 border-t border-gray-m-light bottom grid grid-cols-12 items-center">
            <div class="md:col-span-10 col-span-8 pl-6">
                <FormulateInput
                    type="text"                    
                    placeholder="Write a comment..."
                    v-model="newComment"
                    @keyup.enter="addComment" 
                    element-class="h-full"
                    wrapper-class="border-0"
                    input-class="h-full w-full focus:outline-none text-pm text-purple-m-secondary"                 
                />
            </div>
            <div class="col-span-2 md:col-span-1 flex justify-center file-form">
                <FormulateInput
                    type="image"
                    name="file"
                    validation="mime:image/jpeg,image/png,image/gif"
                    :uploader="uploadCommentFileHandler"
                    @file-removed="removeFile"
                    :key="imageKey"
                />
            </div>
            <div class="col-span-2 md:col-span-1 flex justify-center">
                <base-button @action="addComment" :disabled="buttonDisabled" icon="arrow-right" size="xs" class="h-12" :icon-size="4"/>
            </div>
        </div> -->

        <!-- <div class="flex flex-col gap-6 pt-6 px-6 overflow-y-scroll" v-if="comments.length" :style="commentsHeight">
            <comment v-for="comment in comments" :key="comment.id" @updateComment="updateCommentFn" :currentUser="user" :comment="comment"/>
        </div>
        <section-empty-state v-if="!comments.length" icon="comments" message="This section has no comments yet."/> -->
    </div>
</template>

<script>
import { fileUpload } from '../../common';
import SectionEmptyState from '../../components/SectionEmptyState';
import { mapGetters } from 'vuex';

export default {
    components: {SectionEmptyState},
    props: {
        comments: {
            type: Array,
            default: function () {
                return [];
            }
        },
        route: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            required: true
        },
        project: {
            type: Object,
            default: function () {
                return {}
            }
        },
        propBaseItemsHeight: {
            type: Number,
            default: 0
        },
        propBaseCommentsHeight: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            newComment: '',
            screen: {},
            uploadedFile: null,
            submittingFile: false,
            imageKey: false,
        }
    },

    computed: {
        ...mapGetters(['user']),
        buttonDisabled() {
            return this.newComment === '' || this.submittingFile;
        },

        itemHeight() {
            return `min-height: calc(100vh - ${this.propBaseItemsHeight}px)`;
        },

        commentsHeight() {
            return `min-height: calc(100vh - ${this.propBaseItemsHeight}px);max-height: calc(100vh - ${this.propBaseItemsHeight}px)`;
        },

        /* notesContainerHeight () {
            return `min-height: calc(100vh - ${this.baseItemsHeight}px);max-height: calc(100vh - ${this.baseItemsHeight}px)`;
        }, */

        /* notesHeight() {
            return `min-height: calc(100vh - ${this.commentsHeight}px);max-height: calc(100vh - ${this.commentsHeight}px)`;
        } */
    },

    methods: {
        async addComment() {
            if (this.newComment !== '') {
                const {data} = await this.$http.post(this.route, {comment: this.newComment, image: this.uploadedFile});
                if (data) {
                    this.newComment = '';
                    this.uploadedFile = null;
                    this.imageKey = !this.imageKey;
                    this.$notify({ title: 'Success', text: 'Comment added successfully', type: 'success' });
                    this.$emit('comments-updated');
                }
                else {
                    this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' });
                }
            }
        },

        updateCommentFn() {
            this.$emit('comments-updated');
        },

        async uploadCommentFileHandler(file, progress, error, option) {
            try {
                this.submittingFile = true;
                const [uploadedFile] = await fileUpload(file, {
                    projectId: this.project.id,
                    userId: this.user.id || null
                });
                this.uploadedFile = uploadedFile.id;
                progress(100);
                return [{url: this.uploadedFile.id}];
            } catch (error) {
                this.$notify({ title: 'Error', text: 'The file can not be submitted. Try again later.', type: 'error' });
                return [{url: 'error'}];
            } finally {
                this.submittingFile = false;
            }
        },

        removeFile() {
            this.uploadedFile = null;
        }
    }
}
</script>

<style lang="scss">
    .file-form {
        .formulate-input {
            @apply w-10 h-10;
            .formulate-input-upload-area {
                @apply w-10 h-10 p-0;
                input {
                    @apply w-10 h-10;
                }

                .formulate-input-upload-area-mask {
                    @apply w-10 h-10 border-0;

                    &:before {
                        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" /></svg>');
                    }
                }

                .formulate-file {
                    @apply p-0;

                    .formulate-file-name {
                        display: none;
                    }
                }
            }
        }
    }

    .file-form > div,
    .file-form > div > div,
    .file-form > div > div > div,
    .file-form > div > div > div > div {
        @apply w-10 h-10 shadow-none;
    }
</style>