<template>
  <!--
    <div :class="[inlineClass, inline ? 'flex flex-row justify-center gap-1' : '', pointer ? 'cursor-pointer' : '', bold ? 'font-bold' : '', uppercase ? 'uppercase' : '']" v-tooltip="tooltip ? { content: tooltip, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : null">
      <p class="flex justify-center items-center"><base-icon :name="icon" v-if="icon" :size="3"/></p>
      <p class="stat-count text-xs">{{ valueData }}</p>
    </div> 
  -->
  <div 
    class="report-post-card-detail-wrapper" 
    @mouseover="boostedStatBreakdownTooltipVisible = true"
    @mouseleave="boostedStatBreakdownTooltipVisible = false"
  >
    
    <boosted-stat-breakdown-tooltip
      v-if="breakdown && boostedStatBreakdownTooltipVisible"
      :value="breakdown"
    />

    <!-- stat card -->
    <stat-card
      :value="valueData" 
      :icon-size="2"
      :card-size="4"
      :bold="false"
      value-class="text-sm"
      :gap="0.5"
      :icon="icon"
      type="mini"
      :tooltip="computedTooltip"
    />
    <!-- if breakdown prop is set, we dont show the regular tooltip -->
  </div>
</template>

<script>
import BoostedStatBreakdownTooltip from './BoostedStatBreakdownTooltip.vue';
import StatCard from './StatCard.vue'
import { isValidObject, isValidString } from '../common';
export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
      default: '0'
    },
    emptyValue: {
      type: String,
      default: '-'
    },
    icon: {
      type: String
    },
    tooltip: {
      type: String
    },
    breakdown: {
      type: Object,
      required: false,
      default: null
    },
    bold: {
      type: Boolean,
      default: true
    },
    uppercase: {
      type: Boolean,
      default: true
    },
    inlineClass: {
      type: String,
      default: 'flex-grow'
    },
    inline: {
      type: Boolean,
      default: false
    },
    pointer: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    StatCard,
    BoostedStatBreakdownTooltip
  },
  data() {
    return {
      boostedStatBreakdownTooltipVisible: false
    }
  },
  computed: {
    computedTooltip(){
      return (!isValidObject(this.breakdown) && isValidString(this.tooltip)) ? this.tooltip : null;
    },
    valueData() {
      if (this.value === 0 || this.value === '0') {
        return this.emptyValue;
      }
      return this.value;
    }
  }
}
</script>
<style lang="scss" scoped>
  .report-post-card-detail-wrapper{
    @apply relative cursor-pointer;
  }
</style>
