<template>
    <comments
        :key="key"
        :project="project"
        :disabled="project.deleted_at !== null && project.deleted_at !== undefined" 
        @comments-updated="getComments()"
        :comments="comments"
        :prop-base-items-height="baseItemsHeight"
        :prop-base-comments-height="commentsHeight"
        :route="`/api/projects/${project.id}/comment`"
    />
</template>

<script>
import Comments from './Comments.vue';
export default {
    props: ['project'],
    components: {
        Comments
    },  
    data() {
        return {
            comments: [],
            key: 0,
                       
            baseItemsHeight: 0,
            baseFormHeight: 0,
            commentsHeight: 0,

            areas: ['.white-menu-responsive', '.user-presentation']
        }
    },

    watch: {
        viewTab(){
            this.alterCommentsKey();
        }
    },
    
    beforeDestroy() {
        window.removeEventListener('resize', this.alterCommentsKey);
    },

    mounted() {
        this.getComments()
        window.addEventListener('resize', this.alterCommentsKey);
        this.alterCommentsKey();
    },

    methods: {
        async getComments() {
            const { data } = await this.$http.get(`/api/projects/${this.$route.params.projectId}/comments`);
            this.key++
            this.comments = data.comments ? data.comments.reverse() : [];
        },
        alterCommentsKey() {
            let len = 0;
            for (let i in this.areas) {
                let item = document.querySelector(this.areas[i]);
                if (item) {
                    len = len + item.offsetHeight;
                }
            }

            let commentForm = document.querySelector('.comment-form');
            let baseComments = 0;
            if (commentForm) {
                baseComments = commentForm.offsetHeight;
            }

            this.baseItemsHeight = len;
            this.baseFormHeight = baseComments;
            this.commentsHeight = this.baseFormHeight + this.baseItemsHeight;
        },
    }
}
</script>