<template>
    <base-modal 
        :title="`The connected Facebook page does not have access to the Instagram account ${handle}`"
        :visible="visible"
        noPadding
        @close="$emit('close')"
    >
        <div class="account-mismatch-modal-container">
            <div class="content">

                <!-- current connection facebook page -->
                <div 
                    class="current-page" 
                    v-if="isValidObject(currentPage)"
                >
                    <avatar :user="{ avatar: currentPage.picture }" />
                    <div class="info">
                        <div class="name">{{ currentPage.first_name }} {{ currentPage.last_name }}</div>
                        <div class="email">{{ currentPage.email }}</div>
                    </div>
                    <div class="user-id">
                        User ID: {{ currentPage.userId }}
                    </div>
                </div>
                
                <!-- main message -->
                <div>The provided Facebook page does not have access to the Instagram account <b>{{handle}}</b>.</div>

                <!-- current connection accounts -->
                <div class="current-accounts" v-if="isValidArray(currentAccounts)">
                    <div class="title">It only has access to the following Instagram accounts:</div>
                    <div class="list">
                        <div 
                            class="item"
                            v-for="(account, index) in currentAccounts"
                            :key="index"
                        >
                            <base-icon name="instagram" />
                            <div>{{ account }}</div>
                        </div>
                    </div>
                </div>

                <div>Follow the steps below to ensure you are connecting the right Facebook page{{ isValidString(handle) ? `, with access to the Instagram account ${handle}` : `` }}.</div>
                <ul class="list">
                    <li>On mobile, go to your Instagram profile</li>
                    <li>Click "Edit profile"</li>
                    <li>Under "Profile information" select "Page"</li>
                    <li>Ensure the correct Facebook Page is linked to your Instagram account</li>
                    <li>If not, "Change or create Page"</li>
                </ul>
            </div>
            <div class="actions-footer">
                <div
                    class="action-item" 
                    v-for="(action, index) in actions" 
                    :key="index"
                    @click="() => action.handler()"
                >
                    <base-icon :name="action.icon" />
                    <div class="content">
                        <div class="title">{{action.title}}</div>
                        <div class="description">{{action.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>
<script>
import { isValidArray, isValidObject, isValidString } from '../../common';
export default {
    props: [
        'visible', 
        'handle', 
        'currentConnectionInfo'
    ],
    data() {
        return {
            isValidString,
            isValidArray,
            isValidObject
        }
    },
    computed: {
        isSocialLoginView(){
            return isValidObject(this.$route) && isValidString(this.$route.name) && this.$route.name == 'social-login';
        },
        currentPage(){
            if(isValidObject(this.currentConnectionInfo) && isValidObject(this.currentConnectionInfo.object)){
                // it should at least have these properties
                const { picture, first_name, last_name } = this.currentConnectionInfo.object;
                if(picture, first_name, last_name){
                    return this.currentConnectionInfo.object;
                }
            }
            return {}
        },
        currentAccounts(){
            if(isValidObject(this.currentConnectionInfo) && isValidArray(this.currentConnectionInfo.accounts)){
                return this.currentConnectionInfo.accounts;
            }
            return [];
        },
        actions(){
            return [
                {
                    icon: 'link',
                    title: 'Reconnect',
                    description: 'Connect another Facebook page',
                    handler: () => {
                        this.$emit('reconnect');
                    }
                },
                ...( isValidString(this.handle) ? [ {
                    icon: 'refresh',
                    title: 'Request handle change',
                    description: `Notify the admin to update your handle if ${this.handle} is not matching your social handle.`,
                    handler: () => {
                        this.$emit('request-handle-change');
                    }
                } ] : []),
                // We will disable this action in the social login view for now, 
                // as we dont want creators to continue if they dont connect the right page.
                ...(!this.isSocialLoginView ? [
                    {
                        icon: 'close',
                        title: 'Continue without access',
                        description: `Continue without access to ${isValidString(this.handle) ? `the Instagram account ${this.handle}.` : `any Instagram account.`}`,
                        handler: () => {
                            this.$emit('continue-without-access');
                        }
                    }
                ] : [])
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .account-mismatch-modal-container{
        > .content{
            @apply flex flex-col pt-2 px-7;
            > .current-page{
                @apply relative cursor-pointer mb-3;
                @apply flex gap-x-3 items-center;
                > .info{
                    // @apply 
                    > .name{
                        @apply font-bold;
                    }
                    > .email{
                        @apply text-pxs;
                    }
                }
                &:hover{
                    > .user-id{
                        @apply visible;
                    }
                }
                > .user-id{
                    @apply invisible;
                    @apply absolute bg-white border border-gray-500 rounded-lg px-4 py-2 text-pxs;
                    left: 0px;
                    top: 0px;
                }
            }
            > .current-accounts{
                @apply my-2;
                > .title{
                    //
                }
                > .list{
                    @apply flex flex-col gap-x-3;
                    > .item{
                        @apply flex gap-x-2 items-center;
                        @apply font-bold;
                    }
                }
            }
            > .list{
                @apply mt-6 pl-5 flex flex-col gap-y-4 list-disc text-left;
            }
        }
        .actions-footer{
            // bg-white rounded-xl
            @apply w-full pt-10 pb-16 px-6;
            @apply flex justify-between flex-wrap;
            > .action-item{
                @apply w-full py-4 px-4 border-b border-gray-300;
                @apply flex items-center gap-x-4;
                @apply cursor-pointer transition-colors duration-300;
                &:hover{
                    @apply bg-purple-m-main text-white rounded-lg; 
                }
                // icon
                > .content{
                    > .title{
                        @apply font-bold;
                    }
                    > .description {
                        //
                    }
                }
            }
        }
    }
</style>