<template>
    <div :class="outterClass">

        <FormulateInput
            type="text"
            :label="label"
            class="w-full"
            :placeholder="`${name} Handle`"
            :id="`${slug}-checker`"
            v-model="content"
            @input="handle"
            v-debounce="(inputValue, e) => checkHandle(inputValue)"
        />
        <div v-if="errorMessage" class="text-pxs font-bold">{{errorMessage}}</div>

        <!-- Loader -->
        <span v-if="loading" class="absolute right-2.5 top-2.5">
            <base-icon name="loader" />
        </span>

        <!-- Info box for instagram -->
        <span v-if="network.id == 2 && fbLogin.error" class="absolute right-2.5 top-2.5">
            <base-icon 
                name="info-circle"
                v-tooltip="{ 
                    content: fbLogin.computedMessage('search users'), 
                    classes: 'bg-black text-white rounded py-1 px-2 text-xs w-40', 
                    delay: { show: 100, hide: 50 } 
                }"
            />
        </span>

        <!-- Dropdown -->
        <div v-if="showOptions" :id="`${slug}-options`" class="w-full rounded-md bg-white shadow-lg border-2 border-gray-m-light absolute">
            <ul
                tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                class="max-h-56 text-base leading-6 shadow-lg overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                v-if="options.length"
            >
                <li
                    v-for="(influencer, i) in options"
                    :key="i"
                    @click="setUser(influencer.username)"
                    class="text-black cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-m-light"
                    role="option"
                >
                    <div class="flex items-center space-x-3">
                        <img :src="influencer.picture || influencer.profile_picture_url" alt="" class="flex-shrink-0 h-6 w-6 rounded-full">
                        <span class="font-normal block truncate">{{ influencer.username }}</span>
                    </div>

                    <span v-if="content === influencer.username"
                      class="absolute inset-y-0 right-0 flex items-center pr-3">
                        <base-icon name="check" />
                    </span>
                </li>
            </ul>
            <div v-else class="empty-state">
                
                <div v-if="network.id == 2 && igMessageVisible" class="alert" role="alert">
                    <svg 
                        class="fill-current w-6 h-6 mr-2" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 20 20"
                    >
                        <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
                    </svg>
                    <div>
                        <p>The creator must be an Instagram Business or Creator IG User, in order for us to find it and automatically retrieve data.</p>
                        <div class="mt-4 mb-2">Invite this creator to change their account to an Instagram Business or Creator IG User using the button below.</div>
                        <base-button
                            label="Invite"
                            bold
                            class="mt-4"
                            @action="inviteToChangeIgAccountModalVisible = true"
                        />
                    </div>
                    <invite-to-change-ig-account-modal
                        :visible="inviteToChangeIgAccountModalVisible"
                        @close="inviteToChangeIgAccountModalVisible = false"
                    />
                </div>

                <div class="p-8">This creator has no available options. You can still proceed with creation, but automatic data retrieval will not be supported.</div>

            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { isValidObject, isValidString } from '../common';
import { getParamsFromProfileUrl } from '../lib/network';
import { validateUrl } from '../lib/strings';
import InviteToChangeIgAccountModal from './InviteToChangeIgAccountModal.vue';
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        outterClass: {
            type: String,
            default: 'relative'
        },
        name: {
            type: String,
            default: ''
        },
        slug: {
            type: String,
            default: ''
        },
        network: {},
        target: {
            type: Object,
            default: function () {
                return {};
            }
        },
        value: {}
    },
    components: { 
        InviteToChangeIgAccountModal 
    },
    data() {
        return {
            loading: false,
            showOptions: false,
            options: [],
            content: this.value,
            igMessageVisible: false,
            errorMessage: null,
            inviteToChangeIgAccountModalVisible: false
        }
    },
    watch: {
        showOptions: function (val) {
            if (val) {
                this.addListener();
            } else {
                this.removeListener();
            }
        }
    },
    computed: {
        ...mapGetters(['fbLogin']),
    },
    methods: {
        handle(e) {
            this.$emit('input', this.content);
        },
        setUser(user) {
            this.content = user;
            this.$emit('input', user);
            this.showOptions = false;
        },
        addListener() {
            window.addEventListener('click', this.listenerHandle);
        },
        removeListener() {
            window.addEventListener('click', this.listenerHandle);
        },
        listenerHandle(e) {
            let item = document.getElementById(`${this.slug}-options`);
            if (item && !item.contains(e.target)) {
                this.showOptions = false;
            }
        },
        async checkHandle(inputValue) {
            if(inputValue.length < 4){
                return false
            }
            this.errorMessage = null;
            this.loading = true;
            
            // if its a social profile url, we will get its paramaters
            // and if we find the handle we change the inputValue
            // and the content var (and emit this change)
            if(validateUrl(inputValue)){
                const params = getParamsFromProfileUrl(inputValue);
                if(params && isValidString(params.handle)){
                    this.content = params.handle;
                    this.$emit('input', this.content);
                    inputValue = params.handle;
                }else{
                    return this.$notify({ title: 'Unable to get handle from profile URL', text: 'Check the value', type: 'error' })
                }
            }

            try {
                this.igMessageVisible = false;
                this.showOptions = false;
                this.options = [];
                this.$emit('toggle-search', true);
                
                const { data } = await this.$http.get(`/api/social-api-service/user-lookup?handle=${inputValue}&platform=${this.slug}`);

                const { exists, results, users } = data;
                // exists: if the handle exists on our db
                // results: the scraped results for the handle
                // users: users that already have that handle
                
                // does not exist on database but there are no scraped results either 
                if(!exists && !users.length && !isValidObject(results)){
                    this.showOptions = true;
                    this.igMessageVisible = true;
                }

                // user does not exist on the database, we will show the scraped results
                if (!exists || (exists && users && Array.isArray(users) && !users.length)) {
                    if(isValidObject(results)){
                        this.options = [results];
                        this.showOptions = true;
                    }
                }

                // if there are users with that handle already, show a message
                if(exists && users && Array.isArray(users) && users.length){
                    this.errorMessage = 'A creator in this company is already using this handle.';
                }

                this.$emit('users-exists', users);
            } catch(e) {
                console.log("error fetching creator lookup", e);
            } finally {
                this.loading = false
                this.$emit('toggle-search', false);
            }

        },
    }
}
</script>
<style lang="scss" scoped>
    .alert{
        @apply flex items-center gap-x-4 bg-blue-500 text-white text-left text-pxs px-4 py-3;
    }
    .empty-state{
        @apply max-h-56 text-base text-center leading-6 shadow-lg overflow-auto;
        &:focus{
            @apply outline-none;
        }
        @media (min-width: 640px) {
            @apply leading-5 text-sm;
        } 
    }

</style>