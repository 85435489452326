<template>
    <base-modal
        v-if="title"
        :title="title"
        :visible="visible"
        classes="max-w-4xl max-h-screen	overflow-y-auto"
        no-padding
        center-title
        :hideCloseButton="!isManual"
        @close="close"
    >
        <div class="wrapper">

            <div class="loading-text" v-if="loading">Please wait...</div>
            
            <template v-else>
                
                <div class="container" v-if="step == 1">
                    
                    <!-- project info section -->
                    <div class="project-info" v-if="!isManual">

                        <div class="project-info-wrapper">
                            
                            <div class="project-info-container">

                                <div class="name">{{ userDeliverableProject }} - {{ userDeliverableName }}</div>
                    
                                <div class="content">
                                    <div class="requested-by">
                                        <template v-if="projectUserDeliverable.owner">
                                            <div class="text">Requested by</div> 
                                            <avatar-list 
                                                class="font-semibold text-h6 text-purple-m-secondary"
                                                nameClasses="w-40" 
                                                :users="[projectUserDeliverable.owner]" 
                                                cursor="pointer" 
                                            />
                                        </template>
                                    </div>
                                    <base-button
                                        v-if="briefExists"
                                        @action="projectBriefVisible = !projectBriefVisible"
                                        type="label" 
                                        size="lg"
                                        class="font-semibold text-h6 text-purple-m-secondary"
                                        :label="projectBriefVisible ? 'Hide Project Brief' : 'View Project Brief'" 
                                        :secondary-icon="projectBriefVisible ? 'chevron-up' : 'chevron-down'"
                                    />
                                </div>

                            </div>

                            <template v-if="briefExists && projectBriefVisible">
                                
                                <div class="separator"/>
                                
                                <div class="brief-container">
                                    <!-- <div class="enriched-format" v-html="projectUserDeliverable.project_user.project.brief" /> -->
                                    <div class="brief label enriched-format" v-html="projectUserDeliverable.project_user.project.brief"></div>
                                    
                                    <div class="brief-attachment-container" v-if="projectUserDeliverable.project_user.project.brief_attachment !== null">
                                        <div 
                                            v-for="(attachment, index) in [projectUserDeliverable.project_user.project.brief_attachment]" 
                                            :key="index"
                                            @click="$emit('showBriefAttachment', attachment)"
                                            class="brief-attachment"
                                        >
                                            <div class="icon-wrapper">
                                                <div class="icon-container">
                                                    <base-icon class="text-purple-m-main" :size="3" name="file" />
                                                </div>
                                            </div>
                                            <div>
                                                {{attachment.name}}
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </template>

                        </div>

                    </div>

                    <div class="form-container">

                        <div v-if="projectUserDeliverable.is_completed" class="text">This deliverable is completed.</div>

                        <div class="left-column" v-if="!projectUserDeliverable.is_completed">
                            
                            <FormulateInput
                                type="text"
                                label="Name"
                                class="w-full col-span-2"
                                v-model="name"
                                :input-class="['focus:shadow-none', 'resize-none']"
                                :label-class="['font-bold']"
                            />

                            <div class="caption" v-if="projectUserDeliverable && projectUserDeliverable.deliverable">
                                <vue-editor 
                                    v-if="this.conceptPhase"
                                    v-model="description"
                                    class="w-full min-ql-h"
                                    :placeholder="`Enter ${ descriptionDisplayName }`"
                                    :editorToolbar="$customToolbar()"
                                />
                                <FormulateInput
                                    v-else
                                    v-model="description"
                                    type="textarea"
                                    :placeholder="`Enter ${ descriptionDisplayName } ${ descriptionDisplayName.toLowerCase() === 'draft' ? `caption` : `` }`"
                                    :label="`${descriptionDisplayName} ${ descriptionDisplayName.toLowerCase() === 'draft' ? `caption` : `` }`"
                                    @input="handleInput"
                                    :maxlength="projectUserDeliverable.deliverable.max_characters"
                                    :rows="3"
                                    class="w-full col-span-2"
                                    :input-class="['focus:shadow-none', 'resize-none']"
                                    :label-class="['font-bold']"
                                />
                                <div class="char-limit">
                                    <div class="text">{{description.length}}/{{max_chars}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="right-column" v-if="projectUserDeliverable && projectUserDeliverable.deliverable && draftPhase">
                            <div class="title">{{projectUserDeliverable.deliverable.name}} requirements</div>
                            <div class="separator" />
                            <div class="requirement-list">
                                <div v-if="projectUserDeliverable.deliverable.max_video_duration" class="requirement">Max Video Duration: {{projectUserDeliverable.deliverable.max_video_duration}} sec</div>
                                <div v-if="projectUserDeliverable.deliverable.max_characters" class="requirement">Max Characters: {{projectUserDeliverable.deliverable.max_characters}}</div>
                                <div v-if="projectUserDeliverable.deliverable.max_file_size" class="requirement">Max File Size: {{maxFileSize}}</div>
                                <div v-if="projectUserDeliverable.deliverable.required_file_type" class="requirement">Available File Types: {{projectUserDeliverable.deliverable.required_file_type.join(', ')}}</div>
                                <div v-if="projectUserDeliverable.deliverable.min_aspect_ratio" class="requirement">Min Aspect Ratio: {{projectUserDeliverable.deliverable.min_aspect_ratio}}</div>
                                <div v-if="projectUserDeliverable.deliverable.max_aspect_ratio" class="requirement">Max Aspect Ratio: {{projectUserDeliverable.deliverable.max_aspect_ratio}}</div>
                            </div>

                            <drive-picker :visible="openDrive" @set-files="setDriveFiles"/>

                            <FormulateInput id="file" type="file" :uploader="addFile" class="invisible w-0 h-0"/>

                            <dropdown :options="fileOptions" @changed="fileOptionsHandler" class="mt-4 w-48" icons>
                                <base-button icon="media" theme="dark" label="Add media" bold :outline="false" size="lg"/>
                            </dropdown>

                            <div v-if="addingLink" class="flex items-center gap-x-4">
                                <FormulateInput
                                    type="text"
                                    label="External Link"
                                    class="w-full col-span-2"
                                    placeholder="https://"
                                    v-model="link"
                                    @keypress.enter="addLink"
                                    :input-class="['focus:shadow-none', 'resize-none']"
                                    :label-class="['font-bold']"
                                />
                                <div class="py-3 flex justify-end">
                                    <base-button @action="addLink" :disabled="!link || link == ''" size="xs" icon="plus"/>
                                </div>
                            </div>

                            <!-- file list -->
                            <div class="flex gap-x-2" v-for="(file, index) in files" :key="index">
                                <div class="w-5/6" v-if="file.type == 'link'">{{file.name || file.link}}</div>
                                <div class="w-5/6" v-else>{{file.name}}</div>
                                <base-icon
                                    class="w-1/6 cursor-pointer hover:opacity-50"
                                    name="close"
                                    :size="6"
                                    @action="files = files.filter(element => element != file)"
                                />
                            </div>

                            <!-- audios list -->
                            <div class="flex gap-x-4" v-for="(audio, index) in audios" :key="`audio-${index}`">
                                <div v-if="audio.type == 'link'">{{audio.link}}</div>
                                <div v-else>{{audio.name}}</div>
                                <base-icon
                                    class="cursor-pointer hover:opacity-50"
                                    name="close"
                                    :size="6"
                                    @action="audios = audios.filter(element => element != audio)"
                                />
                            </div>
                        </div>
                    </div>

                </div>    

                <div v-if="step == 2" class="preview-container">
                    <preview 
                        :deliverableSlug="projectUserDeliverable.deliverable.slug"
                        :files="files"
                        :audios="audios"
                        :description="description"
                        :stats="stats"
                        :networkId="projectUserDeliverable.network_id"
                        :user="projectUserDeliverable.project_user.user"
                        @edit="step--"
                        @removeAudio="(fileIndex) => onRemoveAudio(fileIndex)"
                        @audioDrop="(audio, fileIndex) => onAudioDrop(audio, fileIndex)"
                    />
                </div>

                <div v-if="step == 3" class="step-3-container">
                    <base-icon class="text-green-m-main"  :size="24" name="check-circle" />
                    <div class="title">
                        Thank you for your submission, we will be in touch...
                    </div>
                    <div class="actions" v-if="me && isOnlyCreator">
                        <base-button
                            label="Go Home"
                            @action="$router.push('/home')"
                        />
                    </div>
                </div>

                <div class="footer">
                    <base-button
                        v-if="isManual"
                        @action="submit"
                        :disabled="loading || (description.length <= 0 && files.length <= 0)"
                        class="font-bold"
                        size="lg"
                        :iconSize="4"
                        theme="dark"
                        submit
                        label="Submit"
                    />
                    <template v-else-if="!projectUserDeliverable.is_completed && (step == 1 || step == 2)">
                        <base-button
                            v-if="step == 1 && draftPhase"
                            @action="step++"
                            :disabled="loading || (description.length <= 0 && files.length <= 0)"
                            class="font-bold"
                            label="Next"
                            secondaryIcon="arrow-right"
                        />
                        <base-button
                            v-if="step == 1 && conceptPhase"
                            @action="submit"
                            :disabled="loading || (description.length <= 0 && files.length <= 0)"
                            class="font-bold"
                            submit
                            label="Submit"
                        />
                        <base-button 
                            v-if="step == 2" 
                            @action="step--" 
                            type="secondary" 
                            label="Back"
                            icon="arrow-left"
                        />
                        
                        <div v-if="step == 2" id="dropzone-wrapper2">
                            <FormulateInput
                                type="file"
                                :uploader="addFile"
                            >
                                <template #prefix="{ id }">
                                    <label :for="id">
                                        <div class="cursor-pointer button border-green-m-main-dark justify-center items-center gap-4 rounded-full text-h6 font-base border focus:outline-none transition duration-150 ease-in-out flex w-48 px-4 py-4 focus:shadow-outline-gray text-purple-m-main bg-white border-transparent hover:bg-green-m-main-dark focus:bg-green-m-main-dark">
                                            <base-icon name="upload2"/>
                                            <div class="truncate">Upload another</div>
                                        </div>
                                    </label>
                                </template>
                            </FormulateInput>
                        </div>

                        <base-button
                            v-if="step == 2"
                            @action="submit"
                            :disabled="loading || (description.length <= 0 && files.length <= 0)"
                            class="font-bold"
                            submit
                            label="Submit"
                        />
                    </template>
                </div>
            </template>
        </div>

    </base-modal>
</template>

<script>
import { linkUpload, draftUpload, getObjectValue } from '../../common';
import { capitalize } from '@/lib/strings'
import { mapGetters } from 'vuex';
import formatBytes, { videoDuration, isType, isSize, isVideoAspectRatioInRange, videoAspectRatio, getContentType } from '../../lib/files';
import displayNames from '../../lib/deliverable'
import AvatarList from '../../components/AvatarList.vue';
import BaseButton from '../../components/BaseButton.vue';
import Preview from './Preview.vue'
//import FileListItem from '../../components/FileListItem.vue';
import DrivePicker from './DrivePicker';

export default {
    props: ['visible', 'type', 'isManual', 'submissionType', 'deliverable', 'projectName', 'standAlone'],
    components: {
        AvatarList,
        BaseButton,
        DrivePicker,
        Preview,
        //FileListItem
    },
    data() {
        return {
            step: 1,
            projectUserDeliverable: {
                is_completed: false
            },
            name: '',
            description: '',
            files: [],
            audios: [],
            addingLink: false,
            openDrive: false,
            link: '',
            
            loading: false,
            projectBriefVisible: false,
        }
    },
    watch: {
        visible(val){
            if(val === true){
                if(Object.keys(this.projectUserDeliverable).length <= 1){
                    this.getProjectUserDeliverable();
                }
            }else{
                this.resetDataToDefault()
            }
        }
    },
    computed: {
        ...mapGetters(['me', 'isOnlyCreator']),
        
        max_chars() {
            return (this.projectUserDeliverable.deliverable.max_characters) ? this.projectUserDeliverable.deliverable.max_characters : 200;
        },

        briefExists(){
            const deliverable = this.projectUserDeliverable
            const brief = getObjectValue(deliverable, 'project_user.project.brief')
            const briefAttachment = getObjectValue(deliverable, 'project_user.project.brief_attachment')
            if(brief || briefAttachment){
                return true
            }
            return false
        },
        conceptPhase(){

            if ( typeof this.submissionType !== 'undefined' ) {
                return ( this.submissionType.toLowerCase() == 'concept' ) ? true : false;
            } else {
                return this.projectUserDeliverable.phases_progress.status == "concept_approval" || this.projectUserDeliverable.phases_progress.status == "not_started" ? true : false;
            }
        },
        fileOptions() {
            let options = [
                { label: 'From Computer', value: 'file', icon: 'link' },
                { label: 'From Drive', value: 'drive', icon: 'drive' }
            ];

            if (this.conceptPhase) {
                options.push({ label: 'Add Link', value: 'link', icon: 'attachment' });
            }
            return options;
        },
        draftPhase(){
            if ( typeof this.submissionType !== 'undefined' ) {
                return ( this.submissionType.toLowerCase() == 'draft' ) ? true : false;
            } else {
                return (this.projectUserDeliverable.phases_progress.status == "draft_approval" || this.projectUserDeliverable.phases_progress.status == "draft_submission") ? true : false;
            }
        },
        completedPhase(){
            return this.projectUserDeliverable.phases_progress.status == "completed" ? true : false;
        },
        userDeliverableProject() {
            if(Object.keys(this.projectUserDeliverable).length){
                return this.projectUserDeliverable.project_user && this.projectUserDeliverable.project_user.project ? this.projectUserDeliverable.project_user.project.name : 'Project Name'
            }
            else{
                return 'Project Name'
            }
        },
        userDeliverableName() {
            if(Object.keys(this.projectUserDeliverable).length){
                return this.projectUserDeliverable.name && this.projectUserDeliverable.name !== '' ? this.projectUserDeliverable.name : `${this.projectUserDeliverable.deliverable.name} Deliverable`
            }
            else{
                return 'Deliverable Name'
            }
        },
        attachmentDisplayName(){
            // Eg. Upload Instagram Story
            return this.conceptPhase ? 'Attachment' : displayNames(this.projectUserDeliverable.deliverable?.slug).attachment
        },
        descriptionDisplayName(){
            return capitalize(this.submissionType || this.projectUserDeliverable.phases_progress.display_name);
        },
        maxFileSize(){
            return formatBytes(this.projectUserDeliverable.deliverable.max_file_size)
        },
        deliverableUrl(){
            return `${window.location.origin}/deliverables/${this.projectUserDeliverable.id}`
        },
        title() {
          if (this.projectUserDeliverable.is_completed) {
            return 'Deliverable completed';
          }
          if (this.projectUserDeliverable.phases_progress) {
            return this.loading ? 'Getting deliverable data...' : (this.step == 2 ? 'Preview' : `Submit ${this.submissionType || this.projectUserDeliverable.phases_progress.display_name}`);
          } else {
            return false;
          }
        },

        stats(){
            return this.projectUserDeliverable?.project_user?.user?.audience_data || []
        }
    },
    methods: {
        resetDataToDefault(){
            this.step = 1
            this.projectUserDeliverable = {
                is_completed: false
            }
            this.name = ''
            this.description = ''
            this.files = []
            this.audios = []
            this.addingLink = false
            this.openDrive = false
            this.link = ''
            this.loading = false
            this.projectBriefVisible = false
        },
        setDriveFiles(files) {
            this.openDrive = false;

            for (let file of files) {
                this.files.push({ type: 'link', link: file.url, iconUrl: file.iconUrl, name: file.name });
            }
        },
        fileOptionsHandler(option) {
            switch (option.value) {
                case 'file': {
                    let file = document.getElementById('file');
                    file.click();
                    break;
                }
                case 'drive':
                    this.openDrive = true;
                    break;
                case 'link':
                    this.addingLink = !this.addingLink;
                    break;
            }
        },
        close() {
            if (this.isManual) {
                this.$emit('close');
            }
        },
        async getProjectUserDeliverable() {
            
            if ( !this.standAlone ) {
                this.projectUserDeliverable = this.deliverable;
            } else {
                let token = this.$route.query.t;
                if (this.deliverable.token) {
                    token = this.deliverable.token;
                }

                this.getting = true;
                this.loading = true;
                
                try {
                    const { data } = await this.$http.get(`/api/userDeliverables/withToken/${token}`);
                    this.projectUserDeliverable = data;
                    // if max characters is null we fall back to 1200
                    if(this.projectUserDeliverable.deliverable.max_characters == null){
                        this.projectUserDeliverable.deliverable.max_characters = 1200;
                    }
                    // increase max chars if on concept phase
                    if(this.conceptPhase){
                        this.projectUserDeliverable.deliverable.max_characters = 10000;
                    }
                } catch(e) {
                    console.log(e);
                    if (e.response && e.response.data) {
                        return this.error = e.response.data.message;
                    }
                    this.error = 'An unknown error occurred. Please try again.';
                } finally {
                    this.loading = false;
                    this.getting = false;
                    this.name = `New ${this.userDeliverableName} ${this.conceptPhase ? 'Concept' : 'draft'}`;
                }
            }
            this.name = `${this.projectUserDeliverable.name} ${this.submissionType || this.descriptionDisplayName} for ${this.projectName || this.projectUserDeliverable.project_user.project.name}`;
        },
        async submit(){
            this.loading = true
            try {
                const { name, description } = this;
                const type = this.conceptPhase ? 1 : this.draftPhase ? 2 : 0;
                const { data } = await this.$http.post(`/api/userDeliverableVersions`, {
                    deliverable_id: this.projectUserDeliverable.id,
                    user_id: this.me && this.me.id ? this.me.id : null,
                    user_name: this.me && this.me.first_name ? this.me.first_name : null,
                    name,
                    type,
                    description: description,
                    attachment_id: this.attachmentId,
                    project_id: this.projectUserDeliverable.project_user.project_id,
                    deliverable_description: this.projectUserDeliverable.deliverable.description,
                    redirect: this.deliverableUrl
                });
                // data will return { version, deliverable }
                const versionId = data.version.id
                this.$notify({ title: 'Success!', text: '', type: 'success' });
                let files = this.files.concat(this.audios.filter(audio => !audio.used));
                let hasFiles = files.length > 0;
                if (hasFiles) {
                    this.$notify({ title: 'Uploading files, please wait...', text: '', type: 'warn', duration: -1000 })
                }
                
                for (const file of files) {
                    const uploadedFile = file.type == 'link' ? await this.submitLink(file) : await this.uploadDraft(file)
                    const uploadedAudio = file.audio ? await this.uploadDraft(file.audio) : null
                    const response = await this.$http.post(`/api/userDeliverableVersions/media`, {
                        version_id: versionId,
                        attachment_id: uploadedFile.id,
                        audio_attachment_id: uploadedAudio ? uploadedAudio.id : null
                    });
                    console.log('Uploaded File Successfully', response.data)
                }

                if (hasFiles) {
                    this.$notify({ group: '', clean: true });
                    this.$notify({ title: 'Success', text: 'All files uploaded successfully', type: 'success' });
                }
                this.step = ( this.standAlone ) ? 3 : 1;
                this.$emit('done', data.deliverable);
                // this.$emit('done', this.projectUserDeliverable);
            } catch(e) {
                console.log(e);
                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }
                this.error = 'An unknown error occurred. Please try again.';
            } finally {
                this.loading = false
            }
        },
        async addFile(file) {

            if(await getContentType(file.type) == 'audio'){ // is audio
                this.audios.push(file)
                return
            }

            // the user can upload any type of file while on concept phase
            if(this.conceptPhase || await this.validateDraft(file)){
                this.files.push(file)
                return
            }
        },
        async addLink(){
            this.files.push({ type: 'link', link: this.link, icon: 'link' });
            this.link = ''
            this.addingLink = false;
        },
        async onAudioDrop(audio, fileIndex){
            const audioIndex = this.audios.findIndex(element => {
                return element.name === audio.name;
            })
            this.files[fileIndex].audio = this.audios[audioIndex]
            this.files[fileIndex].audioElementId = audio.elementId 
            this.files = this.files.map(file => file) // reset data
            this.audios[audioIndex].used = true
            this.audios[audioIndex].usedByFileIndex = fileIndex
        },
        async onRemoveAudio(fileIndex){
            const audioIndex = this.audios.findIndex(element => {
                return element.name === this.files[fileIndex].audio.name;
            })
            this.audios[audioIndex].used = false
            this.audios[audioIndex].usedByFileIndex = null
            this.files[fileIndex].audio = null
            this.files[fileIndex].audioElementId = null
            this.files = this.files.map(file => file) // reset data
        },
        async submitLink(file){
            const link = await linkUpload({ name: 'Concept Link', file: file.link, projectId: null, userId: this.projectUserDeliverable.project_user.user_id });
            return link
        },
        async uploadDraft(file) {
            const uploadedFile = await draftUpload(file, {userId: this.projectUserDeliverable.project_user.user_id});
            return uploadedFile
        },
        async validateDraft(file){
            const { max_video_duration, required_file_type, max_file_size, min_aspect_ratio, max_aspect_ratio } = this.projectUserDeliverable.deliverable
            let errors = 0
            let msgHtml = ''

            // file type
            if(required_file_type && Array.isArray(required_file_type) && required_file_type.length > 0){
                const isMp4 = await isType(file, required_file_type)
                if(!isMp4){
                    msgHtml += `<div>• This file must be type: ${required_file_type}</div><br>`
                    errors++
                }
            }
            // file size
            if(max_file_size){
                const isInsideMaxSize = await isSize(file, max_file_size)
                if(!isInsideMaxSize){
                    msgHtml += `<div>• This file must not exceed ${formatBytes(max_file_size)}</div><br>`
                    errors++
                }
            }
            // video duration
            if(max_video_duration){
                // make sure file is video or an error will be thrown
                if(await getContentType(file.type) == 'video'){
                    const duration = await videoDuration(file)
                    if(duration > max_video_duration){
                        msgHtml += `<div>• The duration of this video must be less than ${max_video_duration}s</div><br>`
                        errors++
                    }
                }
            }
            // video aspect ratio
            if(min_aspect_ratio && max_aspect_ratio){
                // make sure file is video or an error will be thrown
                if(await getContentType(file.type) == 'video'){
                    const isAspectRatioInRange = await isVideoAspectRatioInRange(file, min_aspect_ratio, max_aspect_ratio)
                    if(!isAspectRatioInRange){
                        const aspectRatio = await videoAspectRatio(file)
                        msgHtml += `<div>• Expected video aspect ratio to be between ${min_aspect_ratio} - ${max_aspect_ratio} but got ${aspectRatio.displayName}</div><br>`
                        errors++
                    }
                }
            }
            if(errors){
                this.$swal.fire({
                    title: `Your file didn't meet the following requirements:`,
                    html: msgHtml,
                    icon: 'warning',
                    iconColor: '#0E092C',
                    confirmButtonText: 'Ok',
                })
            }
            return !errors
        },
        handleInput(){

            if(this.description.length >= this.max_chars){
                this.$notify({ title: 'Warning', text: 'Max characters reached', type: 'warn' })
            }
        }
    }
}
</script>
<style lang="scss" scoped>

    .wrapper{
        .loading-text{
            @apply text-center italic my-10;
        }
        .container{
            // #
            .project-info{
                @apply w-full px-7;
                .project-info-wrapper{
                    @apply w-full bg-white rounded-lg;
                    .project-info-container{
                        @apply p-4;
                        .name{
                            @apply font-semibold text-h6 text-purple-m-secondary;
                        }
                        .content{
                            @apply w-full flex justify-between mt-2;
                            .requested-by{
                                @apply flex gap-x-4 items-center;
                                .text{
                                    @apply text-sm;
                                }
                            }
                        }
                    }
                }
            }
            .separator{
                @apply w-full bg-gray-300 mt-2;
                height: 1px;
            }
            .brief-container{
                @apply p-4;
                .brief{
                    @apply w-full text-center;
                    @media (min-width: 768px) {
                        @apply text-left;
                    }
                }
                .brief-attachment-container{
                    @apply flex mt-4;
                    .brief-attachment{
                        @apply flex gap-x-4 items-center cursor-pointer;
                        &:hover{
                            @apply opacity-80;
                        }
                        .icon-wrapper{
                            @apply w-14 h-14 flex justify-center items-center bg-tan-m rounded-lg;
                            .icon-container{
                                @apply w-8 h-8 flex justify-center items-center border border-gray-300 rounded-full bg-white;
                            }
                        }
                    }
                }
            }
        }
        .step-3-container{
            @apply w-full text-center pt-5 pb-10 px-14;
            .title{
                @apply text-h5 mt-5;
            }
            .actions{
                @apply w-full flex justify-center mt-6;
            }
        }
        .footer{
            @apply w-full bg-white rounded-xl py-4 px-6 flex justify-end gap-x-4;
        }
    }

    .form-container{
        @apply w-full flex gap-x-10 pt-8 pb-10 px-7;
        .text{
            @apply italic text-center w-full;
        }
        .left-column{
            @apply flex-grow flex-shrink flex flex-col gap-y-2;
            .caption{
                @apply relative;
                .char-limit{
                    @apply absolute bottom-3 right-5 flex justify-end;
                    .text{
                        @apply text-pxs text-gray-m;
                    }
                }
            }
        }
        .right-column{
            @apply flex-grow-0 flex-shrink-0 w-1/3;
            .title{
                @apply w-full text-pxs font-semibold;
            }
            .separator{ 
                @apply my-4 bg-gray-m w-full;
                height: 1px;
            }
            .requirement-list{
                @apply flex flex-col gap-y-2;
                .requirement{
                    @apply w-full text-pxs;
                }
            }
        }
    }

    .preview-container{
        @apply pb-10 px-7;
    }
</style>
<style lang="scss">
    #dropzone-wrapper .formulate-input div .formulate-input-element .formulate-input-upload-area{
        display: none;
    }
    #dropzone-wrapper2 .formulate-input div .formulate-input-element .formulate-input-upload-area{
        display: none;
    }
</style>