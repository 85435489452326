<template>
    <div class="container">
        <base-table
            :data="creatorsVal"
            :key="`creator-list-table-key-${key}`"
            class="mt-8 mb-12"
            :columns="[
                {
                    name: 'status',
                    label: 'Status',
                    type: 'user-importable-status',
                    onUpdateUser: onUpdateUser
                },
                {
                    name: 'first_name', 
                    label: 'First name'
                },
                {
                    name: 'last_name', 
                    label: 'Last name'
                },
                { 
                    name: 'email', 
                    label: 'Email'
                },
                {
                    name: 'profile_link', 
                    label: 'Profile Link'
                },
                {
                    name: 'handle', 
                    label: 'Handle',
                    type: 'handle',
                    verifyHandle: true,
                    value: (element) => {
                        return element.handle;
                    }
                },
                {
                    name: 'platform',
                    label: 'Platform',
                    type: 'network-selector',
                    value: (element) => {
                        return element.target_network
                    },
                    socialAccounts: (element) => {
                        return element.user && element.user.social_accounts ? element.user.social_accounts : [];
                    },
                    user: (element) => {
                        return element.user   
                    },
                    onUpdateValue: onUpdateTargetNetworkValue
                },
                { 
                    name: 'description', 
                    label: 'Description',
                    type: 'long-text',
                    value: (element) => {
                        return element.description;
                    }
                },
                { 
                    name: 'posts', 
                    label: 'Posts',
                    type: 'featured-posts',
                    value: (element) => {
                        return element.posts
                    },
                    onUpdateValue: onUpdateValue
                },
                {
                    name: 'additional-info',
                    label: 'Additional Info',
                    type: 'additional-info',
                    value: (element) => {
                        return element.additional_information
                    },
                    onUpdateValue: onUpdateAdditionalInformationValue
                }
            ]"
            :search="false"
            @set-accounts="toggleNetwork"
        />
    </div>
</template>
<script>
import { copyObject, isNotNull, isValidArray, isValidObject, isValidString, notifyCatchError } from '../../common';
import { getNetworkFromProfileUrl, getNetworkFromSocialPostUrl, getParamsFromProfileUrl, getParamsFromSocialPostUrl, isProfileUrl, removeUnnecessaryQueryParams } from '../../lib/network'
import { stringToArray, validateEmail } from '../../lib/strings';
export default {
    props: ['fileCreators', 'mapper', 'additionalInformation', 'creators', 'mountEventTrigger'],
    data(){
        return {
            creatorsVal: this.creators,
            key: false
        }
    },
    watch: {
        // trigger the mounted functions again,
        // only if we go from step 3 to step 4
        mountEventTrigger(val, old){
            if(val !== old && old == 3 && val == 4){
                this.buildCreators();
            }
        },
        creatorsVal(val, old){
            if(val !== old){
                this.$emit('update:creators', val);
                setTimeout(() => {
                    this.key = !this.key;
                }, 100);
            }
        }
    },
    mounted(){
        this.buildCreators();
    },
    methods: {
        async buildCreators(){
            try {
                
                this.loading = true;

                // "creators" var should merge "fileCreators" & "mapper" vars and have all of the creator values
                const mapper = copyObject(this.mapper);
                const fileCreators = copyObject(this.fileCreators);
                const additionalInformation = copyObject(this.additionalInformation);
                let creators = copyObject(fileCreators);

                // Required fields: check that required is true
                const requiredFields = mapper.filter(element => element.required == true);

                // Optional fields: check that required is false and mapped and fileColumn value are truthy
                const optionalFields = mapper.filter(element => element.required == false && element.mapped == true && element.fileColumn);

                // Selected additional information: check the ones that the user toggled, by checking the .selected property
                const selectedAdditionalInformation = additionalInformation.filter(element => element.selected == true);
                
                // --------------------------------------------------
                // For each creator: Map, set & validate information
                // --------------------------------------------------
                for (let creator of creators) {
                    
                    // --------------------
                    // Map selected fields
                    // --------------------

                    // set required fields
                    for (const field of requiredFields) {
                        creator[field.slug] = creator[field.fileColumn];
                    }

                    // set optional fields
                    for (const field of optionalFields) {
                        creator[field.slug] = creator[field.fileColumn];
                    }

                    // -------------------------
                    // Set creator information
                    // -------------------------

                    // set default ".status" property
                    creator.status = {};

                    // these vars will determine if the user is importable and add actions
                    // if there is any errors with the content, before sending to the backend
                    let is_importable = true;
                    let actions = [];

                    // set the creator's "target_network" and "handle" properties, so the backend can search for the right handle
                    let network = getNetworkFromProfileUrl(creator.handle);
                    
                    // if network is not found by this point we will try to find it in the .posts property
                    if(!isValidObject(network) && isValidString(creator.posts)){
                        const urls = stringToArray(creator.posts);
                        for (const url of urls) {
                            const _network = getNetworkFromSocialPostUrl(url);
                            // check that the network and postId are found for the URLs
                            if(isValidObject(_network)){
                                network = {
                                    id: _network.id,
                                    name: _network.name,
                                    display_name: _network.display_name
                                }
                            }
                        }
                    }
                    
                    // if network is still not found, we default to instagram
                    if(!isValidObject(network)){
                        network = {
                            id: 2,
                            name: "instagram",
                            display_name: "Instagram"
                        }
                    }

                    // this helps us always show the platform icon on the overview table
                    network.network_id = network.id;   
                    
                    // set target_network
                    creator['target_network'] = network;

                    // set handle
                    if(isValidString(creator.handle)){
                        creator.profile_link = creator.handle;
                        if(isProfileUrl(creator.handle)){
                            let params = getParamsFromProfileUrl(creator.handle);
                            if(params && params.handle){
                                creator.handle = params.handle;
                            }
                        }
                    }

                    // set toggled network if found
                    if(creator.user && creator.user.social_accounts){
                        const found = creator.user.social_accounts.find(element => element.network_id == creator.target_network.id);
                        if(found){
                            creator.accounts = [found.network_id];
                        }
                    }

                    // set posts 
                    let posts = [];
                    if(isValidString(creator.posts)){
                        const urls = stringToArray(creator.posts);
                        for (const url of urls) {
                            const _network = getNetworkFromSocialPostUrl(url);
                            const params = getParamsFromSocialPostUrl(url);
                            // check that the network and postId are found for the URLs
                            if(isValidObject(_network) && params && params.postId){
                                // check that the found network for the post matches the target network, 
                                // if not, the post wont be added, and a warning will be added
                                if(_network.id !== network.id){
                                    actions.push({
                                        slug: 'fix-content',
                                        title: 'Invalid url',
                                        description: `The url: ${url}. Does not match the selected platform: ${network.display_name}. The creator is still importable but this post wont be fetched.` 
                                    });
                                }else{
                                    // if the networks match, we add the post
                                    // any change in this object should also be changed on BaseTableColumnFeaturedPostsModal.vue file
                                    posts.push({
                                        url: removeUnnecessaryQueryParams(url), 
                                        network_id: _network.id, 
                                        network_name: _network.name, 
                                        network_display_name: _network.display_name,
                                        found: false,
                                        preview: null,
                                    });
                                }
                            }else{
                                // if network or postId are not found it means that the URL is not valid
                                // user should still be importable but at least let the user know that the url won't work
                                // is_importable = false;
                                actions.push({
                                    slug: 'fix-content',
                                    title: 'Invalid url',
                                    description: `The following url is not valid: ${url}. The user is still importable but this post wont be fetched.` 
                                });
                            }
                        }
                    }
                    // set the creator's "posts" var
                    creator['posts'] = posts;

                    // set additional information
                    let additional_information = [];
                    
                    // TODO here we could do the request "POST /user-additional-info/import/parse"
                    // that its on <BaseTableColumnAdditionalInfoModal/>. Its important that we do this 
                    // request because eventually we should be able to edit the additional informations 
                    // on this step, so its important that all of them are already parsed and editable 
                    // on the frontend.

                    // ! However it would be good to have another endpoint that manages all of the additional informations
                    // at once, since some of them will have multiple entries or will have a parent with children, returning
                    // more elements that the original array.
                    for (const info of selectedAdditionalInformation) {
                        const { name } = info;
                        const value = creator[name] ? creator[name].toString() : null;
                        // check that we were able to get the name and value
                        if(!isValidString(name) || !isNotNull(value)){
                            // if we were unable to get name and value, user should still be importable but at least let the user know that 
                            // we were not able to get an additional info
                            // is_importable = false;
                            actions.push({ 
                                slug: 'fix-content', 
                                title: 'Invalid additional info', 
                                description: `Unable to get additional information for: ${JSON.stringify(name)}, ${JSON.stringify(value)}. The user is still importable but this additional information wont be created.` 
                            });
                        }else{
                            // if there are multiple links (if there is a comma and there are links) we will split the additional informations
                            if(isValidString(value) && value.includes(',') && value.includes('http')){
                                const split = value.split(',');
                                for (const element of split) {
                                    if(isValidString(element)){
                                        additional_information.push({ name, value: element } );
                                    }
                                }
                            }else{
                                additional_information.push({ name, value } );
                            }
                        }
                    }
                    creator.additional_information = additional_information;

                    // --------------------
                    // Frontend validation
                    // --------------------
                    let validatableProperties = [
                        {
                            name: "first_name",
                            display_name: "First name",
                            validator: "string",
                            required: true
                        },
                        {
                            name: "last_name",
                            display_name: "Last name",
                            validator: "string",
                            required: true
                        },
                        {
                            name: "email",
                            display_name: "Email",
                            validator: "email",
                            required: true
                        },
                        {
                            name: "handle",
                            display_name: "Handle",
                            validator: "handle",
                            required: true
                        },
                        {
                            name: "description",
                            display_name: "Description",
                            validator: "string",
                            required: false
                        }
                    ];

                    for (const property of validatableProperties) {
                        const val = creator[property.name];
                        let is_valid = true;
                        if(property.validator == "string"){
                            if(!isValidString(val)) is_valid = false;
                        }
                        if(property.validator == "email"){
                            if(!validateEmail(val)) is_valid = false;
                        }
                        if(property.validator == "handle"){
                            if(!isValidString(val)){
                                is_valid = false;
                            }else{
                                // check it does not have spaces still after trim
                                if(val.trim().includes(' ')){
                                    is_valid = false;
                                }
                            }
                        }
                        if(is_valid == false){
                            // if a required field fails, user is not importable and an action is added
                            if(property.required){
                                is_importable = false;
                                actions.push({ 
                                    slug: 'fix-content', 
                                    title: property.display_name, 
                                    description: `${property.display_name} is a required field, make sure the content is valid. The user wont be imported if this required field is invalid.` 
                                });
                            }else{
                                // if an optional field fails, user IS still importable but the action is added anyways as info
                                // is_importable = false;
                                actions.push({ 
                                    slug: 'optional-field', 
                                    title: property.display_name, 
                                    description: `${property.display_name} is an optional field, and its not set. The user is still importable.` 
                                });
                            }
                        }
                    }
                    
                    // after all checks set "is_importable" and "actions"
                    creator.status.is_importable = is_importable;  
                    creator.status.actions = actions;

                }
                
                // -------------------
                // Backend validation
                // -------------------
                // send creators to the backend to check that the users exist on the DB and validate their information
                // this endpoint will set the following properties for each of the creators: ".user" ".user_matches" & ".status: { creator_found, is_importable, actions }"
                let { data } = await this.$http.post('/users/importable-creators', { creators });

                if(!isValidArray(data)){
                    throw "There was an error while processing the creators, try again later"
                }

                // (info) the watcher should catch the changes to the creatorsVal and update the parent var
                this.creatorsVal = data;

            } catch (error) {
                notifyCatchError(error, this.$notify);
            } finally {
                this.loading = false;
            }

        },
        toggleNetwork(element, accounts){
            const found = this.creatorsVal.find(e => e.user && e.user.id == element.user.id);
            if(found){
                const index = this.creatorsVal.findIndex(e => e.user && e.user.id == element.user.id);
                if(index != -1){
                    this.creatorsVal[index].accounts = accounts;       
                }
            }
        },
        onUpdateTargetNetworkValue(column, element, value){
            const found = this.creatorsVal.find(e => e.email == element.email);
            if(found){
                const index = this.creatorsVal.findIndex(e => e.email == element.email);
                if(index != -1){
                    this.creatorsVal[index].target_network = value;      
                }
            }
        },
        onUpdateValue(column, element, value){
            const found = this.creatorsVal.find(e => e.email == element.email);
            if(found){
                const index = this.creatorsVal.findIndex(e => e.email == element.email);
                if(index != -1){
                    this.creatorsVal[index].posts = value;      
                }
            }
        },
        onUpdateUser(column, element, value){
            const found = this.creatorsVal.find(e => e.email == element.email);
            if(found){
                const index = this.creatorsVal.findIndex(e => e.email == element.email);
                if(index != -1){
                
                    let creator = copyObject(this.creatorsVal[index]);

                    // set toggled network if found
                    if(value && value.social_accounts){
                        const found = value.social_accounts.find(element => element.network_id == creator.target_network.id);
                        if(found){
                            creator.accounts = [found.network_id];
                        }
                    }
                    
                    // remove "email-mismatch" & "handle-mismatch" status actions, as we are going to recalculate them
                    if(creator && creator.status && isValidArray(creator.status.actions)){
                        creator.status.actions = creator.status.actions.filter(element => element.slug !== 'email-mismatch' && element.slug !== 'handle-mismatch');
                    }

                    // check if the new user "handle" matches the spreadsheet handle
                    if(creator.handle){
                        const found = value.social_accounts.find(element => element.network_id == creator.target_network.id);
                        if(found){
                            if(creator.handle.trim() !== found.handle.trim()){
                                // add to actions array
                                creator.status.actions.push({ 
                                    slug: 'handle-mismatch', 
                                    title: 'The handle does not match.', 
                                    description: `The provided handle for this creator "${creator.handle}" does not match the existing creator's handle "${found.handle}". User is still importable.`,
                                    solutions: [
                                        { slug: 'select-another-user', name: 'Select another user' }, 
                                        { slug: 'edit-current-user', name: 'Edit current user' }
                                    ]
                                });
                            }
                        }
                    }
                    
                    // check if the new user "email" matches the spreadsheet email
                    if(creator.email && value.email){
                        if(creator.email.trim() !== value.email.trim()){
                            // add to actions array
                            creator.status.actions.push({ 
                                slug: 'email-mismatch', 
                                title: 'The email does not match.', 
                                description: `The provided email for this creator "${creator.email}" does not match the existing creator's email "${value.email}". User is still importable.`,
                                solutions: [
                                    { slug: 'select-another-user', name: 'Select another user' }, 
                                    { slug: 'edit-current-user', name: 'Edit current user' }
                                ] 
                            });
                        }
                    }
                    
                    // set whole creator value
                    this.creatorsVal[index].user = value;
                    this.creatorsVal[index].status.actions = creator.status.actions;

                }
            }
        },
        onUpdateAdditionalInformationValue(column, element, value){
            const found = this.creatorsVal.find(e => e.email == element.email);
            if(found){
                const index = this.creatorsVal.findIndex(e => e.email == element.email);
                if(index != -1){
                    this.creatorsVal[index].additional_information = value;
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
</style>