<template>
    <!-- [unused-component] -->
    <div class="select-wrapper relative flex">
        <button
            :class="['px-2 py-1 rounded-md text-white bg-transparent hover:text-green-m-main hover:bg-purple-m-main focus:outline-none transition ease-in-out duration-150',                    
                    {'bg-purple-m-main text-green-m-main': open}]"
            @click="toggleDropdown(!open)">
            <div class="w-full h-full flex justify-between items-center gap-x-4">                
                <avatar-list v-if="owners.length" :users="owners" cursor="pointer" theme="dark" />
                <div v-else class="h-8 flex items-center gap-2">
                    <span :class="['inline-flex items-center justify-center sm h-6 w-6 rounded-full bg-gray-m']">                                                
                        <span :class="['text-white text-xs']">UA</span>
                    </span>
                    <span class="text-sm font-medium text-gray-m-disable">Unassigned</span>
                </div>
                <base-icon name="chevron-down" class="" :size="5" />                            
            </div>
        </button>     
        <div v-if="open"
            :class="['select-menu absolute z-10 top-full w-56 shadow-lg rounded-md border-2 border-purple-m-secondary',
            {'right-0': !right}, 
            {'left-0': right}]"
        >
            <div class="bg-purple-m-main">           
                <template v-if="users.length">
                    <div v-for="(user, i) in users" :key="i" class="">
                        <base-button @action="selectUser(user)" type="label" rounded="none" justify="start" theme="dark"
                            class="w-full h-full px-2 py-1 hover:bg-purple-m-secondary flex justify-start items-center gap-x-2"
                            :icon="owners.find(owner => owner.id === user.id) ? 'minus' : 'plus'"
                        >                                                       
                            <avatar-list class="w-36" :users="[user]" :tooltips="false" theme="dark"/>                            
                        </base-button>                                                
                    </div>
                </template>
                <template v-else>
                    <div class="px-6 leading-10 py-4 text-h6 text-white cursor-not-allowed text-center">
                        There are no users available.
                    </div>
                </template>          
            </div>      
        </div>      
    </div>    
</template>

<script>
import AvatarList from './AvatarList.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        AvatarList,
    },

    props:{
        projectId: {
            type: Number,
            required: true
        },
        owners:{
            type: Array,
            default: function () {
                return [];
            }
        },
        right:{
            type: Boolean,
            default: false
        },
        usable:{
            type:Boolean,
            default: false
        }
    },

    data() {
        return {
            open: false,
            users: '',
        };
    },

    computed: {
        ...mapGetters(['me']),
    },

  mounted() {
    this.getTeamMembers();
    document.addEventListener('click', this.close);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },

  methods: {
    toggleDropdown(state) {
        if(this.usable)
            this.open = state; 
        else
            this.$emit('unusable');
    },

    close(e) {
        if (!this.$el.contains(e.target) && this.open) {
            this.toggleDropdown(false);
        }
    },

    async getTeamMembers() {
        const { data } = await this.$http.get('/api/users');
        this.users = data.map(user => {
            user.showDropdown = false;
            return user;
        });
    },

    selectUser(user) {
        if(this.owners.find(owner => owner.id === user.id))
            this.removeFromOwners(user)
        else
            this.addToOwners(user);            
    },

    async addToOwners(user){
        try{
            const { data } = await this.$http.post(`/api/projects/${this.projectId}/owner/${user.id}`)
            if(data && data.success){                
                this.$emit('done')
                this.$notify({ title: 'Success', text: 'Project owner added', type: 'success' })
            }
        }catch(err){
            this.$notify({ title: 'Error', text: 'Error trying to update the project', type: 'error' })
        }
    },

    async removeFromOwners(user) {
        if(user.id === this.me.id){
            this.$notify({ title: 'Warn', text: "You can't remove yourself from the project owners", type: 'warn' })
            return
        }

        if(this.owners.length <= 1){
            this.$notify({ title: 'Warn', text: "The only owner of a project can't be removed", type: 'warn' })
            return
        }

        let route = `/api/projects/${this.projectId}/owner/${user.id}`;
        this.$http.delete(route).then(res => {
            if (res.status === 200) {
                console.log(res);
                this.$emit('done');
                this.$store.dispatch('restorer/set', {
                    resourceLabel: 'Project owner',
                    route: `${route}/restore`,
                    action: res.data.show_undo,
                    fn: () => {
                        this.$emit('done');
                    }
                });
            }
        })
        .catch(() => {
            this.$notify({ title: 'Error', text: 'Error trying to update the project', type: 'error' })
        });
    }

  }
};
</script>

<style lang="scss">
.select-wrapper {
  .select-menu {
    max-height: 14em;
    overflow-y: auto;
  }
}
</style>