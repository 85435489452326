<template>
<div class="main-wrapper">

    <Header
        :userDeliverable="userDeliverable"
        :loading="loading"
        :prev-route="prevRoute"

        user-direct

        :toggleDeliverables="toggleDeliverables"
        @toggleDeliverablesInput="val => toggleDeliverables = val"

        :viewTab="viewTab"
        @viewTabInput="(val) => viewTab = val"
    />

    <template v-if="loading">
        <content-skeleton />
    </template>
    <template v-else>

        <!-- Concept Tab -->
        <template v-if="viewTab === 'Concept'">          
            <div class="content">
                <div class="left-column">

                    <div class="none-selected" v-if="!Object.keys(selectedConcept).length">
                        <div class="title">Concept Current Version</div>
                        <div class="description">No concepts have been created</div>
                        <div class="actions">

                            <base-button 
                                label="Request New Concept" 
                                v-if="!projectDeleted && (!isGuest && !isClient && !isOnlyCreator) || allowEditing" 
                                @action="openShareCollectionLinkModal('Concept', userDeliverable)"
                                theme="dark" 
                                size="xl" 
                                bold 
                            />

                            <!-- disabled tooltip  -->
                            <base-button 
                                label="Add Concept" 
                                v-if="!projectDeleted && (isUserAdminOrHigher || isOnlyCreator)" 
                                @action="addConceptOrDraftIntent('Concept', userDeliverable)" 
                                bold 
                                type="secondary" 
                                theme="dark" 
                                size="lg" 
                                icon="submit" 
                                :iconSize="5" 
                            />

                        </div>
                    </div>

                    <div class="selected-concept" v-else>
                        <div class="title-container">
                            <div class="title">
                                {{selectedConcept.name}}               
                            </div>     
                        </div>        
                        <div class="content">
                            <div class="general-info">
                                
                                <div v-if="selectedConcept.description && selectedConcept.description !== ''">
                                    <div class="font-semibold">Description</div>
                                    <div class="enriched-format" v-html="selectedConcept.description"></div>
                                </div>
                                <div v-else class="font-semibold italic">No description</div>

                                <div class="actions">
                                    <base-button 
                                        label="Request New Concept" 
                                        v-if="!projectDeleted && (!isGuest && !isClient && !isOnlyCreator) || allowEditing" 
                                        @action="openShareCollectionLinkModal('Concept', userDeliverable)"
                                        theme="dark" 
                                        size="xl" 
                                        bold 
                                    />
                                    <base-button 
                                        label="Add New Concept" 
                                        v-if="!projectDeleted && (isUserAdminOrHigher || isOnlyCreator)" 
                                        @action="addConceptOrDraftIntent('Concept', userDeliverable)" 
                                        theme="dark" 
                                        size="xl" 
                                        type="secondary" 
                                        icon="submit" 
                                        :iconSize="5" 
                                        bold 
                                    />
                                </div>
                            </div>

                            <ul class="media-list">
                                <li v-for="(media, i) in selectedConcept.media" :key="i">
                                    <version-media-item
                                        :attachment="media.attachment"
                                        :audioAttachment="media.audio_attachment"
                                        @on-click="handleVersionMediaItemClick"
                                    />
                                </li>
                            </ul>

                        </div>

                        <!-- comments section -->
                        <div class="comments">
                            <div class="header-wrapper">
                                <div class="container">
                                    Comments
                                    <span>({{ selectedConcept.comments ? selectedConcept.comments.length : 0 }})</span>
                                    <base-icon v-if="commentsLoading" class="ml-4" name="loader"/>
                                </div>
                            </div>

                            <div class="content">
                                <div class="form" v-if="!projectDeleted && !isGuest && !isClient">
                                    <FormulateInput
                                        type="textarea"
                                        class="w-full"
                                        placeholder="Write a comment..."
                                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                                        v-model="comment"
                                        :disabled="commentsLoading"
                                        :rows="1"
                                    />
                                    <div class="actions">
                                        <base-button
                                            @action="postComment(selectedConcept.id, 'concept')"
                                            size="lg" 
                                            theme="dark" 
                                            secondaryIcon="pencil" 
                                            label="Add Comment"
                                            class="font-bold" 
                                            :disabled="comment == '' || commentsLoading"
                                        />
                                    </div>
                                </div>
                                <div class="comment-list">
                                    <div class="empty-state" v-if="selectedConcept.comments <= 0">No comments yet</div>
                                    <div class="item" v-for="comment in selectedConcept.comments" :key="comment.id">
                                        <div class="container">

                                            <avatar :user="comment.user"/>

                                            <template v-if="!comment.editing">
                                                <div class="content">
                                                    <div class="name-date">
                                                        <div class="name">{{ `${comment.user.first_name} ${comment.user.last_name}` }}</div>
                                                        <div class="date">{{ formatDateTime(comment.created_at) }}</div>
                                                    </div>
                                                    <div class="text">
                                                        {{ comment.comment }}
                                                        <span v-if="comment.created_at !== comment.updated_at">(Edited)</span>
                                                    </div>
                                                </div>
                                                <options-dropdown
                                                    v-if="me.id == comment.user_id && !projectDeleted"
                                                    class="ml-auto"                                                                                                                                    
                                                    :options="[
                                                        {label: 'Edit', icon: 'pencil-alt', event: 'edit'},
                                                        {label: 'Delete', icon: 'trash', event: 'delete'}                                                
                                                    ]"                                        
                                                    @delete="deleteComment(comment)"
                                                    @edit="comment.editing = true; $forceUpdate();"
                                                />
                                            </template>

                                            <template v-else>
                                                <div class="editing">
                                                    <FormulateInput
                                                        type="textarea"
                                                        class="w-full"
                                                        placeholder="Write a comment..."
                                                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                                                        v-model="comment.comment"
                                                        :disabled="commentsLoading"
                                                        :rows="1"
                                                    />
                                                    <div class="loader" v-if="comment.loading"><base-icon name="loader" /></div>
                                                    <div class="actions">
                                                        <base-button
                                                            @action="comment.editing = false; $forceUpdate(); comment.comment = comment.editedComment"
                                                            size="sm" 
                                                            type="secondary"
                                                            theme="dark"                                                
                                                            label="Cancel"
                                                            class="font-bold"                                                        
                                                        />
                                                        <base-button
                                                            @action="editComment(comment)"
                                                            size="sm" 
                                                            theme="dark"                                                          
                                                            label="Save"
                                                            class="font-bold" 
                                                            :disabled="comment.comment == '' || comment.loading"
                                                        />
                                                    </div>
                                                </div>
                                            </template>

                                        </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right-column">
                    
                    <div class="phases-progress">
                        <div class="text">
                            {{ userDeliverable.phases_progress.other_description }}                        
                        </div>
                    </div>
                    
                    <template v-if="Object.keys(selectedConcept).length">

                        <div class="actions">
                            <base-button 
                                size="lg" 
                                class="w-64 font-bold" 
                                v-if="(!projectDeleted && isUserAdminOrHigher) || allowEditing"
                                :label="selectedConcept.approved_at == null ? 'Approve Concept' : 'Unapprove Concept'"
                                v-tooltip="!checkPermission ? { content: 'Only for project owners and managers', classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : null"
                                @action="selectedConcept.approved_at == null ? updateStatusIntent(1, 1) : updateStatusIntent(0, 1)"
                            />
                        </div>

                        <div class="title-container">
                            <div class="title">
                                {{viewTab}} History
                            </div>              
                        </div>

                        <div v-if="!concepts.length">There are no versions for this deliverable</div>

                        <div class="history-wrapper">

                            <div class="history-lines" v-if="concepts.length">
                                <span class="dashed"></span>
                                <span class="circle at-top">
                                    <span class="inner-circle" />
                                </span>
                                <span class="circle at-bottom">
                                    <span class="inner-circle" />
                                </span>
                            </div>

                            <template v-if="concepts.length > 0">
                                <div class="history-list" v-if="userDeliverable && userDeliverable.project_user_deliverable_versions">
                                    <button 
                                        v-for="version in concepts" :key="version.id"
                                        class="version"
                                        :class="{ 'active': selectedConcept.id === version.id }"
                                        @click="selectConcept(version)"
                                    >
                                        <div class="name">
                                            <span class="text" :class="{'active': selectedConcept.id === version.id }">{{version.name}}</span>                                        
                                        </div>
                                        <div class="uploaded">
                                            <span class="title" :class="{ 'active': selectedConcept.id === version.id }">Uploaded</span>
                                            <div class="content">
                                                <div class="date-container">
                                                    <div class="icon-container">
                                                        <base-icon class="text-purple-m-main" name="calendar" :size="4" />
                                                    </div>
                                                    <span class="date" :class="{ 'active': selectedConcept.id === version.id }">{{version.created_at | moment('MMM D, Y')}}</span>
                                                </div>                            
                                                <div class="user-container">
                                                    <avatar-list 
                                                        :users="[version.user]" 
                                                        cursor="pointer" 
                                                        :theme="selectedConcept.id === version.id ? 'dark' : 'light'" 
                                                    />                                
                                                </div>                 
                                            </div>
                                        </div>                                                                        
                                        <div class="approved" v-if="version.approved_at !== null">
                                            <span class="title" :class="{ 'active': selectedConcept.id === version.id }">Approved</span>
                                            <div class="content">
                                                <div class="date-container">
                                                    <div class="icon-container">
                                                        <base-icon class="text-purple-m-main" name="calendar" :size="4" />
                                                    </div>
                                                    <span class="date" :class="{ 'active': selectedConcept.id === version.id }">{{version.approved_at | moment('MMM D, Y')}}</span>
                                                </div>                            
                                                <div class="user-container">
                                                    <avatar-list
                                                        :users="[version.approved_by_user]" 
                                                        cursor="pointer" 
                                                        :theme="selectedConcept.id === version.id ? 'dark' : 'light'" 
                                                    />                                
                                                </div>                 
                                            </div>
                                        </div>
                                    </button>                                  
                                </div>
                            </template>                        
                            <div class="empty-state" v-else>
                                <div class="text-container">
                                    <span class="text">Empty</span>
                                </div>                                 
                            </div>
                        </div>

                    </template>

                </div>
            </div>                     
        </template>
        
        <!-- Draft Tab -->
        <template v-if="viewTab === 'Draft'">  
            <div class="content">

                <!-- left column -->
                <div class="left-column">
                
                    <!-- if no draft is selected -->
                    <div class="none-selected" v-if="!Object.keys(selectedDraft).length">
                        <div class="title">Draft Current Version</div>
                        <div class="description">No drafts have been created</div>
                        <div class="actions">
                            <base-button 
                                label="Request New Draft" 
                                v-if="!projectDeleted && (!isGuest && !isClient && !isOnlyCreator) || allowEditing" 
                                @action="openShareCollectionLinkModal('Draft', userDeliverable)"
                                theme="dark" 
                                size="xl" 
                                class="font-bold" 
                            />
                            <base-button 
                                label="Add Draft" 
                                v-if="!projectDeleted && (isUserAdminOrHigher || isOnlyCreator)" 
                                @action="addConceptOrDraftIntent('Draft', userDeliverable)"
                                bold 
                                type="secondary" 
                                theme="dark" 
                                size="lg" 
                                icon="submit" 
                                :iconSize="5" 
                            />
                        </div>
                    </div>

                    <!-- if there is a selected draft -->
                    <div class="selected-draft" v-else>
                        <div class="title-container">
                            <div class="title">
                                {{selectedDraft.name}}               
                            </div>              
                        </div>        
                        <div class="content">

                            <div class="general-info">

                                <div v-if="selectedDraft.description && selectedDraft.description != ''">
                                    <div class="font-semibold">Description</div>                                        
                                    <div class="enriched-format" v-html="selectedDraft.description"></div>
                                </div>

                                <div class="actions">

                                    <base-button 
                                        label="Request New Draft"
                                        v-if="!projectDeleted && (!isGuest && !isClient && !isOnlyCreator) || allowEditing"
                                        @action="openShareCollectionLinkModal('Draft', userDeliverable)"
                                        theme="dark"
                                        size="xl" 
                                        bold
                                    />

                                    <base-button 
                                        v-if="selectedDraft.media && selectedDraft.media.length"
                                        @action="fullPreviewVisible = true" 
                                        label="View full preview" 
                                        class="font-bold" 
                                        size="lg"
                                    />

                                    <base-button 
                                        label="Add Draft" 
                                        v-if="!projectDeleted && (isUserAdminOrHigher || isOnlyCreator)"
                                        @action="addConceptOrDraftIntent('Draft', userDeliverable)" 
                                        bold 
                                        type="secondary" 
                                        theme="dark" 
                                        size="lg" 
                                        icon="submit" 
                                        :iconSize="5"
                                    />

                                </div>

                            </div>
                            
                            <ul class="media-list" v-if="selectedDraft.media && Array.isArray(selectedDraft.media) && selectedDraft.media.length">
                                <li v-for="(media, i) in selectedDraft.media" :key="i">
                                    <version-media-item
                                        :attachment="media.attachment"
                                        :audioAttachment="media.audio_attachment"
                                        @on-click="handleVersionMediaItemClick"
                                    />
                                </li>
                            </ul>
                            
                        </div>                
                   

                        <!-- comments section -->
                        <div class="comments">
                            <div class="header-wrapper">
                                <div class="container">
                                    Comments
                                    <span>({{ selectedDraft.comments ? selectedDraft.comments.length : 0 }})</span>
                                    <base-icon v-if="commentsLoading" class="ml-4" name="loader"/>
                                </div>
                            </div>

                            <div class="content">
                                <div class="form" v-if="!projectDeleted && !isGuest && !isClient">
                                    <FormulateInput
                                        type="textarea"
                                        class="w-full"
                                        placeholder="Write a comment..."
                                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                                        v-model="comment"
                                        :disabled="commentsLoading"
                                        :rows="1"
                                    />
                                    <div class="actions">
                                        <base-button
                                            @action="postComment(selectedDraft.id, 'draft')"
                                            size="lg" 
                                            theme="dark" 
                                            secondaryIcon="pencil"
                                            label="Add Comment"
                                            class="font-bold" 
                                            :disabled="comment == '' || commentsLoading"
                                        />
                                    </div>
                                </div>
                                <div class="comment-list">
                                    <div class="empty-state" v-if="selectedDraft.comments <= 0">No comments yet</div>
                                    <div class="item" v-for="comment in selectedDraft.comments" :key="comment.id">
                                        <div class="container">
                                            
                                            <avatar :user="comment.user"/>
                                            
                                            <template v-if="!comment.editing">
                                                <div class="content">
                                                    <div class="name-date">
                                                        <div class="name">{{ `${comment.user.first_name} ${comment.user.last_name}` }}</div>
                                                        <div class="date">{{ formatDateTime(comment.created_at) }}</div>
                                                    </div>
                                                    <div class="text">
                                                        {{ comment.comment }}
                                                        <span v-if="comment.created_at !== comment.updated_at">(Edited)</span>
                                                    </div>
                                                </div>
                                                <options-dropdown
                                                v-if="me.id == comment.user_id && !projectDeleted"
                                                    class="ml-auto"                                                                                                                                    
                                                    :options="[
                                                        {label: 'Edit', icon: 'pencil-alt', event: 'edit'},
                                                        {label: 'Delete', icon: 'trash', event: 'delete'}                                                
                                                    ]"                                        
                                                    @delete="deleteComment(comment)"
                                                    @edit="comment.editing = true; $forceUpdate();"
                                                />
                                            </template>

                                            <template v-else>
                                                <div class="editing">
                                                    <FormulateInput
                                                        type="textarea"
                                                        class="w-full"
                                                        placeholder="Write a comment..."
                                                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                                                        v-model="comment.comment"
                                                        :disabled="commentsLoading"
                                                        :rows="1"
                                                    />
                                                    <div class="loader" v-if="comment.loading"><base-icon name="loader" /></div>
                                                    <div class="actions">
                                                        <base-button
                                                            @action="comment.editing = false; $forceUpdate(); comment.comment = comment.editedComment"
                                                            size="sm" 
                                                            type="secondary"
                                                            theme="dark"                                                          
                                                            label="Cancel"
                                                            class="font-bold"                                                    
                                                        />
                                                        <base-button
                                                            @action="editComment(comment)"
                                                            size="sm" 
                                                            theme="dark"                                                          
                                                            label="Save"
                                                            class="font-bold" 
                                                            :disabled="comment.comment == '' || comment.loading"
                                                        />
                                                    </div>
                                                </div>
                                            </template>
                                            
                                        </div>
                                    </div>
                                </div>                            
                            </div>                        
                        </div>
                    </div>
                </div>

                <!-- right column -->
                <div class="right-column">
                    
                    <div class="phases-progress">
                        <div class="text">
                            {{ userDeliverable.phases_progress.other_description }}                        
                        </div>
                    </div>
                    
                    <template v-if="Object.keys(selectedDraft).length">
                        <div class="actions">
                            <!-- <span class="text-purple-m-secondary font-semibold">{{userDeliverableStatus}}</span>  -->
                            <base-button size="lg" class="font-bold" 
                                v-if="(!projectDeleted && isUserAdminOrHigher) || allowEditing"
                                :label="selectedDraft.approved_at == null ? 'Approve Draft' : 'Unapprove Draft'"
                                v-tooltip="!checkPermission ? { content: 'Only for project owners and admins', classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : null"
                                @action="selectedDraft.approved_at == null ? updateStatusIntent(1, 2) : updateStatusIntent(0, 2)"
                            />
                        </div>        
                        <div class="title-container">
                            <div class="title">
                                {{viewTab}} History
                            </div>              
                        </div>
                        <div v-if="!concepts.length">There are no drafts for this deliverable</div>
                        <div class="history-wrapper">
                            <div class="history-lines" v-if="drafts.length">
                                <span class="dashed"></span>
                                <span class="circle at-top">
                                    <span class="inner-circle" />
                                </span>
                                <span class="circle at-bottom">
                                    <span class="inner-circle" />
                                </span>
                            </div>
                            <template v-if="drafts.length > 0">
                                <div 
                                    v-if="userDeliverable && userDeliverable.project_user_deliverable_versions" 
                                    class="history-list"
                                >
                                    <button 
                                        v-for="version in drafts" 
                                        :key="version.id"
                                        class="version"
                                        :class="{ 'active': selectedDraft.id === version.id }"
                                        @click="selectDraft(version)"
                                    >
                                        <div class="name">
                                            <span class="text" :class="{ 'active': selectedDraft.id === version.id }">{{version.name}}</span>                                        
                                        </div>
                                        <div class="uploaded">
                                            <span class="title" :class=" { 'active': selectedDraft.id === version.id }">Uploaded</span>
                                            <div class="content">
                                                <div class="date-container">
                                                    <div class="icon-container">
                                                        <base-icon class="text-purple-m-main" name="calendar" :size="4" />
                                                    </div>
                                                    <span class="date" :class=" { 'active':  selectedDraft.id === version.id  }">{{version.created_at | moment('MMM D, Y')}}</span>
                                                </div>                            
                                                <div class="user-container" v-if="version.user">
                                                    <avatar-list :users="[version.user]" cursor="pointer" :theme="selectedDraft.id === version.id ? 'dark' : 'light'" />                                
                                                </div>                 
                                            </div>
                                        </div>                                                                                                   
                                        <div class="approved" v-if="version.approved_at !== null && version.approved_by_user !== null">
                                            <span class="title" :class=" { 'active': selectedDraft.id === version.id }">Approved</span>
                                            <div class="content">
                                                <div class="date-container">
                                                    <div class="icon-container">
                                                        <base-icon class="text-purple-m-main" name="calendar" :size="4" />
                                                    </div>
                                                    <span class="date" :class=" { 'active':  selectedDraft.id === version.id  }">{{version.approved_at | moment('MMM D, Y')}}</span>
                                                </div>                            
                                                <div class="user-container">
                                                    <avatar-list :users="[version.approved_by_user]" cursor="pointer" :theme="selectedDraft.id === version.id ? 'dark' : 'light'" />                                
                                                </div>                 
                                            </div>
                                        </div>
                                    </button>                                  
                                </div>
                            </template>                        
                            <div class="empty-state" v-else>
                                <div class="text-container">
                                    <span class="text">Empty</span>
                                </div>                                 
                            </div>
                        </div>
                    </template>

                </div>
            </div>                     
        </template>

        <!-- Posts Tab -->
        <template v-if="viewTab === 'Posts'">  
            <div class="content">
                <div class="left-column"> 
                    
                    <div class="linked-posts">

                        <div class="title-container">
                            <div class="title">
                                <template v-if="userDeliverable.posts_needed == null || userDeliverable.posts_needed == 0">
                                    No Linked Posts Needed
                                </template>
                                <template v-else-if="userDeliverable.posts_needed != null && userDeliverable.posts_needed > 0">
                                    {{`${this.computedDeliverablePosts.length}/${userDeliverable.posts_needed}`}} Linked Posts
                                </template>
                            </div>              
                        </div>

                        <div class="content">

                            <div class="general-info">

                                <div class="error" v-if="userDeliverable.network_id == 2 && fbLogin.error">
                                    * {{ fbLogin.computedMessage('link an instagram post') }}
                                </div>
                                
                                <div class="linked-posts-list" v-for="(url, index) in linkedPostsUrl" :key="index" v-show="guestOrHigherPermission">
                                    <FormulateInput
                                        type="text"
                                        class="w-full"
                                        placeholder="Network Link"
                                        label="Link New Post"
                                        :disabled="isGuest || isClient || allowEditing"
                                        v-model="linkedPostsUrl[index]"                                    
                                    />
                                </div>                                

                                <div class="display-posts-list" v-if="computedDeliverablePosts.length">
                                    <div v-masonry item-selector=".post-card" fit-width="true" transition-duration="0.3s" gutter="16" horizontal-order="true">
                                        <ReportSocialPostCard
                                            v-masonry-tile
                                            :allow-edit="false"
                                            :allow-unlink="guestOrHigherPermission"
                                            class="post-card mb-4"
                                            :post="post.post"
                                            :current-reports="currentReports"
                                            v-for="post in computedDeliverablePosts" :key="post.id"                                             
                                            @redraw="redrawPosts()"
                                            @unlink="unlinkPost"
                                            @attach="attachPost"
                                        />
                                    </div>                                    
                                </div>
                            </div>
                        </div>   
                    </div>                          
                </div>
                <div class="right-column">
                    <div class="phases-progress">
                        <div class="text">
                            {{ userDeliverable.phases_progress.other_description }}                        
                        </div>
                    </div>
                    <div class="actions">
                        <!-- <span class="text-purple-m-secondary font-semibold">{{userDeliverableStatus}}</span>  -->
                        <base-button size="lg" class="font-bold" 
                            v-if="!projectDeleted && isUserAdminOrHigher"
                            :label="`${ saving ? 'Adding' : 'Add' } Linked Post${ computedLinkedPosts.length !== 1 ? 's' : ''}`"
                            :disabled="!checkPermission || uncompletedLinks || saving"
                            v-tooltip="!checkPermission ? { content: 'Only for project owners and collaborators', classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : null"
                            @action="confirmAddLinkedPosts"
                        />
                    </div>                    
                </div>
            </div>                     
        </template>

    </template>

    <version-approval-email-modal
        v-if="userDeliverable.phases_progress && userDeliverable.phases_progress.code"
        :visible="versionApprovalEmailModalVisible"
        @close="versionApprovalEmailModalVisible = false"
        :deliverableName="userDeliverable.name"
        :version="versionApprovalEmailModalVersion"
        :creator="this.userDeliverable.project_user ? this.userDeliverable.project_user.user : null"
    />

    <share-collection-link-modal
        :visible="showShareCollectionLinkModal"
        @close="showShareCollectionLinkModal = false"
        :submitBy="submitBy"
        :type="shareCollectionType"
        :deliverableToken="this.userDeliverable.token"
        :creatorEmail="this.userDeliverable.project_user ? this.userDeliverable.project_user.user.email : null"
        :creatorName="this.userDeliverable.project_user ? `${this.userDeliverable.project_user.user.first_name} ${this.userDeliverable.project_user.user.last_name}` : null"
    />

    <create-version-modal
        v-if="!projectDeleted && (isUserAdminOrHigher || isOnlyCreator) && userDeliverable.token"
        :visible="showCreateVersionModal"
        @close="closeVersionModal"
        @done="closeVersionModal('done')"
        :isManual="true"
        :deliverable="userDeliverable"
        :projectName="project.name"
        :submissionType="activeSubmissionType"
        :key="manualConceptKey"
    /> 

    <update-status-confirmation-modal
        :visible="updateStatusConfirmationModalVisible"
        :update-status-confirmation-item="updateStatusConfirmationItem"
        :update-status-confirmation-type="updateStatusConfirmationType"
        @close="cancelUpdateStatusConfirmation"
        @confirm="updateStatus(1, updateStatusConfirmationType)"
    />

    <lightbox
        :visible="lightboxVisible"
        :attachment="selectedAttachment"
        :audioAttachment="selectedAudioAttachment"
        @close="lightboxVisible = false"
    />

    <full-preview
        :visible="fullPreviewVisible"
        :deliverable="userDeliverable"
        :selectedDraft="selectedDraft"
        @close="fullPreviewVisible = false"
    />

    <link-post-user-deliverable-modal
        :key="`link-post-${linkKey}`"
        :visible="openReportSelector"
        :prop-links="linkedPostsUrl.filter(l => l !== '')"
        @close="openReportSelector = false"
        @got-reports="handleReports"
        @attach-current-post="attachPost"
        @posts-linked="linkedPostsAction"
        :currentUserDeliverable="userDeliverable"
    />

    <update-deliverable-dates-modal
        :visible="showUpdateDeliverableDatesModal"
        @close="showUpdateDeliverableDatesModal = false"
        @deliverable-dates-updated="getData()"
        :projectId="project.id"
        :userDeliverable="userDeliverable"
    />

    <add-influencer-deliverable-modal
        :visible="editDeliverable"
        @close="editDeliverable = false"
        @user-deliverable-created="getData(false)"
        :projectId="project.id"
        :projectUserId="selectedProjectUser"
        :initial-step="4"
        :networks="availableNetworks"
        :currentUserDeliverable="selectedUserDeliverable"
    />

    <fixed-loader :visible="fixedLoaderLabel !== ''" :label="fixedLoaderLabel"/>

    <loader :visible="saving" :label="`Adding link${ computedLinkedPosts.length !== 1 ? 's' : ''}`"/>
</div>
</template>

<script>
import ContentSkeleton from './ContentSkeleton.vue'
import Loader from './../../components/Loader';
import VersionApprovalEmailModal from './VersionApprovalEmailModal.vue'
import UpdateDeliverableDatesModal from './UpdateDeliverableDatesModal.vue'
import AvatarList from '../../components/AvatarList';
import SectionEmptyState from '../../components/SectionEmptyState.vue';
import ShareCollectionLinkModal from '../../components/ShareCollectionLinkModal.vue';
import { mapGetters } from 'vuex';
import VersionMediaItem from './VersionMediaItem.vue';
import Lightbox from '../../components/Lightbox.vue';
import CreateVersionModal from '../VersionCreate/CreateVersionModal';
import FullPreview from './FullPreview.vue'
import ReportPostCard from '../../components/ReportPostCard';
import ReportSocialPostCard from '../../components/ReportSocialPostCard.vue';
import LinkPostUserDeliverableModal from '../../components/LinkPostUserDeliverableModal';
import DeliverableStatus from './DeliverableStatus.vue';
import AddInfluencerDeliverableModal from '../../components/AddInfluencerDeliverableModal.vue';
import {validateUrl} from '../../lib/strings';
import { errorMessage, isEmpty, getObjectValue } from '../../common';
import Header from './Header';
import moment from 'moment';
import { recommendationsAlert } from '../../lib/alerts';
import UpdateStatusConfirmationModal from './UpdateStatusConfirmationModal.vue';

export default {
    components: {
        AddInfluencerDeliverableModal,
        CreateVersionModal,
        ContentSkeleton,
        VersionApprovalEmailModal,
        AvatarList,    
        SectionEmptyState,
        ShareCollectionLinkModal,
        VersionMediaItem,
        LinkPostUserDeliverableModal,
        Lightbox,
        Loader,
        FullPreview,
        ReportPostCard,
        ReportSocialPostCard,
        DeliverableStatus,
        UpdateDeliverableDatesModal,
        Header,
        UpdateStatusConfirmationModal
    },
    data() {
        return {
            showCreateVersionModal: false,
            viewTab: 'Concept',
            currentUserDeliverable: {},
            loading: true,
            saving: false,
            showShareCollectionLinkModal: false,
            shareCollectionType: "Concept",
            userDeliverable: {
                deliverables: [],
                posts_needed: 0,
            },
            selectedConcept: {},
            selectedDraft: {},
            comment: '',
            fixedLoaderLabel: '',
            commentsLoading: false,
            lightboxVisible: false,
            linkKey: false,
            selectedAttachment: null,
            selectedAudioAttachment: null,
            versionApprovalEmailModalVisible: false,
            versionApprovalEmailModalVersion: null,
            addManualConcept: false,
            addManualDraft: false,
            manualConceptKey: false,
            fullPreviewVisible: false,
            dataReady: false,
            toggleDeliverables: false,
            hasReports: false,
            linkedPostsUrl: [],
            openReportSelector: false,
            instagramAccounts: [],
            currentReports: [],
            showUpdateDeliverableDatesModal: false,
            availableNetworks: [],
            editDeliverable: false,
            selectedProjectUser: null,
            selectedUserDeliverable: null,
            selectedColumn: null,

            updateStatusConfirmationModalVisible: false,
            updateStatusConfirmationType: null,
            updateStatusConfirmationItem: null,

            prevRoute: null
        }
    },

    computed: {
        ...mapGetters(['me', 'isUserAdminOrHigher', 'isProjectManager', 'isGuest', 'isClient', 'isOnlyCreator', 'fbLogin']),

        allowEditing(){
            return this.userDeliverable.project_user.project.share_info?.allow_editing
        },

        concepts(){
            return this.userDeliverable.project_user_deliverable_versions.filter(v => {
                return v.type === 1
            }).sort((a, b) => {
                if(b.approved_by_user === null){
                    return new Date(b.created_at) - new Date(a.created_at);
                }
                else{
                    return 1;
                }
            })
        },

        drafts(){
            return this.userDeliverable.project_user_deliverable_versions.filter(v => {
                return v.type === 2
            }).sort((a, b) => {
                if(b.approved_by_user === null){
                    return new Date(b.created_at) - new Date(a.created_at);
                }
                else{
                    return 1;
                }
            })
        },

        guestOrHigherPermission() {
            return this.guestPermission || !this.isGuest;
        },

        // allow editing project permissions
        guestPermission(){
            let { share_info } = this.userDeliverable.project_user.project;
            const allow_editing = share_info && !!share_info.allow_editing;
            return this.isGuest && allow_editing
        },

        checkPermission() {
            return this.isProjectOwner || this.isUserAdminOrHigher || this.allowEditing;
        },

        isProjectOwner() {
            let projectOwners = this.userDeliverable.project_user && this.userDeliverable.project_user.project ? this.userDeliverable.project_user.project.project_owners : []
            return !!(projectOwners && projectOwners.find(element => element.user.id === this.me.id))
        },

        project() {
            if (Object.keys(this.userDeliverable).length){
                return this.userDeliverable.project_user && this.userDeliverable.project_user.project ? this.userDeliverable.project_user.project : {};
            }
            return {};
        },

        projectDeleted() {
            return this.project.deleted_at !== undefined && this.project.deleted_at !== null;
        },

        userDeliverableStatus() {
            if(Object.keys(this.userDeliverable).length){
                return this.userDeliverable.phases_progress.other_description;
            }
            else{
                return 'Unknown Phase';
            }            
        },

        submitBy(){
            let { phases_progress, concept_approval, post_approval } = this.userDeliverable
            return phases_progress == 0 ? concept_approval : phases_progress == 10 ? post_approval : null
        },

        uncompletedLinks() {
            let links = this.linkedPostsUrl.filter(link => link !== '');
            return !links.length;
        },

        linkedPostsChanges(){
            let changes = false;
            for (let i = 0; i < this.linkedPostsUrl.length; i++) {
                const url = this.linkedPostsUrl[i];
                if(!this.userDeliverable.project_user_deliverable_posts[i]){
                    if(url !== ""){
                        changes = true;
                        break;
                    }
                }
            }
            return changes;
        },

        computedLinkedPosts() {
            return this.linkedPostsUrl.filter(link => link !== '');
        },

        computedDeliverablePosts() {
            return this.userDeliverable.project_user_deliverable_posts.filter(u => u.post);
        },

        activeSubmissionType() {
            if (typeof this.viewTab === 'string') {
                // call toLowerCase() safely
                return this.viewTab.toLowerCase();
            } else {
                return ( this.viewTab.toLowerCase() ) ? 'concept' : 'draft';
            }
        }
    },

    beforeMount() {
        this.$setTitle('Deliverable Details');
    },

    mounted() {
        this.getNetworks();
        this.getData();
        this.getInstagramAccounts();
        if (this.$route.query.tab) {
            this.viewTab = this.$route.query.tab;
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },

    watch: {
        viewTab(val) {
            this.$router.push({ query: { ...this.$route.query, tab: val }})
            if(val == 'Concept' && this.selectedConcept && this.selectedConcept.id){
                this.setVersionQuery(this.selectedConcept.id)
            }
            if(val == 'Draft' && this.selectedDraft && this.selectedDraft.id){
                this.setVersionQuery(this.selectedDraft.id)
            }    
        },

        toggleDeliverables(val) {
            if(this.dataReady){
                this.changeDeliverables(val);
            }
        },

        linkedPostsUrl(val){
            const found = val.find(element => element.includes('instagram.com') && element.includes('/stories'))
            if(found && found.includes('instagram.com') && found.includes('/stories')){
                let handle = found.split('/stories/')[1].split('/')[0]
                if(!this.instagramAccounts.map(element => element.username).includes(handle)){
                    this.linkedPostsUrl = this.linkedPostsUrl.map(element => element == found ? "" : element)
                    this.$swal.fire({
                        title: `Unable to get instagram stories!`,
                        text: `We are not able to get this creator's instagram stories because they haven't logged in with facebook yet.`,
                        icon: 'warning',
                        iconColor: '#0E092C',
                        showCancelButton: true,
                        confirmButtonText: this.userDeliverable?.project_user.user ? 'Invite to login with Facebook' : 'Ok',
                        reverseButtons: true,
                    }).then((result) => {
                        if(result.isConfirmed && this.userDeliverable?.project_user.user){
                            this.inviteToLoginWithFacebook(this.userDeliverable.project_user.user)
                        }
                    })
                }
            }
        }
    },

    methods: {
        setTab() {
            // if the tab is not defined on the route, get it from the user deliverable phases progress
            // we can check the tabs on API's "statuses.js" 
            if(this.$route.query.tab) return;
            if(this.userDeliverable.phases_progress && this.userDeliverable.phases_progress.tab){
                this.viewTab = this.userDeliverable.phases_progress.tab;
            }
        },
        formatDateTime(date){
            var now = moment();
            var d = moment(date)
            
            const sameMonth = d.month() === now.month()
            const sameDay = d.day() === now.day()
            const sameYear = d.year() === now.year()
            const sameWeek = d.isSame(now, "week")

            const month = `${sameMonth && sameYear ? `` : `MMM`}`
            const day = `${sameDay && sameMonth && sameYear ? `` : ` ${sameWeek ? `dddd` : `D`},`}`
            const years = `${sameYear ? `` : ` Y`}`

            return moment(d).format(`${month}${day}${years} hh:mm A`)
        },
        selectConcept(version) {
            this.selectedConcept = version;
            this.setVersionQuery(this.selectedConcept.id)
        },
        selectDraft(version){
            this.selectedDraft = version;
            this.setVersionQuery(this.selectedDraft.id)
        },
        closeVersionModal(action) {
            this.addManualConcept = false
            this.addManualDraft = false;
            this.showCreateVersionModal = false;

            if ( action == 'done' ) {
                this.getData();
            }
        },
        
        openShareCollectionLinkModal(type, userDeliverable){
            let showAlert = false;
            // if deliverable is already completed
            if(userDeliverable.phases_progress.code >= 30){
                showAlert = true;
            }
            if (showAlert) {
                this.$swal.fire({
                    title: `Are you sure you want to request a new ${type} ?`,
                    text: `The current status of the deliverable is: ${userDeliverable.phases_progress.display_name}`,
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    reverseButtons: true,
                }).then((result) => {
                    if(result.isConfirmed){
                        this.shareCollectionType = type; 
                        this.showShareCollectionLinkModal = true;
                    }
                });
            } else {
                this.shareCollectionType = type; 
                this.showShareCollectionLinkModal = true;
            }
        },

        addConceptOrDraftIntent(type, userDeliverable) {
            let showAlert = false;
            // if deliverable is already completed
            if(userDeliverable.phases_progress.code >= 30){
                showAlert = true;
            }
            if (showAlert) {
                this.$swal.fire({
                    title: `Are you sure you want to add a new ${type} ?`,
                    text: `The current status of the deliverable is: ${userDeliverable.phases_progress.display_name}`,
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Add',
                    reverseButtons: true,
                }).then((result) => {
                    if(result.isConfirmed){
                        this.addConceptOrDraft(type, userDeliverable);
                    }
                });
            } else {
               this.addConceptOrDraft(type, userDeliverable);
            }
        },

        addConceptOrDraft(type, userDeliverable) {
            
            // reset vars
            this.showLinkPostUserDeliverableModal = false;
            this.showCreateVersionModal = false;
            this.currentUserDeliverable = userDeliverable;

            if(type == 'Concept'){
                this.showCreateVersionModal = true;
                this.addManualConcept = true;
            }
            if(type == 'Draft'){
                this.showCreateVersionModal = true;
                this.addManualDraft = true;
            }

            // we used to have this logic but I dont know if it applies anymore
            // if(userDeliverable.phases_progress.status == 'link_post'){
            //     this.showLinkPostUserDeliverableModal = true;
            // }
            // if(userDeliverable.phases_progress.status == 'not_started'){
            //     this.showCreateVersionModal = true;
            // }
        },

        async getNetworks() {
            const { data } = await this.$http.get('/api/userDeliverables/networks');
            this.availableNetworks = data.map(network => {
                network.value = network.id;
                network.label = network.display_name;
                return network;
            });
        },

        handleReports(reports) {
            this.hasReports = reports.length > 0;
        },

        openEditDeliverable(projectId, projectUserId, userDeliverable){
            this.selectedProjectUser = projectUserId;
            this.selectedUserDeliverable = userDeliverable;
            this.selectedUserDeliverable.type = { value: this.selectedUserDeliverable.type };
            this.selectedColumn = null;
            this.editDeliverable = true;
        },

        async changeDeliverables(val) {
            try {
                let deliverables = [];

                if (val) {
                    
                    if(this.userDeliverable.concept_approval == null || this.userDeliverable.post_approval == null){

                        this.showUpdateDeliverableDatesModal = true;
                        return;

                    }else{
                        deliverables.push('concept_approval')
                        deliverables.push('post_approval')
                    }

                }

                await this.$http.put(`/api/userDeliverables/${this.$route.params.id}/deliverables`, {deliverables});

                this.userDeliverable.deliverables = deliverables;
            } catch (e) {
                this.$notify({ title: 'Error', text: 'The status could not update. Try again later', type: 'error' });
            }
        },
        manualConceptCompleted() {
            this.addManualConcept = false;
            this.manualConceptKey = !this.manualConceptKey;
            this.getData(false);
        },
        async getData(loading = true) {

            // get deliverable info with user and versions
            this.loading = loading;
            try {
                let { data } = await this.$http.get(`/api/userDeliverables/${this.$route.params.id}/details`);
                this.userDeliverable = data.project_user_deliverable;
                this.userDeliverable.phases_progress = data.phases_progress;
                this.currentReports = data.reports; 

                if ( this.userDeliverable.concept_approval !== null && this.userDeliverable.post_approval !== null ) {
                    this.toggleDeliverables = true;
                } else {
                    this.toggleDeliverables = false;
                }

                // format info
                // sort versions by created_at
                this.userDeliverable.project_user_deliverable_versions = this.userDeliverable.project_user_deliverable_versions.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                })
                
                this.userDeliverable.project_user_deliverable_versions.forEach((version, index) => {
                    version.comments.forEach(vc => {vc.editing = false; vc.editedComment = vc.comment; vc.loading});                
                });         

                // select default versions
                if(this.userDeliverable.project_user_deliverable_versions.length){                      
                    this.setVersion();
                }

                // populate linked posts            
                let needed = this.userDeliverable.posts_needed;
                let items = this.userDeliverable.project_user_deliverable_posts;
                if (items && Array.isArray(items) && items.length) {
                    needed = needed - items.length;
                    if (needed < 0) {
                        needed = 0;
                    }
                }
                this.linkedPostsUrl = Array(needed).fill("");
                // if(this.linkedPostsUrl.length >= 1){
                //     for (let index = 0; index < this.linkedPostsUrl.length; index++) {
                //         if(this.userDeliverable.project_user_deliverable_posts[index]){
                //             this.linkedPostsUrl[index] = this.userDeliverable.project_user_deliverable_posts[index].post.url;
                //         }
                //     }
                // }

                let empty = this.linkedPostsUrl.findIndex(l => l === '');
                if (empty < 0) {
                    this.linkedPostsUrl.push('');
                }

                setTimeout(() => {
                    const deliverableName = !isEmpty(this.userDeliverable) ? getObjectValue(this.userDeliverable, 'name') || `${getObjectValue(this.userDeliverable, 'deliverable.name')} Deliverable` : 'Deliverable Name';
                    this.$setTitle(deliverableName);
                    this.loading = false;
                    this.dataReady = true;
                    this.setTab();
                }, 500);

                this.getInstagramAccounts();
            } catch (error) {
                if (error.response.status >= 400) {
                // Handle the 400+ error
                
                this.$notify({ title: 'Error', text: error.response.data.message, type: 'error' });

                this.$router.push("/home");

                } else {
                    // Handle other errors
                    console.error(error);
                }
            }

        },

        setVersion(){
            const versions = this.userDeliverable.project_user_deliverable_versions
            let selectedConcept, selectedDraft;

            // if version type is concept (1) and is approved
            if(!Object.keys(this.selectedConcept).length){
                selectedConcept = versions.find(v => {return v.type === 1 && v.approved_by_user !== null});
            }

            // if version type is concept (1)
            if(!selectedConcept){
                selectedConcept = versions.find(v => {return v.type === 1});
            }

            // no selected concept
            if(!selectedConcept){
                selectedConcept = {};   
            }

            // if version type is draft (2) and is approved
            if(!Object.keys(this.selectedDraft).length){
                selectedDraft = versions.find(v => {return v.type === 2 && v.approved_by_user !== null});
            }

            // if version type is draft (1)
            if(!selectedDraft){
                selectedDraft = versions.find(v => {return v.type === 2});
            }

            // no selected draft
            if(!selectedDraft){
                selectedDraft = {};
            }

            this.selectedConcept = selectedConcept
            this.selectedDraft = selectedDraft

            // if route's ?version= exists, we prioritize it.
            if(this.$route.query.version){
                const found = versions.find(v => {
                    return v.id === parseInt(this.$route.query.version)
                });
                if(found){
                    if(found.type == 1){
                        this.selectedConcept = found
                        this.setVersionQuery(this.selectedConcept.id)
                    }
                    if(found.type == 2){
                        this.selectedDraft = found
                        this.setVersionQuery(this.selectedDraft.id)
                    }
                }
                return;
            }

            if(this.selectedConcept.id) this.setVersionQuery(this.selectedConcept.id)
            if(this.selectedDraft.id) this.setVersionQuery(this.selectedDraft.id) 
        
        },

        setVersionQuery(id){
            this.$router.push({ query: { ...this.$route.query, version: id }})
        },

        async getInstagramAccounts() {
            const userId = this.userDeliverable?.project_user?.user_id
            if(userId) {
                let { data } = await this.$http.get(`/api/facebook/instagram-accounts?user_id=${userId}`);
                if(data){
                    this.instagramAccounts = data
                } 
            }
        },

        inviteToLoginWithFacebook(user){
            this.loading = true
            this.$http.post('/api/invite/intent', {
                roleId: 13, // creator
                targetEmails: user.email,
                targetId: user.id,
                networkId: 6
            }).then(async (res) => {
                if(res.data){
                    await this.$http.post('/api/invite/social-login', { 
                        email: user.email,
                        url: `${window.location.origin}/social-login?t=${res.data.token}` ,
                        networkName: 'facebook'
                    })
                    this.$notify({ title: 'Success', text: 'Invite sent successfully', type: 'success' })
                }
            }).catch((err) => {
                console.error('sendRequest error', err)
                if(err.response && err.response.data && err.response.data.message){
                    this.$notify({ title: 'Warning', text: err.response.data.message, type: 'warn' })
                }else{
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
                }
            }).finally(() => {
                this.loading = false
            })  
        },

        redrawPosts(){
          setTimeout(() => {
            this.$redrawVueMasonry();
          }, 500);
        },

        async updateStatusIntent(progress, type) {
            if (progress) {
                this.updateStatusConfirmationModalVisible = true;
                this.updateStatusConfirmationType = type;
                this.updateStatusConfirmationItem = type === 1 ? this.selectedConcept : this.selectedDraft;
            } else {
                this.updateStatus(progress, type);
            }
        },

        async cancelUpdateStatusConfirmation() {
            this.updateStatusConfirmationModalVisible = false;
            this.updateStatusConfirmationType = null;
            this.updateStatusConfirmationItem = null;
        },
        
        async updateStatus(progress, type) {
            this.cancelUpdateStatusConfirmation();
            let body = {
                progress,
                versionType: type,
                approvedVersionId: null,                
                approvedByUserId: this.me.id,
            }
            if(type === 1){
                // concept
                body.approvedVersionId = this.selectedConcept.id;
            }
            else if(type === 2){
                // draft
                body.approvedVersionId = this.selectedDraft.id;
            }

            // get deliverable info with user and versions
            let { data } = await this.$http.put(`/api/userDeliverables/${this.$route.params.id}/status`, {...body});
            if (data) {

                if ( type === 1 ) {
                    this.selectedConcept.approved_at = data.approved_at;
                } else if ( type === 2 ) {
                    this.selectedDraft.approved_at = data.approved_at;
                }
                
                // if something was approved
                if(progress == 1){
                    this.versionApprovalEmailModalVisible = true
                    this.versionApprovalEmailModalVersion = type === 1 ? this.selectedConcept : type === 2 ? this.selectedDraft : null
                }

                this.setTab();
                this.$notify({ title: 'Success', text: 'Status updated successfully. Request new versions if needed.', type: 'success' });
                this.getData(false);
            }
            else {
                this.$notify({ title: 'Error', text: 'The status could not update. Try again later', type: 'error' });
            }     
            setTimeout(() => {
                //this.resetData();
            }, 500);
        },

        linkedPostsAction() {
            this.linkKey = !this.linkKey;
            this.openReportSelector = false;
            setTimeout(() => {
                this.resetData();
            }, 500);
        },

        async attachPost(reportId, postId) {
            try {
                let body = { reportId, postId };

                await this.$http.put(`/api/userDeliverables/${this.$route.params.id}/attach`, {...body});
                this.getData(false);
                this.$notify({ title: 'Success', text: 'Post attached to default report successfully.', type: 'success' });
            } catch (e) {
                this.$notify({ title: 'Error', text: 'The post could not be attach to the default report. Try again later', type: 'error' });
            }
        },
        
        fixUrl(uri) {
            if (uri.includes('http://')) {
                uri = uri.replace('http://', 'https://');
            }

            if (!uri.includes('https://')) {
                uri = 'https://' + uri;
            }

            return uri;
        },

        invalidLink(uri) {
            return uri.length > 10 && !validateUrl(uri);
        },

        async confirmAddLinkedPosts() {
            let valid = true;
            this.openReportSelector = false;

            let hasNetworkName = this.userDeliverable.network && this.userDeliverable.network.name;
            let networkName = hasNetworkName ? this.userDeliverable.network.name : null;

            for (let i = 0; i < this.linkedPostsUrl.length; i++) {
                let uri = this.linkedPostsUrl[i];
                if (uri === "") {
                    continue;
                }

                if (hasNetworkName) {
                    let shortenFB = networkName == 'facebook' && uri.includes(`https://fb.`);
                    valid = uri.includes(`://www.${networkName}.`) || shortenFB;
                }

                let fixedLink = this.fixUrl(uri);
                if (uri !== fixedLink) {
                    this.linkedPostsUrl[i] = fixedLink;
                    this.$forceUpdate();
                }

                if (this.invalidLink(uri)) {
                    valid = false;
                }

                if (!valid) {
                    break;
                }
            }

            if (valid) {
                this.openReportSelector = true;
            } else{
                let text = 'Please, try with another url link';
                if (hasNetworkName) {
                    text = `This deliverable expects content links from ${networkName}.`;
                }
                this.$swal.fire({
                    title: "Invalid network link",
                    text,
                    icon: 'warning',
                    iconColor: '#0E092C',                    
                    confirmButtonText: 'Ok', 
                    reverseButtons: true,   
                    showCancelButton: false,
                    customClass: {
                        cancelButton: 'hidden',
                        actions: 'justify-center'
                    }
                });
            }
        },

        async unlinkPost(postId){
            this.$swal.fire({
                title: `Are you sure you want to unlink this content?`,
                text: 'This post will be removed from all reports that contain it.',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Unlink',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.processUnlink(postId);
                }
            });
        },

        async processUnlink(postId) {
            try {
                this.fixedLoaderLabel = 'Detaching content';
                let { data } = await this.$http.delete(`/api/userDeliverables/${this.$route.params.id}/posts/${postId}`);
                if (data) {
                    this.$notify({ title: 'Success', text: 'Content unlinked successfully.', type: 'success' });
                }
                
                setTimeout(() => {
                    this.resetData();
                    this.fixedLoaderLabel = '';
                }, 1000);
            } catch (e) {
                this.fixedLoaderLabel = '';
                this.$notify({ title: 'Error', text: 'The content could not be unlinked. Try again later', type: 'error' });
            }
        },

        postComment(versionId, type) {
            this.commentsLoading = true
            this.$http.post(`/api/userDeliverableVersions/${versionId}/comment`, {
                comment: this.comment,
                type: type, 
                url: window.location.href, // for mail
                relativeUrl: this.$route.fullPath // for notifications
            }).then(({data}) => {
                this.comment = ''
                let obj = { ...data, editing: false, loading: false, editedComment: data.comment }
                if(this.activeSubmissionType === 'draft'){
                    this.selectedDraft.comments.push(obj)
                }
                if(this.activeSubmissionType === 'concept'){
                    this.selectedConcept.comments.push(obj)
                }            
                this.$notify({ title: 'Success', text: 'Comment Posted Successfully', type: 'success' })
            }).catch(err => {
                console.error('postComment error', err)
            }).finally(() => {
                this.commentsLoading = false
            });
        },

        deleteComment(comment) {
            comment.loading = true
            this.$http.post(`/api/userDeliverableVersions/${comment.id}/comment/delete`).then(({data}) => {
                // remove comment from array
                if(this.activeSubmissionType === 'draft'){
                    const index = this.selectedDraft.comments.findIndex(element => element.id === comment.id );
                    this.selectedDraft.comments.splice(index, 1);
                }
                if(this.activeSubmissionType === 'concept'){
                    const index = this.selectedConcept.comments.findIndex(element => element.id === comment.id );
                    this.selectedConcept.comments.splice(index, 1);
                }     
                this.$forceUpdate();                
                this.$notify({ title: 'Success', text: 'Comment deleted successfully', type: 'success' })
            }).catch(err => {
                console.log(err)
                this.$notify({ title: 'Error', text: errorMessage(err, 'The comment could not be deleted, try again later.'), type: 'error' });
            }).finally(() => {
                comment.loading = false
            })
        },

        async editComment(comment) {
            comment.loading = true;
            this.$http.post(`/api/userDeliverableVersions/${comment.id}/comment/edit`, {
                comment: comment.comment
            }).then(({ data }) => {
                this.$notify({ title: 'Success', text: 'Comment edited successfully', type: 'success' })
                comment.editing = false;
                comment.editedComment = comment.comment;
                comment.updated_at = ''
                this.$forceUpdate();
            }).catch(err => {
                console.log(err)
                this.$notify({ title: 'Error', text: errorMessage(err, 'The comment could not be edited. Try again later.'), type: 'error' });
            }).finally(() => {
                comment.loading = false;
            });
        },

        resetData() {            
            this.getData(false);
        },

        handleVersionMediaItemClick(attachment, contentType, audioAttachment){
            if(contentType == 'link'){
                window.open(attachment.url, '_blank')
            }else{
                this.selectedAttachment = attachment;
                this.selectedAudioAttachment = audioAttachment; 
                this.lightboxVisible = true
            }
        }
        
    }
}
</script>
<style lang="scss" scoped>
    .main-wrapper{
        @apply flex-1 min-w-0 bg-white overflow-auto;
        > .content{
            @apply flex gap-12;
            > .left-column{
                @apply w-8/12 overflow-hidden;
                > .none-selected{
                    @apply flex flex-col justify-between pl-12 py-10 gap-4;
                    > .title{
                        @apply text-h2 font-bold text-purple-m-secondary;
                    }
                    > .description{
                        @apply text-sm text-gray-headers;
                    }
                    > .actions{
                        @apply flex gap-3 mt-2;
                    }
                }
                > .selected-concept, .selected-draft, .linked-posts{
                    > .title-container{
                        @apply flex justify-between pl-12 py-10;
                        > .title{
                            @apply text-h2 font-bold text-purple-m-secondary;
                        }
                    }
                    > .content{
                        @apply pl-12 pb-10 flex flex-col gap-6 w-full;
                        > .general-info{
                            @apply flex flex-col gap-6 justify-center items-start text-purple-m-secondary;
                            > .actions{
                                @apply flex justify-between w-full;
                            }
                            > .media-list{
                                @apply flex flex-col gap-y-2;
                            }
                            > .error{
                                @apply text-pxs;
                            }
                            > .linked-posts-list{
                                @apply w-full;
                            }
                            > .display-posts-list{
                                @apply flex w-full justify-start;
                            }
                        }
                        
                    }
                    > .comments{
                        @apply pb-8;
                        > .header-wrapper{
                            @apply flex justify-between pl-12 py-10;
                            > .container{
                                @apply text-h2 font-bold text-purple-m-secondary;
                                > &span{
                                    @apply text-gray-m-disable;
                                }
                            }
                        }
                        > .content{
                            @apply flex flex-col justify-start;
                            > .form{
                                @apply flex flex-col gap-5 px-12 justify-start;
                                > .actions{
                                    @apply w-full flex justify-start;
                                }
                            }
                            > .comment-list{
                                @apply flex flex-col gap-10 px-12 pt-10 pb-10;
                                > .empty-state{
                                    @apply font-semibold italic;
                                }
                                > .item{
                                    @apply relative;
                                    > .container{
                                        @apply flex gap-4 items-start relative w-full;
                                        > .content{
                                            @apply flex flex-col items-start justify-end gap-1;
                                            > .name-date{
                                                @apply flex items-center gap-x-2;
                                                > .name{
                                                    @apply font-bold;
                                                }
                                                > .date{
                                                    @apply text-pxs text-gray-m-disable;
                                                }
                                            }
                                            > .text{
                                                @apply text-pm text-purple-m-secondary;
                                                > span{
                                                    @apply text-pxs font-normal text-gray-m-disable;
                                                }
                                            }
                                        }
                                        > .editing{
                                            @apply flex flex-col gap-2 w-11/12 relative;
                                            > .actions{
                                                @apply w-full flex justify-end gap-2;
                                            }
                                            > .loader{
                                                @apply absolute top-2 right-2;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            > .right-column{
                @apply w-4/12;
                > .phases-progress{
                    @apply flex flex-col justify-between pr-12 py-10;
                    > .text{
                        @apply font-bold text-purple-m-secondary;
                    }
                }
                > .actions{
                    @apply pb-10 flex;
                }
                > .title-container{
                    @apply flex justify-between pr-12 py-10;
                    .title{
                        @apply text-h2 font-bold text-purple-m-secondary;
                    }
                }
                > .history-wrapper{
                    @apply flex gap-8 justify-between pr-12 pb-10;
                    > .history-lines{
                        @apply relative w-8 h-auto flex flex-col items-center;
                        > .dashed{
                            @apply absolute w-0 h-full border border-purple-m-secondary border-dashed;
                        }
                        > .circle{
                            @apply absolute w-6 h-6 rounded-full border border-purple-m-secondary bg-white flex items-center justify-center;
                            &.at-top{
                                @apply top-0;
                            }
                            &.at-bottom{
                                @apply bottom-0;
                            }
                            > .inner-circle{
                                @apply w-3 h-3 rounded-full bg-purple-m-secondary;
                            }
                        }
                    }
                    > .history-list{
                        @apply flex w-full flex-col gap-4 justify-between;
                        > .version{
                            @apply w-full p-6 bg-tan-m flex flex-col items-start gap-2 rounded-md transition ease-in-out duration-150;
                            &.active{
                                @apply bg-gradient-to-b from-gradient-m-main-start to-gradient-m-main-end;
                            }
                            &:focus{
                                @apply outline-none;
                            }
                            > .name{
                                @apply flex flex-col;
                                > .text{
                                    @apply text-left font-semibold text-purple-m-secondary;
                                    &.active{
                                        @apply text-white;
                                    }
                                }
                            }
                            > .uploaded, .approved{
                                @apply flex flex-col w-full;
                                > .title{
                                    @apply text-left text-ps text-purple-m-secondary;
                                    &.active{
                                        @apply text-white;
                                    }
                                }
                                > .content{
                                    @apply flex justify-between items-center;
                                    > .date-container{
                                        @apply flex gap-2 items-center;
                                        > .icon-container{
                                            @apply inline-flex items-center justify-center rounded-full bg-white w-6 h-6;
                                        }
                                        > .date{
                                            @apply text-left font-semibold text-ps text-purple-m-secondary;
                                            &.active{
                                                @apply text-white;
                                            }
                                        }
                                    }
                                    > .user-container{
                                        @apply h-8 flex items-center gap-2 font-semibold;
                                    }
                                }
                            }
                        }
                    }
                    > .empty-state {
                        @apply flex w-full flex-col gap-4 justify-between;
                        > .text-container{
                            @apply w-full p-6 flex flex-col items-start gap-2 rounded-md bg-tan-m transition ease-in-out duration-150;
                            &:focus{
                                @apply outline-none;
                            }
                            > .text{
                                @apply font-semibold text-purple-m-secondary;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
