<template>
    <div 
        class="handle"
        @click="viewOnPlatform"
        v-tooltip="ready ? { 
            content: verified ? 'Verified handle, click to view on platform' : 'This handle is not verified, click to view on platform', 
            classes: 'v-tooltip-black',
            delay: { show: 100, hide: 50 } 
        } : {}"
    >
        <base-icon v-if="loading" name="loader" />
        <div v-if="ready" class="verified-container">
            <base-icon
                :class="verified ? 'text-green-500' : 'text-yellow-300'"
                :name="verified ? 'check' : 'exclamation'" 
                :size="4"
            />
        </div>
        {{value}}
    </div>
</template>
<script>
import { isValidObject, isValidString, notifyCatchError } from '../common'
export default {
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        network: {
            type: Object,
            required: false
        },
        verifyHandle: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data(){
        return {
            verified: false,
            ready: true,
            loading: false
        }
    },
    mounted(){
        if(this.verifyHandle == true && isValidString(this.value) && isValidObject(this.network) && isValidString(this.network.name)){
            this.verify();
        }
    },
    methods: {
        verify(){
            this.loading = true;
            this.$http.post('/social-api-service/get/verify-handle', {
                handle: this.value,
                network_name: this.network.name
            }).then(({ data }) => {
                if(data && data.verified){
                    this.verified = data.verified;
                }
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
                this.ready = true;
            });
        },
        viewOnPlatform(){
            if(isValidString(this.value) && isValidObject(this.network) && isValidString(this.network.name)){
                if(this.network.name == 'instagram'){
                    window.open(`https://instagram.com/${this.value}`, '_blank');
                }
                if(this.network.name == 'tiktok'){
                    window.open(`https://tiktok.com/@${this.value}`, '_blank');
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .handle{
        @apply rounded-lg;
        @apply flex items-center gap-x-2;
        @apply truncate;
        @apply cursor-pointer transition-colors duration-200;

        width: 180px;
        max-width: 180px;
        min-width: 180px;

        &:hover{
            @apply bg-gray-200;
        }
        > .verified-container{
            @apply flex items-center;
        }
    }
</style>
