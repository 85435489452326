<template>
    <div class="report-post-list-wrapper">
        <div :class="['report-post-list-container', `md:grid-cols-${computedColumns}`]">
            <div class="general-info">
                
                <div class="left">
                    <img class="post-preview" :src="displayUrl"/>
                    <img class="profile-picture" :src="prepareImage(post.profile_item.profile_picture_url)" @error="changeProfile"/>
                </div>

                <div class="right">
                    <base-button :link="post.profile_item.uri" type="label" justify="start">
                        <p class="name">
                            {{ parseUnicodeCharacters(post.profile_item.full_name ? post.profile_item.full_name : post.profile_item.username) }}
                        </p>
                    </base-button>
                    <div class="network">{{ post.network.name }}</div>
                </div>

            </div>
            <div class="stat" v-if="columns.includes('date')">{{ customizedDate(post.date) }}</div>
            <div class="stat" v-if="columns.includes('likes')">{{ getPostStat('likes') | numeral('0.0a') }}</div>
            <div class="stat" v-if="columns.includes('comments')">{{ getPostStat('comments') | numeral('0.0a') }}</div>
            <div class="stat" v-if="columns.includes('shares')">{{ getPostStat('shares') | numeral('0.0a') }}</div>
            <div class="stat" v-if="columns.includes('saves')">{{ getPostStat('saves') | numeral('0.0a') }}</div>
            <div class="stat" v-if="columns.includes('views')"><span v-if="getPostStat('views')">{{ getPostStat('views') | numeral('0.0a') }}</span><span v-else>-</span></div>
            <div class="actions" :class="{'col-span-2': checkCols(9)}" v-if="!printView">
                <button 
                    class="view-content-button" 
                    @click="openLink(post.url)"
                >
                    View content
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { prepareAsB64 } from "../../lib/strings";
    const defaultPicture = require('../../assets/images/default-picture.png');
    const defaultProfile = require('../../assets/images/default-profile.jpg');
    import moment from 'moment'
    import { parseUnicodeCharacters } from '../../lib/strings';
    import { getBoostedData } from '../../lib/fbBoostedData';
    export default {
        props: ['post', 'columns', 'printView'],

        computed: {
            computedColumns() {
                let number = 5;
                if (this.printView) {
                    number--;
                }
                return number + this.columns.length;
            },
            computedInlineClass() {
                let columns = this.columns.length;
                return `w-1/${columns} md:w-full flex flex-wrap flex-col-reverse justify-center content-center`;
            },
            displayUrl() {
                // return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAFCAYAAABW1IzHAAAAHklEQVQokWNgGPaAkZHxPyMj439sYrSQo51PBgsAALa0ECF30JSdAAAAAElFTkSuQmCC';
                return prepareAsB64(this.post.display_url);
            }
        },

        data() {
            return {
                defaultPictureBg: defaultPicture,
                parseUnicodeCharacters
            }
        },
        methods: {
            getPostStat(property){
                let val = this.post[property];

                // Aggregate the boosted number if found, otherwise it returns 0
                val += getBoostedData(this.post.boosted_data, property);

                return parseInt(val);
            },
            prepareImage(url) {
                return prepareAsB64(url);
            },
            changeProfile() {
                this.post.profile_item.profile_picture_url = defaultProfile;
            },
            customizedDate (_date) {
                let date = new Date(_date);
                let tenYearsAgo = new Date();
                tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
                if (date < tenYearsAgo) {
                    // * when dates are null, the year 1969 is returned, in those cases we use created_at, or return null
                    // date = post.created_at;
                    return '-';
                }
                return moment(date).format('ll');
            },
            openLink(url) {
                window.open(url);
            },
            checkCols(cols) {
                return this.computedColumns === cols;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .report-post-list-wrapper{
        @apply w-full bg-white rounded-2xl;
        .report-post-list-container{
            @apply w-full flex flex-wrap justify-start text-center py-3 items-center;
            @media (min-width: 768px) {
                @apply grid;
            }
            > .general-info{
                @apply text-left ml-4 mb-4 w-full flex;
                @media (min-width: 768px) {
                    @apply mb-0 col-span-3;
                }
                > .left{
                    @apply w-16 h-16 relative bg-gray-100;
                    > .post-preview{
                        @apply absolute w-16 h-16;
                    }
                    > .profile-picture{
                        @apply absolute rounded-full w-8 h-8;
                    }
                }
                > .right{
                    @apply w-full px-2 flex flex-col justify-center;
                    .name{
                        @apply font-bold text-base capitalize;
                    }
                    .network{
                        @apply text-sm text-gray-m-disable capitalize;
                    }
                }
            }
            > .stat{
                @apply capitalize text-sm text-gray-m-disable;
            }
            > .actions{
                @apply flex justify-center items-center;
                .view-content-button{
                    @apply rounded-full bg-tan-m text-sm font-bold py-4 w-4/5 transition-colors duration-200;
                    &:hover{
                        @apply bg-green-m-main;
                    }
                    &:focus{
                        @apply outline-none;
                    }
                }
            }
        }
    }
</style>
