<template>
    <div class="container">
        <div class="title">
            Linked Accounts
        </div>

        <div class="platform-list" v-for="account in availables" :key="account.id">

            <div class="name">
                <base-brand :name="account.icon"/>
                <span class="font-bold">{{ isLinked(account) ? 'Linked' : 'Unlinked' }}</span>
            </div>
            <div class="font-bold">Link your <span class="capitalize">{{ account.name }}</span> account</div>
            <div class="text-sm" v-if="account.description">{{ account.description }}</div>

            <!-- facebook -->
            <template v-if="account.network_id === 6">

                <base-icon name="loader" v-if="linking || processing || socialLoginsLoading || fbLoading" />

                <template v-else>
                    
                    <div v-if="account.hasSocialLogin && !fbLogin.is_valid" class="alert" role="alert">
                        <p class="font-bold">Invalid token</p>
                        <p>Log in again to grant the necesarry permissions.</p>
                    </div>

                    <div class="info">

                        <div class="flex gap-x-2 justify-between items-start">

                            <div v-if="!fbLogin.is_valid && !account.hasSocialLogin && !fb.initialized && fb.sdkError">
                                <div>We're unable to load this feature due to a Facebook SDK initialization issue. This might be because you're using adblockers or your browser is blocking the Facebook SDK.</div>
                                <base-button
                                    class="mt-4"
                                    label="Troubleshoot"
                                    @action="showFbSdkErrorTroubleshoot()"
                                />
                            </div>

                            <facebook-login 
                                v-if="!fbLogin.is_valid" 
                                :disabled="linking || processing" 
                                @success="successLink"
                            />

                            <base-button
                                v-if="account.hasSocialLogin"
                                label="Details"
                                @action="facebookDetailsModalVisible = true"
                                size="md"
                                :disabled="linking || processing" 
                                icon="info-circle"
                                class="mt-6"
                            />

                            <base-button
                                v-if="account.hasSocialLogin"
                                theme="dark"
                                :disabled="linking || processing" 
                                label="Remove connection"
                                size="xlg"
                                icon="trash"
                                class="mt-6"
                                @action="deleteSocialLoginIntent(account)"
                            />

                        </div>

                        <facebook-details-modal
                            title="Facebook Details"
                            :visible="facebookDetailsModalVisible"
                            @close="facebookDetailsModalVisible = false"
                            @onFacebookLoginSuccess="data => {
                                successLink(data);
                                facebookDetailsModalVisible = false;
                            }"
                        />
                    
                    </div>
                </template>
            
            </template>
            
            <!-- instagram -->
            <template v-else-if="account.network_id === 2">

                <!-- facebook connected but no ig account -->
                <div class="alert" role="alert" v-if="fbLogin.is_valid && (!fbLogin.ig_account || typeof fbLogin.ig_account !== 'object' || !Object.keys(fbLogin.ig_account).length)">
                    <p class="font-bold">No instagram account linked</p>
                    <p>Facebook is connected but there is no instagram account linked to that facebook account. Click on the button below and follow the steps to link your instagram account.</p>
                </div>

                <div class="info">

                    <div class="flex gap-x-2 justify-between items-start">

                        <div v-if="!fbLogin.is_valid && !account.hasSocialLogin && !fb.initialized && fb.sdkError">
                            <div>We're unable to load this feature due to a Facebook SDK initialization issue. This might be because you're using adblockers or your browser is blocking the Facebook SDK.</div>
                            <base-button
                                class="mt-4"
                                label="Troubleshoot"
                                @action="showFbSdkErrorTroubleshoot()"
                            />
                        </div>

                        <div v-if="!fbLogin.is_valid && account.fb_business_token" class="col-span-2 w-full">
                            <div>Connected through Facebook Business Manager</div>
                        </div>
                        
                        <template v-if="!isLinked(account)">
                            <div v-if="fbLogin.is_valid && (!fbLogin.ig_account || typeof fbLogin.ig_account !== 'object' || !Object.keys(fbLogin.ig_account).length)">
                                <base-button
                                    class="mt-6"
                                    label="Link Instagram"
                                    icon="info-circle"
                                    size="lg"
                                    @action="showLinkInstagramTroubleshoot()"
                                />
                            </div>
                            <facebook-login
                                v-else
                                :disabled="linking || processing" 
                                action="request-instagram-permissions"
                                @success="successLink"
                            />
                        </template>

                        <base-button
                            v-if="isLinked(account)"
                            label="Details"
                            @action="facebookDetailsModalVisible = true"
                            size="md"
                            :disabled="linking || processing" 
                            icon="info-circle"
                            class="mt-6"
                        />

                    </div>
                </div>
            </template>

            <!-- tiktok -->
            <template v-else-if="account.network_id === 14">

                <div class="flex gap-x-4" v-if="isLinked(account)">
                    <base-button label="Unlink" theme="dark" :disabled="processing" class="mt-6" @action="toggleAccount(account)" />
                    <base-button label="Details" @action="tiktokDetailsModalVisible = true" size="md" :disabled="linking || processing" icon="info-circle" class="mt-6" />
                    <tiktok-details-modal
                        title="TikTok Details"
                        :access-token="account.access_token"
                        :visible="tiktokDetailsModalVisible"
                        @successLink="data => successLink(data)"
                        @close="tiktokDetailsModalVisible = false"
                    />
                </div>

                <tiktok-login v-else :disabled="linking" @success="successLink" />
             
            </template>

            <!-- other platforms -->
            <div v-else class="info">
                <base-button
                    v-if="account.hasSocialLogin"
                    theme="dark"
                    :disabled="processing"
                    :label="account.active ? 'Unlink' : 'Link'"
                    @action="toggleAccount(account)"
                />
                <component
                    v-else
                    is-logged
                    :is="account.component"
                    :disabled="linking"
                    @success="successLink"
                />
            </div>

        </div>

    </div>
</template>

<script>
    import BaseBrand from './BaseBrand';
    import TiktokLogin from '@/components/TiktokLogin';
    // import BusinessTiktokLogin from '@/components/BusinessTiktokLogin';
    import GoogleLogin from '@/components/GoogleLogin';
    import FacebookLogin from '@/components/FacebookLogin/index';
    import TwitterLogin from '@/components/TwitterLogin';
    import { facebookLogout } from '@/lib/facebook';
    import { mapGetters } from 'vuex'
    import FacebookDetailsModal from './FacebookDetailsModal.vue';
    import TiktokDetailsModal from './TiktokDetailsModal.vue';
    export default {
        name: 'LinkedAccounts',
        components: { BaseBrand, TiktokLogin, FacebookLogin, GoogleLogin, TwitterLogin, FacebookDetailsModal, TiktokDetailsModal },
        props: {
            isLogged: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                processing: false,
                linking: false,
                socialLoginsLoading: false,
                accounts: [],
                availableAccounts: [
                    {
                        id: 10,
                        network_id: 10,
                        name: 'google',
                        // description: 'Link your Google account',
                        icon: 'google',
                        component: 'google-login',
                        enabled: true
                    },
                    {
                        id: 6,
                        network_id: 6,
                        name: 'facebook',
                        // description: 'Link your Facebook account',
                        icon: 'facebook',
                        component: null,
                        enabled: true
                    },
                    {
                        id: 2, // 6 because its login with facebook
                        network_id: 2,
                        name: 'instagram',
                        // description: 'Link your Facebook account',
                        icon: 'instagram',
                        component: null,
                        enabled: true
                    },
                    // {
                    //     id: 7,
                    //     network_id: 7,
                    //     name: 'twitter',
                    //     // description: 'Link your Twitter account',
                    //     icon: 'twitter',
                    //     component: 'twitter-login',
                    //     enabled: true
                    // },
                    {
                        id: 14,
                        name: 'tiktok',
                        // description: 'Link your Tiktok account',
                        icon: 'tiktok',
                        component: 'tiktok-login',
                        enabled: true
                    },
                    // {
                    //     id: 18,
                    //     name: 'business tiktok',
                    //     description: 'Link your Business Tiktok account',
                    //     icon: 'tiktok',
                    //     component: 'business-tiktok-login',
                    //     enabled: true
                    // },
                ],
                facebookDetailsModalVisible: false,
                tiktokDetailsModalVisible: false
            }
        },
        beforeMount() {
            this.getSocialLogins();
        },
        computed: {
            ...mapGetters(['me', 'fb', 'fbLogin', 'fbLoading', 'isCreator']),
            availables() {
                return this.availableAccounts.filter(a => a.enabled).map(account => {

                    let hasSocialLogin = false, active = false, accountId = null;
                    let isInstagram = account.id === 2 ? true : false; 
                    let social = this.accounts.find(a => a.network_id === (isInstagram ? 6 : account.id));

                    if (social) {
                        hasSocialLogin = true;
                        accountId = social.id;
                        active = social.active;
                        account.network_id = (isInstagram ? 2 : social.network_id);
                        account.access_token = social.access_token 
                        account.long_lived_access_token = social.long_lived_access_token
                        account.fb_business_token = social.fb_business_token 
                    }

                    account.hasSocialLogin = hasSocialLogin;
                    account.accountId = accountId;
                    account.active = active;
                    return account;
                });
            },
            hasGoogle() {
                return this.$gAuth;
            }
        },
        methods: {
            isLinked(account){
                if(account.name === 'facebook'){
                    return account.hasSocialLogin && this.fbLogin.is_valid
                }
                if(account.name === 'instagram'){
                    const { ig_account } = this.fbLogin
                    if(account.hasSocialLogin && ig_account && typeof ig_account === 'object' && Object.keys(ig_account).length){
                        return true
                    } 
                    return false
                }
                return account.hasSocialLogin && account.active
            },
            async successLink(data) {
                const { userId, email, accessToken, networkId } = data;

                this.linking = true;
                this.$http.post(`/api/auth/register/link-account`, {
                    network_id: networkId,
                    profile_id: userId,
                    email,
                    ...(accessToken ? { access_token: accessToken } : {})
                }).then(response => {
                    this.$notify({ title: 'Success', text: `Account Linked Successfully`, type: 'success' });
                    this.getSocialLogins();
                    this.$store.dispatch('fb/getLoginStatus');
                    this.$store.dispatch('auth/getProfileCompletion');
                    if(this.me && this.isCreator){
                        this.$store.dispatch('fb/generateAudienceData', this.me.id);
                    }
                }).catch((err) => {
                    this.$notify({ title: 'Error', text: `${(err.response && err.response.data) ? err.response.data.message : ``}`, type: 'error' });
                    console.log('toggleSocialLogins error', err)
                }).finally(() => {
                    this.linking = false;
                });
            },
            async getSocialLogins(){
                this.socialLoginsLoading = true
                this.$http.get('/api/social-login').then(async (res) => {
                    this.accounts = res.data
                }).catch((err) => {
                    console.log('getSocialLogins error', err)
                }).finally(() => {
                    this.socialLoginsLoading = false
                })
            },
            toggleAccount(account) {
                account.active = !account.active;
                this.processing = true;

                if (!account.active && account.id === 6) {
                    facebookLogout();
                }

                if (!account.active && account.id === 10 && window.google !== undefined) {
                    window.google.accounts.id.revoke(this.me.email, done => {
                        console.log('Google logged out');
                    });
                }

                this.$http.post(`/api/social-login/${account.accountId}/toggle`, account).then(async (res) => {
                    await this.getSocialLogins();
                }).catch((err) => {
                    console.log('toggleSocialLogins error', err)
                }).finally(() => {
                    this.processing = false;
                })
            },
            deleteSocialLoginIntent(account){
                this.$swal.fire({
                    title: `Are you sure you want to remove this ${account.name} connection?`,
                    text: `All connection data will be removed`,
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteSocialLogin(account);              
                    }
                });
            },
            deleteSocialLogin(account){
                this.processing = true;
                this.$http.post(`/api/social-login/${account.accountId}/delete`).then(async (res) => {
                    this.$notify({ title: 'Success', text: `Connection removed successfully`, type: 'success' });
                    await this.getSocialLogins();
                    await this.$store.dispatch('fb/getLoginStatus');
                }).catch((err) => {
                    console.log('deleteSocialLogin error', err)
                    this.$notify({ title: 'Error', text: `${(err.response && err.response.data) ? err.response.data.message : ``}`, type: 'error' });
                }).finally(() => {
                    this.processing = false;
                })
            },
            showLinkInstagramTroubleshoot(){
                this.$swal.fire({
                    title: `Link Instagram Account`,
                    html: `
                        <ul class='mt-6 flex flex-col gap-y-4 list-disc text-left'>
                            <li>On mobile, go to your Instagram profile</li>
                            <li>Click "Edit profile"</li>
                            <li>Under "Profile information" select "Page"</li>
                            <li>Ensure the correct Facebook Page is linked to your Instagram account</li>
                            <li>If not, "Change or create Page"</li>
                        </ul>
                    `,
                    showCancelButton: true,
                    confirmButtonText: 'Done',
                    reverseButtons: true,
                }) 
            },
            showFbSdkErrorTroubleshoot(){
                this.$swal.fire({
                    title: `Troubleshoot`,
                    html: `
                        <ul class='flex flex-col gap-y-4'>
                            <li>
                                <div class='font-bold'>a. Disable Adblockers:</div>
                                <div>If you're using an adblocker, please consider disabling it for this website to ensure the proper functioning of our features.</div>
                            </li>

                            <li>
                                <div class='font-bold'>b. Adjust Browser Settings:</div>
                                <div>You can check your browser settings and ensure that any privacy or security features are not blocking the Facebook SDK. Try disabling any extensions that may interfere with the SDK's functionality.</div>
                            </li>

                            <li>
                                <div class='font-bold'>c. Use a Different Browser:</div>
                                <div>If the issue persists, you can try using a different browser to access our website. Sometimes, certain browsers have stricter security settings that might affect the Facebook SDK.</div>
                            </li>

                            <li>
                                <div class='font-bold'>d. Contact Support:</div>
                                <div>If none of the above steps resolve the issue, please contact our support team for further assistance. We'll be happy to help you resolve this problem.</div>
                            </li>
                        </ul>
                    `,
                    showCancelButton: true,
                    confirmButtonText: 'Done',
                    reverseButtons: true,
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container{
        @apply flex flex-col gap-6 px-12 py-10;
    }
    .title{
        @apply text-h2 font-bold text-purple-m-secondary mb-4;
    }
    .alert{
        @apply bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4;
    }
    .platform-list{
        @apply p-8 bg-tan-m flex flex-col gap-3 w-full rounded;
        @media (min-width: 1024px) {
            @apply w-1/2;   
        }
        .name{
            @apply flex justify-between items-center mb-4;
        }
        .info{
            @apply flex justify-between items-center;
        }
    }
    .connection-status{
        @apply flex items-center gap-x-2;
        & .dot{
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: yellow;
            &.connected{
                background-color: green;
            }
        }
    }
</style>
