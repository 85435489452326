<template>
    <div class="navigation-error">
        <div class="wrapper">
            <div class="container">
                <div class="icon">
                    <img src="../../assets/images/exception.png"/>
                    <p>{{ code }}</p>
                </div>
                
                <div class="content">
                    <div class="title">Whoops!</div>
                    <div class="description" v-if="code == 401">You are not authorized to see this page.</div>
                    <div class="description" v-else-if="code == 403">You do not have sufficient permissions to view this page.</div>
                    <div class="description" v-else>It looks like the page you’re looking for was either moved or doesn’t exist anymore.</div>

                    <div class="required-roles" v-if="$route.query.role && code == 403">You are missing the following required permissions: {{ roles }}</div>
                    <base-button @action="returnFn" label="Go Back" theme="dark-mode"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        roles(){
            const role = this.$route.query.role;
            // if its multiple roles
            if(role && role.includes(',')){
                return role.split(',').join(', ');
            }
            return role; 
        },
        code() {
            return this.$route.query.code || 404;
        }
    },
    methods: {
        returnFn() {
            if (this.code == 403) {
                this.$router.go(-2);
            } else {
                this.$router.back();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .navigation-error{
        @apply flex w-full h-full items-center justify-center relative z-0 overflow-y-auto px-12 py-10 bg-purple-m-main text-white;
        &:focus{
            @apply outline-none;
        }
        > .wrapper{
            @apply flex w-full h-full items-center justify-center min-w-0 w-1/2;
            > .container{
                @apply flex flex-col gap-8 w-full h-full items-center justify-center;
                > .icon{
                    @apply h-72 w-auto relative;
                    > img{
                        @apply h-full;
                    }
                    > p{
                        @apply font-semibold text-5xl absolute;
                        top: 24%;
                        right: 12%;
                    }
                }
                > .content{
                    @apply flex flex-col gap-6 items-center;
                    > .title{
                        @apply font-bold text-4xl;
                    }
                    > .description{
                        @apply font-semibold w-3/4 text-center;
                    }
                    > .required-roles{
                        @apply font-semibold w-3/4 text-center;
                    }
                }
               
               
            }
        }
    }
</style>