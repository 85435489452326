<template>
<div class="min-h-screen bg-purple-m-main flex flex-col justify-center py-12 sm:px-6 lg:px-8">

    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-12 w-auto" src="../../assets/images/logo.svg">
        <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white">
            Password Reset
        </h2>
        <div class="host">
            <p>{{host}}</p>
        </div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

            <alert class="mb-8" type="danger" v-if="error">
                <template v-slot:icon>
                    <base-icon name="exclamation-2" />
                </template>
                {{ error }}
            </alert>

            <FormulateForm 
                class="flex flex-col items-center" 
                name="form" 
                @submit="submit"
            >

                <FormulateInput
                    type="email"
                    label="Email address"
                    validation="required"
                    validation-name="Email"
                    class="w-full text-gray-400"
                    v-model="email"
                    readonly
                />

                <FormulateInput
                    type="password"
                    label="New Password"
                    validation="required"
                    validation-name="New Password"
                    class="w-full mt-6"
                    v-model="newPassword"
                />

                <FormulateInput
                    type="password"
                    label="Confirm Password"
                    validation="required"
                    validation-name="Confirm Password"
                    class="w-full mt-6"
                    v-model="confirmPassword"
                />

                <base-button
                    @action="$formulate.submit('form')" 
                    :disabled="loading"
                    class="mt-4 w-full"
                    submit
                    label="Submit"
                />

            </FormulateForm>

        </div>
    </div>

    <reset-password-success-modal
        :visible="successModalVisible"
        @close="()=>null"
    />
</div>
</template>

<script>
import Alert from '../../components/Alert';
import ResetPasswordSuccessModal from './ResetPasswordSuccessModal.vue';
export default {
    components: {
        Alert,
        ResetPasswordSuccessModal
    },
    data() {
        return {
       
            email: '',
            newPassword: '',
            confirmPassword: '',

            error: null,
            loading: false,

            successModalVisible: false
        }
    },

    mounted(){
        this.getPasswordResetInstance()   
    },

    computed: {
        host(){
            return window.location.host
        }
    },

    methods: {
        async getPasswordResetInstance(){
            try {
                this.loading = true

                const { data } = await this.$http.get(`/api/auth/password-reset?id=${this.$route.query.id}&token=${this.$route.query.t}`)

                if(data && data.email){
                    this.email = data.email                    
                }

            }
            catch(e) {
                console.log(e);
            } finally {
                this.loading = false
            } 
        },
        async submit(){
            try {
                this.loading = true
                this.error = null

                const { data } = await this.$http.post(`/api/auth/reset-password`, { 
                    email: this.email,
                    newPassword: this.newPassword,
                    confirmPassword: this.confirmPassword,
                    token: this.$route.query.t                    
                })

                if(data){
                    this.successModalVisible = true
                }
                
            }
            catch(e) {
                console.log(e);

                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }

                this.error = 'An unknown error occurred. Please try again.';

            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .host{
        @apply flex justify-center mt-2;
        > p{
            @apply text-sm text-gray-m;
        }
    }
</style>