<template>
<!-- 
! not using this file right now, in case we enable it in the future, make sure that the "/api/projects" is not 
fetching all projects ever 
-->
<main class="project-settings flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
    <!-- <div class="bg-tan-m flex justify-between items-center py-10 px-12">
       <div class="text-h2 font-bold text-purple-m-secondary">
           Deliverables
        </div>         
    </div> -->

    <div class="flex justify-between px-12 pt-10 pb-4">
        <!-- Filters -->          
        <div class="flex-shrink w-full">
            <div class="relative flex w-full">
                <div class="absolute w-16 h-full flex items-center justify-center pointer-events-none">
                    <base-icon name="search" class="text-purple-m-secondary" />
                </div>                    
                <FormulateInput
                    type="text"                        
                    v-model="filters[0].selected"
                    placeholder="Search"
                    outer-class="w-full"
                    element-class="w-full"
                    wrapper-class="border rounded-md py-2 px-3 pl-16 bg-white h-16 flex items-center"
                />
            </div>
        </div>
    </div> 


    <div class="px-12 pb-10 space-y-10 overflow-x-auto">
        <template v-if="loading">
            <div class="space-y-4" v-for="s in 5" :key="s">
                <div class="flex gap-4 items-center">
                    <skeleton type="icon"/>
                    <skeleton class="w-1/3" :height="8"/>
                </div>
                <table class="bg-white w-full">
                    <tbody>
                    <tr class="h-15 border-b border-gray-m-light">
                        <td class="w-56 px-6 text-left"><skeleton :full-width="true"/></td>
                        <td class="w-56 px-6 text-left"><skeleton :full-width="true"/></td>
                        <td class="w-56 px-6 text-left">
                            <div class="flex flex-row items-center space-x-3 lg:pl-2">
                                <skeleton class="w-3/4"/>
                                <skeleton class="w-1/4"/>
                            </div>
                        </td>
                        <td class="w-16 px-6 text-center"><skeleton :full-width="true"/></td>
                    </tr>
                    <tr v-for="trI in 5" :key="trI" class="h-15 border-b border-gray-m-light">
                        <td class="px-6 py-6">
                            <div class="flex flex-row gap-4 justify-items-center">
                                <skeleton type="circle" :width="5"/>
                                <skeleton/>
                            </div>
                        </td>
                        <td class="px-6 py-6"><div class="flex items-center space-x-3 lg:pl-2"><skeleton :full-width="true"/></div></td>
                        <td class="px-6 py-6"><div class="flex items-center space-x-3 lg:pl-2"><skeleton :full-width="true"/></div></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <base-table
            v-for="project in filteredComputedProjects"
            :key="project.slug+project.refresh"
            :columns="[
                {
                    name: 'name',
                    label: 'Creator',
                    type: 'avatar-list',
                    sort: false,
                    options: {
                        cellClasses: 'font-bold',
                    },
                    value: (element) => {
                        return [element.user]
                    }
                },
                {
                    name: 'cost',
                    label: 'Cost',
                    type: 'price',
                    hidden: !isAdminOrHigher,
                    bold: (element) => { return element.fixed_amount },
                    fixed_amount: (element) => { return element.fixed_amount },
                    rowHelp: (element) => {
                        if (element.fixed_amount) {
                            return 'This amount is fixed for this user and it will be splitted by the deliverables quantity.';
                        }

                        return null;
                    },
                    value: (element) => {
                        if (element.fixed_amount) {
                            return element.fixed_amount;
                        }
                        if(element.project_user_deliverables){
                            return element.project_user_deliverables.reduce((a, c) => a += c.budget, 0);
                        }
                        else{
                            return 0;
                        }
                    },
                    options: {
                        stopPropagation: true
                    },
                    onClick: (element) => setAmount(element)
                },
                {
                    name: 'deliverables',
                    label: 'Completed Deliverables',
                    type: 'deliverables',
                    columnClass: (element) => {
                        return 'flex justify-start';
                    },
                    options: {
                        stopPropagation: true
                    },
                    value: (element) => {
                        if(element.project_user_deliverables){
                            return `${element.project_user_deliverables.reduce((acc, cur) => {
                                    
                                if(cur.posts_needed != null && cur.posts_needed > 0 && cur.project_user_deliverable_posts != null) {

                                    if (cur.project_user_deliverable_posts && cur.project_user_deliverable_posts.length < cur.posts_needed) {
                                        return acc;
                                    } else {
                                        return acc + 1;
                                    }
                                }
                                return acc + 1;
                                    
                            }, 0)}/${element.project_user_deliverables.length}`;
                            
                        } else{
                            return '0/0';
                        }
                    }
                },
                creatorColumns(project).find(col => col.creator_column_id === 6) ? {
                    name: 'user_tags',
                    label: 'Tags',
                    type: 'user-tags',
                    value: (element) => {
                        return element.user.user_tags
                    },
                    searchBy: customUserTagsSearchBy,
                    filter: {
                        options: tags.map(tag => {return { label: tag.name, value: tag.slug }}),
                        placeholder: 'Tags',
                        type: 'select',
                        allSelectedPlaceholder: 'All Tags'
                    },
                } : {},
                creatorColumns(project).find(col => col.creator_column_id === 5) ? {
                    name: 'email',
                    label: 'Email',
                    type: 'text',
                    value: (element) => {
                        return element.user.email ? element.user.email : '-'
                    }
                } : {},
                creatorColumns(project).find(col => col.creator_column_id === 4) ? {
                    name: 'phone',
                    label: 'Phone',
                    type: 'text',
                    value: (element) => {
                        return element.user.phone ? element.user.phone : '-'
                    }
                } : {},
                creatorColumns(project).find(col => col.creator_column_id === 7) ? {
                    name: 'links',
                    label: 'Links',
                    type: 'networks',
                    value: (element) => {
                        return element.user.social_accounts
                    },
                    currentAccounts: (element) => {
                        return element.user.social_accounts.map(network => network.network.id);
                    },
                    options: {
                        stopPropagation: true
                    }
                } : {},
                creatorColumns(project).find(col => col.creator_column_id === 8) ? {
                    name: 'intagram-followers',
                    label: 'Instagram Followers',
                    type: 'number',
                    value: (element) => {
                        return instagramFollowersValue(element.user)
                    }
                } : {},
                creatorColumns(project).find(col => col.creator_column_id === 9) ? {
                    name: 'tiktok-followers',
                    label: 'TikTok Followers',
                    type: 'number',
                    value: (element) => {
                        return tiktokFollowersValue(element.user)
                    }
                } : {},
                creatorColumns(project).find(col => col.creator_column_id === 1) ? {
                    name: 'city',
                    label: 'City',
                    type: 'text',
                    value: (element) => {
                        return element.user.audience_data && element.user.audience_data[0] && element.user.audience_data[0].location && element.user.audience_data[0].location.city ? element.user.audience_data[0].location.city.name : '-';
                    }
                } : {},
                creatorColumns(project).find(col => col.creator_column_id === 2) ? {
                    name: 'state',
                    label: 'State',
                    type: 'text',
                    value: (element) => {
                        return element.user.audience_data && element.user.audience_data[0] && element.user.audience_data[0].location && element.user.audience_data[0].location.state ? element.user.audience_data[0].location.state.name : '-';
                    }
                } : {},
                creatorColumns(project).find(col => col.creator_column_id === 3) ? {
                    name: 'country',
                    label: 'Country',
                    type: 'text',
                    value: (element) => {
                        return element.user.audience_data && element.user.audience_data[0] && element.user.audience_data[0].location && element.user.audience_data[0].location.country ? element.user.audience_data[0].location.country.name : '-';
                    }
                } : {}
            ].filter(
                obj => !(obj && Object.keys(obj).length === 0 && obj.constructor === Object)
            )"
            :onRowClick="(element) => openCreator(element, project)"
            :loading="loading"
            :search="false"
            :filtering="false"
            :addColumnEnabled="true"
            :project="project"
            :data="project.project_users"
            show-project
            overflow
            @add-deliverable="openAddDeliverable"
            @user-deliverable-option="userDeliverableOption"
            @individual-network="unlinkPost"
            @add-column="openAddColumn"
            @move-column="moveColumn"
            @delete-column="deleteColumn"
            @edit-column="openEditColumn"
            @request-post="requestPost"
            @collapse-toggle="collapseToggle"
            @collapse-all="collapseProjects"
            @toggle-project-user-column-value-edit="enableEditUserValueField"
            @edit-user-column-value="editUserColumnValue"
        />

    </div>

    <!-- modals -->
    <deliverables-table-column-modal
        :visible="showColumnModal"
        :project="selectedProject"
        :column="selectedColumn"
        :dataTypes="columnDataTypes"
        @close="showColumnModal = false"
        @save-new-column="addColumn"
        @edit-column="editColumn"
    />

    <add-influencer-deliverable-modal
        :visible="showAddInfluencerDeliverableModal"
        :key="`modal_key_${modalKey}`"
        @close="showAddInfluencerDeliverableModal = false; selectedProjectUser = null; selectedUserDeliverable = null;"
        @user-deliverable-created="updateUserDeliverable"
        :projectId="selectedProject"
        :fixed-amount="fixedAmount"
        :projectUserId="selectedProjectUser"
        :networks="networks"
        :currentUserDeliverable="currentUserDeliverable"
    />

    <base-modal
        :visible="selectedProjectUser !== null && !showAddInfluencerDeliverableModal"
        @close="selectedProjectUser = null"
        title="Single Amount"
    >
        <div class="flex flex-col gap-4">
            <div>If you change this creator to a single amount for all deliverables you won’t be able to add  amounts to each deliverable added to this creator.</div>
            <div class="field">
                <div class="text-h6 font-bold">Enter  amount for all deliverables</div>
                <FormulateInput
                    type="text"
                    placeholder="0.00"
                    @input="onFixedAmountInput"
                    :options="{ prefix: true }"
                    v-model="fixed_amount"
                />
            </div>
            <div class="flex flex-row-reverse">
                <base-button label="Save" @action="saveFixedAmount"/>
            </div>
        </div>
    </base-modal>

    <create-version-modal
        v-if="currentUserDeliverable && isUserAdminOrHigher && currentUserDeliverable.token"
        :visible="currentUserDeliverable !== null && showCreateVersionModal"
        @close="addManualConcept = false; currentUserDeliverable = null;"
        @done="manualConceptCompleted"
        :isManual="true"
        :projectName="currentUserDeliverable.project.name"
        :deliverable="{...currentUserDeliverable, project_user: currentUserDeliverable.project.project_users.filter(e => e.id === currentUserDeliverable.project_user_id)[0]}"

    />

    <link-post-user-deliverable-modal
        :key="`link-post-${deliverableModalKey}`"
        :visible="currentUserDeliverable != null && showLinkPostUserDeliverableModal"
        @close="currentUserDeliverable = null"
        @posts-linked="closeLinkedPost"
        :currentUserDeliverable="currentUserDeliverable"
    />

</main>
</template>

<script>
import DeliverablesTable from '../../components/DeliverablesTable.vue';
import DeliverablesTableColumnModal from '../../components/DeliverablesTableColumnModal.vue';
import AddInfluencerDeliverableModal from "../../components/AddInfluencerDeliverableModal";
import CreateVersionModal from '../VersionCreate/CreateVersionModal';
import { formatBudget, budgetToDouble } from '../../lib/strings'
import { mapGetters } from 'vuex';
import LinkPostUserDeliverableModal from '@/components/LinkPostUserDeliverableModal';

export default {
    components: {
        DeliverablesTable,
        DeliverablesTableColumnModal,
        AddInfluencerDeliverableModal,
        LinkPostUserDeliverableModal,
        CreateVersionModal
    },
    data() {
        return {
            loading: false,  
            link_post_visible: false,
            showColumnModal: false,         
            projects: [],   
            selectedProject: null,
            modalKey: 0,

            selectedColumn: null,    
            columnDataTypes: [],  
            showAddInfluencerDeliverableModal: false,
            fixedAmount: false,
            networks: [],
            selectedUserDeliverable: null,
            userDeliverable: {},
            fixedProject: null,
            activeSubmissionType: 0,
            savingUserColumn: false,
            savingUserDeliverableColumn: false,

            filters: [
                {
                    name: 'search',
                    type: 'string',
                    selected: ''
                },
            ],
            fixed_amount: 0,
            selectedProjectUser: null,
            deliverableModalKey: false,
            currentUserDeliverable: null,
            addManualConcept: false,
            manualConceptKey: false,
        }
    },
    mounted(){
        this.getData();
    },
    destroyed() {
    },
    computed: {
        ...mapGetters(['isAdminOrHigher', 'isUserAdminOrHigher', 'isGuest', 'isClient', 'fbLogin']),
        filteredComputedProjects() {
            return this.filteredProjects.map(project => {
                project.project_users = project.project_users.filter(u => u.user).map(u => {
                    let { avatar, first_name, last_name, email, id } = u.user;
                    let item = u;
                    item.user = { avatar, first_name, last_name, email, id };
                    return item;
                });

                return project;
            });
        },

        filteredProjects(){     
            let validProjects = [];
            let valid = false;            
            // filter options must have value & label
            this.projects.forEach(project => {
                valid = false;
                this.filters.forEach(filter => {
                    if(filter.selected){
                        let searchTerm = filter.selected.toLowerCase();
                        switch(filter.type){
                            case 'string':
                                if(project.name.toLowerCase().includes(searchTerm) || project.brand.toLowerCase().includes(searchTerm))
                                    valid = true;
                                else if(project.columns.some(column => {return column.name.toLowerCase().includes(searchTerm)}))
                                    valid = true;
                                else if(project.users.some(user => {return user.first_name.concat(" ", user.last_name).toLowerCase().includes(searchTerm)}))
                                    valid = true;
                                else if(project.users.some(user => {return this.checkUserDeliverables(user, searchTerm)}))
                                    valid = true;
                                break;
                            default: break;
                        }
                    }
                    else
                        valid = true;
                });
                if(valid)
                    validProjects.push(project);
            });

            return validProjects;
        },
    },
    methods: {

        fieldMatch(column, userColumns, verbose = false) {

            for (const [key, user_column] of userColumns.entries()) {
                if ( column.id == user_column.project_user_column_name_id ) {

                    if ( verbose == true ) {
                        return {user_column: user_column, columnKey: key, column_type_id: column.column_type_id, id: column.id};
                    } else {
                        return user_column.data;
                    }   
                }
            }
        },

        async editUserDeliverableColumn(columnIndex, userIndex, project_user, isDeliverableColumn, columnValue) {  
            this.savingUserDeliverableColumn = false;

            const projectsObject = this.keyedObject(this.projects);
            const the_project = projectsObject[project_user.project_id];
            const projectsUsersObj = this.keyedObject(the_project.project_users);
            const the_project_user = projectsUsersObj[project_user.id];
                
            const deliverableColumn = the_project_user.project_user_deliverables[columnIndex].project_user_deliverable_column_names[columnIndex];
            const the_columns = deliverableColumn.project_user_deliverable_column_values;

            this.savingUserColumn = true;
            // create

            if (!the_columns[userIndex].id) {
                
                const the_post_data = {
                    project_user_id: the_project_user.id,
                    id: the_columns[userIndex].project_user_column_value.id,
                    data: the_columns[userIndex].data
                };

                const {data} = await this.$http.post('/api/userDeliverables/projectUserDeliverableColumnValue', the_post_data);
                if (data) {
                    the_columns[userIndex] = data;
                    this.$notify({title: 'Data saved successfully', text: '', type: 'success'});
                } else {
                    this.$notify({title: 'Unable to save data, try again later', text: '', type: 'error'});
                }
            }
            // update
            else {
                const {data} = await this.$http.put(`/api/userDeliverables/projectUserDeliverableColumnValue/${the_columns[userIndex].id}`, {
                    data: the_columns[userIndex].data
                });
                if (data) {
                    this.$notify({title: 'Data saved successfully', text: '', type: 'success'});
                } else {
                    this.$notify({title: 'Unable to save data, try again later', text: '', type: 'error'});
                }
            }

            this.savingUserDeliverableColumn = false;

            the_project.project_users.forEach((pu) => {
              pu.project_user_deliverables.forEach((deliverable) => {
                deliverable.project_user_deliverable_column_names.forEach((column_name, index) => {
                    column_name.project_user_deliverable_column_values.forEach((column_value, index2) => {
                        column_value.edit = false;
                    });
                  
                });
              });
            });
        },

        async editUserColumnValue(columnValue, project, isDeliverableColumn = false) {

            this.savingUserColumn = false;

            if (!this.savingUserColumn) {

                this.savingUserColumn = true;

                let the_post_data = isDeliverableColumn ? {
                    project_user_deliverable_id: columnValue.project_user_deliverable_id,
                    project_user_deliverable_column_name_id: columnValue.id,
                    data: columnValue.data
                } : {
                    project_user_id: columnValue.project_user_id,
                    project_user_column_name_id: columnValue.project_user_column_name_id,
                    data: columnValue.data
                };

                const the_id = (isDeliverableColumn) ? columnValue?.id ?? '' : columnValue?.id ?? '';
                let apiRoute = isDeliverableColumn ? 'projectUserDeliverableColumnValue' : 'projectUserColumnValue';
                apiRoute += `/${the_id}`;

                // create
                if (!the_id ) {
                    const { data } = await this.$http.post(`/api/userDeliverables/${apiRoute}`, the_post_data);

                    if (data) {
                        columnValue = data;
                        this.$notify({ title: 'Data saved successfully', text: '', type: 'success' });
                    } else {
                        this.$notify({ title: 'Unable to save data, try again later', text: '', type: 'error' });
                    }
                }

                // update
                else {
                    const { data } = await this.$http.put(`/api/userDeliverables/${apiRoute}`, the_post_data);
                    if (data) {
                        columnValue = data;
                        this.$notify({ title: 'Data saved successfully', text: '', type: 'success' });
                    } else {
                        this.$notify({ title: 'Unable to save data, try again later', text: '', type: 'error' });
                    }
                }

                this.savingUserColumn = false;
            }

            project.project_users.forEach((pu) => {
                if (isDeliverableColumn) {
                    pu.project_user_deliverables.forEach((deliverable) => {
                        deliverable.project_user_deliverable_column_names.forEach((columnName, index) => {
                            this.$set(columnValue, 'edit', false);
                        });
                    });
                } else {
                    project.project_users.forEach((pu) => {
                        pu.project_user_column_values.forEach((columnValue) => {
                            this.$set(columnValue, 'edit', false);
                        });
                    });

                }
            });
        },

        unlinkPost(userDeliverable, account) {
            let project_user_deliverable_post = userDeliverable.project_user_deliverable_posts.filter(p => p.post).find(p => p.post.url === account.social_url);
            if (project_user_deliverable_post) {
                this.$swal.fire({
                    title: 'Unlink content',
                    text: 'You are about to unlink a content. Do you want to continue?',
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Unlink content',
                    reverseButtons: true,
                }).then(async (result) => {
                    if(result.isConfirmed){
                        let {project_user_deliverable_id, post_id} = project_user_deliverable_post;
                        await this.$http.delete(`/api/userDeliverables/${project_user_deliverable_id}/posts/${post_id}`).then(() => {
                            this.$notify({ title: 'Success', text: 'Content unlinked successfully.', type: 'success' });
                        }).catch(() => {
                            this.$notify({ title: 'Error', text: 'The content could not be unlinked. Try again later', type: 'error' });
                        }).finally(() => {
                            this.closeLinkedPost();
                        });
                    }
                });
            }
        },

        submissionModal(userDeliverable, project) {
            this.showLinkPostUserDeliverableModal = false;
            this.showCreateVersionModal = false;
            this.currentUserDeliverable = { ...userDeliverable, project };
            switch ( userDeliverable.phases_progress.status ) {
                case 'draft_approval':
                    this.showCreateVersionModal = true;
                break;
                case 'concept_approval':
                    this.showCreateVersionModal = true;
                break;
                case 'link_post':
                    this.showLinkPostUserDeliverableModal = true;
                break;
            case 'not_started':
                    this.showCreateVersionModal = true;
                break;
                
            }
        },

        keyedObject(projects) {
          return projects.reduce((acc, project) => {
            acc[project.id] = project;
            return acc;
          }, {});
        },

        projectUsersToObject(projectUsers) {
          return projectUsers.reduce((acc, projectUser) => {
            acc[projectUser.id] = projectUser;
            return acc;
          }, {});
        },

        updateProject(projects, updatedProject) {
            const index = projects.findIndex(project => project.id === updatedProject.id);

            if (index !== -1) {
                projects[index] = updatedProject;
            } else {
                console.error('Project not found:', updatedProject.id);
            }
        },

        enableEditUserValueField(columnValueObj, project, isDeliverableColumn = false) {
            if (isDeliverableColumn) {
                project.project_users.forEach((pu) => {
                    pu.project_user_deliverables.forEach((deliverable) => {
                        deliverable.project_user_deliverable_column_names.forEach((columnName) => {
                            this.$set(columnName.project_user_deliverable_column_value, 'edit', false);
                        });
                    });
                });
            } else {
                project.project_users.forEach((pu) => {
                    pu.project_user_column_values.forEach((columnValue) => {
                        this.$set(columnValue, 'edit', false);
                    });
                });
            }

            const valueField = isDeliverableColumn ? 'project_user_deliverable_column_value' : 'project_user_column_value';
            this.$set(columnValueObj, 'edit', true);
        },


        manualConceptCompleted() {
            this.addManualConcept = false;
            this.manualConceptKey = !this.manualConceptKey;
            this.currentUserDeliverable = null;
            this.activeSubmissionType = '';
            this.getData(false);
        },
        inViewPort(item) {
            let rect = item.getBoundingClientRect();
            let visible = rect.top < (window.pageYOffset + window.innerHeight) && rect.bottom > window.pageYOffset;

            return {visible, rect};
        },
        onFixedAmountInput(val) {
            this.fixed_amount = formatBudget(val);
        },
        setAmount(element) {
            this.selectedProjectUser = element.id;
            this.fixed_amount = element.fixed_amount ? formatBudget(element.fixed_amount) : 0;
        },
        async saveFixedAmount() {
            if (this.selectedProjectUser !== null) {
                let payload = {
                    project_user: this.selectedProjectUser,
                    amount: budgetToDouble(this.fixed_amount)
                };

                await this.$http.post(`/api/userDeliverables/setFixedAmount`, payload);
                this.selectedProjectUser = null;
                this.fixed_amount = 0;
                this.$notify({ title: 'Amount saved', text: 'The amount was saved successfully', type: 'success' });
                this.getData(false)
            }
        },
        openCreator(element, project) {
            this.$router.push(`/creator/${element.user.id}`)
        },
        creatorColumns(project) {

            /// I can always add these back if I ever want to show the creator columns.
            /// Will need a rewrite though in the base table columns and I want to eliminate complexity for now
            //return project.columns;
            return [];
        },

        async getData(showLoader = true) {

            this.loading = showLoader;

            try {

                // get column types
                const columnTypes = await this.$http.get('/api/userDeliverables/columnTypes');
                this.columnDataTypes = columnTypes.data.map(type => {
                    type.value = type.id
                    return type;        
                });
                // get networks
                this.getNetworks();
                
                // get projects
                // [get-all-projects]
                const { data } = await this.$http.get('/api/projects?only=deliverables&with=columns');
                
                // remove deleted/archived projects     
                const filteredData = data.filter(element => {
                    return element.deleted_at === null || element.deleted_at === undefined;
                });

                this.projects = await Promise.all(filteredData.map(async (project) => {
                    return this.loadProject(project);
                }));
                
            } catch (error) {
                if ( error.response.status >= 400 ) {
                    
                    // Handle the 400+ error

                    this.$notify({ title: 'Error', text: error.response.data.message, type: 'error' });
                    this.$router.push("/home");

                } else {
                    // Handle other errors
                    console.error(error);
                }
            }

            this.loading = false;
        },

        mapUpdatedValues(primaryObject, parentObject, isDeliverableColumn) {

            const valName = (isDeliverableColumn) ? 'project_user_deliverable_value' : 'project_user_column_value';
            const colName = (isDeliverableColumn) ? 'project_user_deliverable_column_name' : 'project_user_column_name';
            const columns = parentObject[`${colName}s`];
            let newValArr = [];
            
            if ( columns.length > 0 ) {
            
                for (var key in columns) {
                    
                    var column = columns[key];

                    let associated_value = {};
                    let existingVal = primaryObject[`${valName}s`].find(val => val[`${colName}_id`] === column.id);

                    if (existingVal) {
                        associated_value = existingVal;
                    } else {

                        associated_value = {
                            [isDeliverableColumn ? 'project_user_deliverable_column_name_id' : 'project_user_column_name_id']: column.id,
                            [isDeliverableColumn ? 'project_user_deliverable_id' : 'project_user_id']: primaryObject.id,
                            data: "",
                            edit: false
                        };
                    }

                    newValArr.push(associated_value);

                }
                return newValArr;
            } else {
                return [];
            }
        },

        async loadProject(project, refresh = true) {
            
            // set a refresh aux attribute.
            project.refresh = false;
            // set a collapsable aux attribute.
            project.collapsed = localStorage.getItem(`project_${project.id}_collapsed`) === "true";
            // set a loader aux attribute
            project.loadingColumns = false;

            project.project_users = project.project_users.map((project_user) => {

                // console.log('project_user.project_user_column_values', project_user.project_user_column_values);
                const updatedValues = this.mapUpdatedValues(project_user, project, false);

                // console.log('project_user.project_user_column_values', project_user.project_user_column_values);
                // console.log('updatedValues', updatedValues);
                
                // this.$set(project_user, 'project_user_column_values', updatedValues);
                project_user.project_user_column_values = updatedValues;

                // console.log('project_user.project_user_column_values', project_user.project_user_column_values);

                
                // return project_user;

                project_user.project_user_deliverables.forEach((deliverable) => {

                    // const updatedNames = this.mapUpdatedNames(deliverable.project_user_deliverable_column_names, deliverable, false);
                    // deliverable.project_user_deliverable_column_names = updatedNames;

                    // deliverable.project_user_deliverable_column_names.forEach((column_name) => {


                    //     // Need to add this back later
                    //     // deliverableColumns.sort((a, b) => {
                    //     //     const columnA = deliverable.project_user_deliverable_column_names.find((column) => column.id === a.project_user_deliverable_column_name_id);
                    //     //     const columnB = deliverable.project_user_deliverable_column_names.find((column) => column.id === b.project_user_deliverable_column_name_id);
                    //     //     return columnA.position - columnB.position;
                    //     // });

                    // });
                });

                console.log('project_user', project_user);

                return { ...project_user, showUserDeliverables: false };
            });

            project.project_user_column_names.sort((a, b) => {
                const columnA = project.project_user_column_names.find((column_name) => column_name.id === a.id);
                const columnB = project.project_user_column_names.find((column_name) => column_name.id === b.id);
                return columnA.position - columnB.position;
            });


            return project;

        },
        
        async getUserDeliverables(userId) {
            const { data } = await this.$http.get(`/api/userDeliverables/${userId}`);
            return data;
        },
        async getNetworks() {
            const { data } = await this.$http.get('/api/userDeliverables/networks');
            this.networks = data.map(network => {
                network.value = network.id;
                network.label = network.display_name;
                return network;
            });
        },

        openAddColumn(project) {
            this.selectedProject = project;
            this.selectedColumn = null;
            this.showColumnModal = true;
        },        
        openEditColumn(columnName, project) {
            this.selectedProject = project;
            this.selectedColumn = columnName;
            this.showColumnModal = true;
        },

        async moveColumn(columnName, direction, columnIndex) {
            
            let columns = this.projects.find(project => project.id === columnName.project_id).project_user_column_names;

            let swap = {};
            if ( direction == 'right' && columnIndex+1 < columns.length ) {
                swap.firstColumnId = columns[columnIndex].id;
                swap.firstColumnPosition = columns[columnIndex].position;
                swap.secondColumnId = columns[columnIndex+1].id;
                swap.secondColumnPosition = columns[columnIndex+1].position;
            }
            else if ( columnIndex > 0 ) {
                swap.firstColumnId = columns[columnIndex].id;
                swap.firstColumnPosition = columns[columnIndex].position;
                swap.secondColumnId = columns[columnIndex-1].id;
                swap.secondColumnPosition = columns[columnIndex-1].position;
            }

            if(Object.keys(swap).length){

                await this.$http.put(`/api/userDeliverables/projectColumn/${swap.firstColumnId}`, {position: swap.secondColumnPosition});
                await this.$http.put(`/api/userDeliverables/projectColumn/${swap.secondColumnId}`, {position: swap.firstColumnPosition});
                await this.refreshProject(columnName.project_id);
            }
        },

        async addColumn(newColumn, project) {

            const columnNames = project.project_user_column_names;
            newColumn.position = columnNames.length ? columnNames[(columnNames.length - 1)].position + 1 : 1;

            await this.$http.post('/api/userDeliverables/projectColumn', {...newColumn});
            await this.refreshProject(project.id);
        },

        async editColumn(columnNameObj) {
            await this.$http.put(`/api/userDeliverables/projectColumn/${columnNameObj.id}`, {name: columnNameObj.name});
            await this.refreshProject(columnNameObj.project_id);
        },

        async refreshProject(projectId) {
            // [get-all-projects]
            const { data } = await this.$http.get(`/api/projects?projectId=${projectId}&only=deliverables&with=columns`);

            if (data.length) {
                const projectIndex = this.projects.findIndex(project => project.id === projectId);

                if (projectIndex !== -1) {

                    this.projects[projectIndex].refresh = !this.projects[projectIndex].refresh;
                    const updatedProject = await this.loadProject(data[0], true);

                    // Update the project with the new data
                    this.$set(this.projects, projectIndex, updatedProject);
                }
            }

            this.selectedProject = null;
            this.selectedColumn = null;
        },

        async deleteColumn(columnName, project) {
            this.$swal.fire({
                title: `Are you sure you want to delete the column?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { data } = await this.$http.delete(`/api/userDeliverables/projectColumn/${columnName.id}`);

                    if (data) {
                        this.$store.dispatch('restorer/set', {
                            resourceLabel: 'Column',
                            route: `/api/userDeliverables/projectColumn/${columnName.id}/restore`,
                            action: data.show_undo,
                            fn: () => {
                                this.refreshProject(project.id);
                            }
                        });

                        // Remove columnName from the nested array
                        project.project_users.forEach(user => {
                            const columnIndex = user.project_user_column_names.findIndex(column => column.id === columnName.id);
                            if (columnIndex !== -1) {
                                user.project_user_column_names.splice(columnIndex, 1);
                            }
                        });


                        // NEED TO ADD POSITIONING HELPER FUNCTION HERE!!!!!!!!!!!

                    }
                }
            });
        },

        openAddDeliverable(projectId, projectUserId, fixed){
            this.selectedProject = projectId;
            this.selectedProjectUser = projectUserId;
            this.selectedUserDeliverable = null;
            this.selectedColumn = null;
            this.fixedAmount = fixed;
            this.modalKey++;
            this.showAddInfluencerDeliverableModal = true;
        },
        
        openEditDeliverable(projectId, projectUserId, userDeliverable, fixed){
            this.selectedProject = projectId;
            this.selectedProjectUser = projectUserId;
            this.selectedUserDeliverable = userDeliverable;
            this.fixedAmount = fixed;

            if (userDeliverable.deliverables_type) {
                this.selectedUserDeliverable.type = userDeliverable.deliverables_type;
                this.selectedUserDeliverable.type.id = userDeliverable.deliverables_type.slug;
            }
            this.selectedColumn = null;
            this.showAddInfluencerDeliverableModal = true;
        },
        cleanFilters() {
            for (let i in this.filters) {
                this.filters[i].selected = this.filters[i].type === 'string' ? '' : null;
            }
        },
        checkUserDeliverables(user, filter){
            return user.project_user.userDeliverables.some(deliverable => {return (deliverable.name && deliverable.name.toLowerCase().includes(filter))})
        },
        async updateUserDeliverable({ id }) {
            this.getData();
            let project = this.projects.find(c => c.id === this.selectedProject);
            let user = project.users.find(u => u.project_user.id === this.selectedProjectUser);
            await this.updateUserDeliverables(project, user);
        },
        async updateUserDeliverables(project, user) {
            await new Promise(resolve => setTimeout(resolve, 500));

            // get updated deliverables.
            user.project_user.userDeliverables = await this.getUserDeliverables(user.project_user.id);
            // update table
            project.refresh = !project.refresh;
        },
        async userDeliverableOption(deliverable, option, fixed = false){
            let project = this.projects.find(c => c.users.find(u => u.project_user.id === deliverable.project_user.id));
            let user = project.users.find(u => u.project_user.id === deliverable.project_user.id);
            switch(option){
                case 'edit':
                    this.modalKey++;
                    this.openEditDeliverable(project.id, user.project_user.id, deliverable, fixed);
                    break;
                case 'duplicate':
                    await this.$http.post(`/api/userDeliverables/duplicate/${deliverable.id}`);
                    await this.updateUserDeliverables(project, user);
                    break;
                case 'delete':
                    this.$swal.fire({
                        title: `Are you sure you want to delete this deliverable?`,
                        text: '',
                        icon: 'warning',
                        iconColor: '#0E092C',
                        showCancelButton: true,
                        confirmButtonText: 'Delete',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.processDeleteDeliverable(deliverable, project, user);
                        }
                    });
                    break;
                default:
                    break;
            }
        },

        async processDeleteDeliverable(deliverable, project, user) {
            const { data } = await this.$http.post(`/api/userDeliverables/delete/${deliverable.id}`);
            if (data) {
                this.$store.dispatch('restorer/set', {
                    message: 'Deliverable deleted successfully',
                    success: 'Deliverable restored successfully',
                    fail: 'Deliverable cannot be restored',
                    route: `/api/userDeliverables/restore/${deliverable.id}`,
                    action: data.show_undo,
                    forceFn: true,
                    fn: async () => {
                        await this.updateUserDeliverables(project, user);
                    }
                });
            }
        },

        closeLinkedPost() {
            this.link_post_visible = null;
            this.currentUserDeliverable = null;
            this.deliverableModalKey = !this.deliverableModalKey;
            this.getData(false);
        },

        requestFinalPost(userDeliverable) {
            if ( userDeliverable.project_user_deliverable_posts.length < userDeliverable.posts_needed ) {

                if ( userDeliverable.phases_progress.code < 30 || userDeliverable.phases_progress.code >= 20 ) {

                    this.currentUserDeliverable = userDeliverable;
                    this.addManualConcept = true;

                } else {

                    if(userDeliverable.network_id == 2 ) {
                        if ( this.fbLogin.error ) {
                            this.$store.dispatch('restorer/set', {
                                notification: {
                                    title: this.fbLogin.message,
                                    text: this.fbLogin.computedMessage('link an instagram post'),
                                    type: 'warn'
                                },
                                fail: 'Unabled to get account',
                                action: true,
                                forceFn: false,
                                data: { undoLabel: 'Connect' },
                                fn: () => {
                                    this.$store.dispatch('fb/simpleLogin', this.$notify);
                                }
                            });
                        } else {
                            this.currentUserDeliverable = userDeliverable;
                        }
                    }
                }
            }
        },

        requestPost(userDeliverable, project) {

            if ( userDeliverable.project_user_deliverable_posts.length < userDeliverable.posts_needed ) {

                if ( userDeliverable.phases_progress.code <= 30 ) {

                    this.submissionModal(userDeliverable, project);

                } else {

                    if(userDeliverable.network_id == 2 ) {
                        if ( this.fbLogin.error ) {
                            this.$store.dispatch('restorer/set', {
                                notification: {
                                    title: this.fbLogin.message,
                                    text: this.fbLogin.computedMessage('link an instagram post'),
                                    type: 'warn'
                                },
                                fail: 'Unabled to get account',
                                action: true,
                                forceFn: false,
                                data: { undoLabel: 'Connect' },
                                fn: () => {
                                    this.$store.dispatch('fb/simpleLogin', this.$notify);
                                }
                            });
                        } else {
                            this.currentUserDeliverable = userDeliverable;
                        }
                    }
                }
            }
        },

        collapseToggle(project){
            project.collapsed = !project.collapsed
            localStorage.setItem(`project_${project.id}_collapsed`, project.collapsed);
        },

        collapseProjects() {
            this.projects.forEach(p => {
                p.collapsed = true;
                localStorage.setItem(`project_${p.id}_collapsed`, true);
            });
        }
    }
}
</script>