<template>
    <!-- [unused-component] -->
    <div class="space-y-4">
        <div class="project-title flex gap-4 items-center" v-if="project.name !== undefined && !isUnique" :class="{'fixed top-0 bg-white z-50 w-full': isFixed}">
            <!--<options-dropdown
                :right="true"
                :options="project.collapsed !== null && !isUnique? [
                    {label: !project.collapsed ? 'Collapse' : 'Expand', icon: !project.collapsed ? 'chevron-double-up' : 'chevron-double-down', event: 'collapse'},                    
                    {label: 'Collapse All', icon: 'chevron-double-up', event: 'collapse-all'},
                    {label: 'Share Deliverables', icon: 'link', event: 'share-deliverables'}]
                    :                     
                    [{label: 'Share deliverables', icon: 'link', event: 'share-deliverables'}]"
                @collapse="$emit('collapse-toggle', project)"
                @collapse-all="$emit('collapse-all')"
            />-->
            <base-icon @action="$emit('collapse-toggle', project)" :name="!project.collapsed ? 'chevron-up' : 'chevron-down'" class="cursor-pointer"/>
            <base-button type="label" justify="start"
                @action="$router.push(`/projects/${project.slug}`)">
                <div class="text-h2 font-bold text-purple-m-secondary">{{`${ project.name } - ${ project.brand } `}}</div>
            </base-button>
            <base-icon name="loader" v-if="project.loadingColumns" />
        </div>
        <table class="bg-white w-full table-fixed" v-if="project.name !== undefined && !(!!project.collapsed)">
            <thead class="border-b border-gray-m-light">
                <tr class="project-columns text-purple-m-main-light text-h6 h-16 font-bold" :class="{'pt-6 fixed top-10 bg-white z-50 w-full': isFixed}">
                    <th class="w-56 px-6 text-left">Creator</th>
                    <th class="w-56 px-6 text-left">Cost</th>
                    <th class="w-56 px-6 text-left">Deliverables</th>
                    <th class="w-56 px-6 text-left" v-for="(column, i) in project.columns" :key="i">
                        <div class="inline-flex gap-2 items-center justify-start w-full">
                            {{column.name}}
                            <options-dropdown
                                v-if="!disabled && !isGuest && !isClient"
                                theme="cancel"               
                                :options="[
                                    ...(!(i == project.columns.length-1) ? [{label: 'Move right', icon: 'chevron-double-right', event: 'right'}] : []),
                                    ...(!(i == 0) ? [{label: 'Move left', icon: 'chevron-double-left', event: 'left'}] : []),
                                    {label: 'Edit', icon: 'pencil-alt', event: 'edit', separator: true},
                                    {label: 'Delete', icon: 'trash', event: 'delete', theme: 'cancel'}
                                ]"
                                @right="$emit('move-column', project.id, i, true)"
                                @left="$emit('move-column', project.id, i, false)"
                                @delete="$emit('delete-column', project.id, column.id)"
                                @edit="$emit('edit-column', project.id, column)"
                            />
                        </div>
                    </th>
                    <th class="w-16 px-6 text-center">
                        <base-button 
                            @action="$emit('add-column', project.id)" 
                            type="label" 
                            icon="plus" 
                            v-if="!disabled && !isGuest && !isClient"
                        />
                    </th>
                </tr>
            </thead>
            <!--<thead v-else>-->
                <!--<tr>-->
                    <!--<th class="px-6"></th>-->
                    <!--<th class="px-6"></th>-->
                    <!--<th class="px-6" v-for="(column, i) in project.columns" :key="i"></th>-->
                    <!--<th class="px-6"></th>-->
                <!--</tr>-->
            <!--</thead>-->
            <template v-if="project.users.length > 0">
                <tbody v-for="(user, i) in project.users" :key="i + project.refresh" :id="`user-${user.id}`">
                    <tr class="text-purple-m-secondary text-h6 border-b border-gray-m-light h-20">
                        <td class="px-6">
                            <base-button 
                                type="label" 
                                class="w-full" 
                                justify="start"
                                @action="!disabled ? $router.push(`/projects/${project.slug}/creator/${user.project_user.user_id}`) : null"
                            >
                                <avatar-list                                
                                    :users="[user]"                                
                                />
                            </base-button>
                        </td>
                        <td class="px-6">
                            <div class="cursor-pointer flex gap-1 items-center" @click="setFixedAmount(user.project_user)">
                                <span class="font-bold" v-if="!!user.project_user.fixed_amount">{{ user.project_user.fixed_amount | numeral('$0,0') }}</span>
                                <span v-else>{{ userDeliverables(user.project_user).reduce((a, c) => a += c.budget, 0) | numeral('$0,0') }}</span>
                                <base-icon
                                    class="text-gray-600"
                                    v-tooltip="{ content: `This amount is fixed for this user and it will be splitted by the deliverables quantity.`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                    name="info-circle"
                                    v-if="!!user.project_user.fixed_amount"
                                />
                            </div>
                        </td>
                        <td class="px-6">
                            <div class="inline-flex gap-4">
                                <base-button @action="() => { user.project_user.showUserDeliverables = !user.project_user.showUserDeliverables; $forceUpdate() }" type="label" :icon="!!user.project_user.showUserDeliverables ? 'chevron-up' : 'chevron-down'" />
                                <base-icon name="deliverable-check" />
                                {{ user.project_user.userDeliverables ? user.project_user.userDeliverables.length : 0 }}
                            </div>
                        </td>                        
                        <td class="h-full" v-for="(column, j) in project.columns" :key="j">
                            <template v-if="user.project_user.columns && user.project_user.columns.length && columns && columns[i]">
                                <div v-if="columns[i][j].edit">
                                    <FormulateInput
                                        type="text"
                                        outer-class="w-full"
                                        element-class="w-full"
                                        wrapper-class="border rounded-md py-2 px-3 bg-white h-16 flex items-center"
                                        input-class="w-full focus:outline-none text-purple-m-secondary resize-none bg-transparent h-full"
                                        placeholder="empty"
                                        v-model="columns[i][j].data"
                                        @keyup.enter="$event.target.blur()"
                                        @blur="editUserColumn(i, j)"
                                    />
                                </div>
                                <base-button v-else @action="!disabled ? enableEditUserColumn(i, j) : null" type="label" justify="start" rounded="lg"
                                    class="w-full text-left h-full px-6 hover:bg-tan-m">
                                    {{ columns[i][j].data ? columns[i][j].data : "-" }}
                                </base-button>
                            </template>
                        </td>
                        <td class="w-12">
                            <!-- <base-button @action="() => { user.project_user.showUserDeliverables = !user.project_user.showUserDeliverables; $forceUpdate() }" type="label" icon="chevron-down" /> -->
                        </td>
                    </tr>
                    <tr><td>{{  }}</td></tr>
                    <tr v-if="!!user.project_user.showUserDeliverables" class="border-l-4 border-purple-m-main">
                        <td :colspan="3+project.columns.length">
                            <table class="w-full">
                                <thead class="border-b border-gray-m-light">
                                    <tr class="text-purple-m-main-light text-h6 font-bold h-16">
                                        <th class="w-56 px-6 text-left">Deliverable</th>
                                        <th class="w-56 px-6 text-left">Type</th>
                                        <th class="w-56 px-6 text-left">Status</th>
                                        <th v-if="!isGuest && !isClient" class="w-56 px-6 text-left">Payment</th>
                                        <th class="w-56 px-6 text-left">Due Date</th> 
                                        <th class="w-12 px-6 text-left"></th>                                   
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(userDeliverable, i) in userDeliverables(user.project_user)" :key="i" class="text-purple-m-secondary text-h6 h-20 border-b border-gray-m-light">
                                        <td class="px-6">
                                            <base-button type="label" class="w-full" justify="start"
                                                @action="$router.push(`/deliverables/${userDeliverable.id}`)"
                                                :label="userDeliverable.name && userDeliverable.name !== '' ? userDeliverable.name : `${userDeliverable.deliverable.name} Deliverable`"
                                            />
                                        </td>
                                        <td class="px-6">{{userDeliverable.deliverable.name}}</td>
                                        <td class="px-6">{{deliverablePhasesProgress(userDeliverable)}}</td>
                                        <template v-if="!isGuest && !isClient">
                                            <td class="px-6 font-bold" v-if="!!user.project_user.fixed_amount">{{ (user.project_user.fixed_amount / userDeliverables(user.project_user).length) | numeral('$0,0') }}</td>
                                            <td class="px-6" v-else-if="userDeliverable.budget">{{ userDeliverable.budget | numeral('$0,0') }}</td>
                                            <td class="px-6" v-else >No budget yet</td>
                                        </template>
                                        <td class="px-6" v-if="!!userDeliverable.end_date"><time datetime="2020-11-18">{{ userDeliverable.end_date | moment('M/D/Y') }}</time></td>
                                        <td class="px-6" v-else>No due date yet</td>
                                        <th class="w-12">
                                            <options-dropdown                
                                                :options="[
                                                    {label: 'Edit', icon: 'pencil-alt', event:'edit', disabled: !isAdminOrHigher, disabledTooltip: tooltip},
                                                    {label: 'Duplicate', icon: 'duplicate', event: 'duplicate', separator: true, disabled: !isAdminOrHigher, disabledTooltip: tooltip},
                                                    {label: 'Delete', icon: 'trash', event: 'delete', theme: 'cancel', disabled: !isAdminOrHigher, disabledTooltip: tooltip},
                                                ]"
                                                @edit="$emit('user-deliverable-option', userDeliverable, 'edit', !!user.project_user.fixed_amount)"
                                                @duplicate="$emit('user-deliverable-option', userDeliverable, 'duplicate')"
                                                @delete="$emit('user-deliverable-option', userDeliverable, 'delete')"
                                                v-if="!disabled && !isGuest && !isClient"
                                            />
                                        </th>
                                    </tr>
                                    <tr class="h-16" v-if="!disabled">
                                        <td class="px-6">
                                            <base-button
                                                v-tooltip="!isAdminOrHigher ? tooltip : null"
                                                :disabled="!isAdminOrHigher || isGuest || isClient"
                                                @action="emitAdd(project.id, user.project_user.id, !!user.project_user.fixed_amount)"
                                                type="label"
                                                icon="plus"
                                            >Add Deliverable</base-button>
                                        </td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-else>
                <tbody>
                    <tr class="text-gray-m-disable text-h6 border-b border-gray-m-light h-20">
                        <td class="px-6" :colspan="3+project.columns.length">
                            No creators assigned to this project
                        </td>                        
                    </tr>                    
                </tbody>
            </template>
        </table>

        <base-modal
            :visible="selectedProjectUser !== null"
            @close="selectedProjectUser = null"
            title="Single Amount"
        >
            <div class="flex flex-col gap-4">
                <div>If you change this creator to a single amount for all deliverables you won’t be able to add  amounts to each deliverable added to this creator.</div>
                <div class="field">
                    <div class="text-h6 font-bold">Enter  amount for all deliverables</div>
                    <FormulateInput
                        type="text"
                        placeholder="0.00"
                        @input="onFixedAmountInput"
                        :options="{ prefix: true }"
                        v-model="fixed_amount"
                    />
                </div>
                <div class="flex flex-row-reverse">
                    <base-button label="Save" @action="saveFixedAmount"/>
                </div>
            </div>
        </base-modal>
    </div>
</template>

<script>
import AvatarList from './AvatarList';
import { mapGetters } from 'vuex';
import { formatBudget, budgetToDouble } from '../lib/strings'

export default {    
    components: {
        AvatarList
    },
    
    props: {        
        project: {
            type: [Object, String],
            required: true
        },
        isUnique: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        pinnedUser: {
            type: Number,
            default: 0,
        },
        fixedProject: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loading: false,    
            columns: [],
            tooltip: {
                content: 'Collaborators are not allowed to perform this action.',
                classes: 'bg-black text-white rounded py-1 mb-2 mr-16 px-2 text-xs',
                delay: {
                    show: 100,
                    hide: 50
                }
            },
            fixed_amount: 0,
            selectedProjectUser: null,
            savingUserColumn: false
        }
    },
    mounted() {
        this.getData();
        this.runPinned();
    },
    watch: {
        'project': {
            deep: true,
            handler: function (val, old) {
                if (val.columns && val.columns !== old.columns) {
                    this.getData();
                }
                else if(!val.columns){
                    this.columns = []
                }
            }
        },
    },
    computed: {
        ...mapGetters(['isAdminOrHigher', 'isGuest', 'isClient']),
        isFixed() {
            return this.project.id === this.fixedProject;
        }
    },
    methods: {
        onFixedAmountInput(val) {
            this.fixed_amount = formatBudget(val)
        },
        setFixedAmount(project_user) {
            this.selectedProjectUser = {...project_user};
            this.fixed_amount = this.selectedProjectUser.fixed_amount;
        },
        async saveFixedAmount() {
            if (this.selectedProjectUser !== null) {
                let payload = {
                    project_user: this.selectedProjectUser.id,
                    amount: budgetToDouble(this.fixed_amount)
                };

                await this.$http.post(`/api/userDeliverables/setFixedAmount`, payload);
                this.selectedProjectUser = null;
                this.fixed_amount = 0;
                this.$notify({ title: 'Amount saved', text: 'The amount was saved successfully', type: 'success' });
                this.$emit('refresh');
            }
        },
        emitAdd(projectId, projectUser, fixed) {
            if (this.isAdminOrHigher) {
                this.$emit('add-deliverable', projectId, projectUser, fixed);
            }
            else {
                this.$notify({ title: 'Warning', text: 'Only for admins', type: 'warn' });
            }
        },
        deliverablePhasesProgress(deliverable){            
            switch(deliverable.phases_progress){
                case 0:
                    return 'Concept Phase';
                case 10:
                    return 'Draft Phase';
                case 20:
                    if(deliverable.posts_needed != null & deliverable.posts_needed > 0 && deliverable.project_user_deliverable_posts != null){
                        if(deliverable.project_user_deliverable_posts.length < deliverable.posts_needed)
                            return 'Waiting For Posts';
                        else
                            return 'Completed'
                    }
                    else
                        return 'Completed';
                default:
                    return 'Default Phase';
            }
        },
        runPinned() {
            if (this.pinnedUser) {
                for (let i in this.project.users) {
                    if (this.project.users[i] && this.project.users[i].project_user) {
                        let isOpen = this.project.users[i].id === this.pinnedUser;
                        this.project.users[i].project_user.showUserDeliverables = isOpen;

                        if (isOpen) {
                            window.location.hash = `#user-${this.pinnedUser}`;
                        }
                    }
                }
            }
        },
        userDeliverables(project_user) {
            if (this.project.project_users !== undefined) {
                let user = this.project.project_users.find(user => user.id === project_user.id);
                if (user) {
                    return user.userDeliverables;
                }
                return [];
            }

            return project_user.userDeliverables ? project_user.userDeliverables : [];
        },
        async getData(showLoader = true) {
            this.loading = showLoader;
            // get columns for each project user
            this.columns = [];

            const { data } = await this.$http.get(`/api/userDeliverables/projectUserColumns/project/${this.project.id}`);

            if(this.project.users){
                this.project.users = await Promise.all(this.project.users.map(async (user) => {
                    user.project_user.columns = await this.getColumns(user.project_user.id, data);
                    if (user.project_user.userDeliverables === undefined) {
                        user.project_user.userDeliverables = [];

                        if (this.project.project_users !== undefined) {
                            let p_user = this.project.project_users.find(p_user => p_user.user_id === user.id);
                            if (p_user) {
                                user.project_user.userDeliverables = p_user.userDeliverables;
                            }
                        }
                    }
                    return user;
                }));       
                // set columns after promises resolve to keep the right order for the rows
                this.columns = this.project.users.map(user => {
                    return user.project_user.columns;
                });
            }
            else if(this.project.project_users){
                this.project.project_users = await Promise.all(this.project.project_users.map(async (user) => {
                    user.columns = await this.getColumns(user.id, data);
                    return user;
                }));
                // set columns after promises resolve to keep the right order for the rows
                this.columns = this.project.project_users.map(user => {
                    return user.columns;
                });
            }
                        
            this.loading = false;
        },
        async getColumns(projectUserId, data = []) {
            let columns = [];
            if (this.project.columns !== undefined && this.project.columns.length) {
                this.project.columns.forEach(cc => {
                    const col = data.find(d => d.project_user_column_name_id === cc.id && d.project_user_id === projectUserId);

                    columns.push({
                        id: col ? col.id : null,
                        project_user_id: projectUserId,
                        project_user_column_name_id: cc.id,
                        column_type_id: cc.column_type_id,
                        data: col ? col.data : {"value": ""},
                        edit: false,
                    });
                });
            }
            return columns;
        },
        enableEditUserColumn(userIndex, columnIndex){
            // close all columns for edit
            for(var i = 0; i < this.columns.length; i++) {
                var user = this.columns[i];
                for(var j = 0; j < user.length; j++) {
                    user[j].edit = false;
                }
            }
            // open selected column for edit
            this.columns[userIndex][columnIndex].edit = true;
        },
    }
}
</script>