<template>
    <div class="content">
        {{message}}
        <div style="width: 300px;">
            <facebook-login :disabled="loading" @success="linkAccount"/>
        </div>
    </div>
</template>
<script>
import FacebookLogin from '@/components/FacebookLogin/index'
import { mapGetters } from 'vuex';
export default {
    components: {
        FacebookLogin
    },
    data(){
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters(['fbLogin']),
        message(){
            return this.fbLogin.computedMessage ? this.fbLogin.computedMessage('view Facebook business information') : '' 
        }
    },
    methods: {
        async linkAccount(data) {
            const { userId, accessToken, email, networkId } = data;
            this.loading = true;
            this.$http.post(`/api/auth/register/link-account`, {
                network_id: networkId,
                profile_id: userId,
                email,
                ...(accessToken ? { access_token: accessToken } : {})
            }).then(response => {
                this.$store.dispatch('fb/getLoginStatus');
                this.$notify({ title: 'Success', text: `Account Linked Successfully`, type: 'success' });
                // this.$emit('reload');
            }).catch((err) => {
                this.$notify({ title: 'Error', text: `${(err.response && err.response.data) ? err.response.data.message : ``}`, type: 'error' });
                console.log('linkAccount error', err)
            }).finally(() => {
                this.loading = false;
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .content{
        @apply px-12 py-10 flex-shrink flex-grow;
        @apply overflow-y-auto;
        height: calc(100vh - 190px);
    }
</style>