<template>
<div class="social-login-container">

    <div class="header">
        <img class="logo" src="../../assets/images/logo.svg" />
        <h2 class="title">
            Connect your {{invitation.network ? invitation.network.display_name : 'social'}} account 
            <template v-if="isValidString(handle)">for <span class="text-green-m-main">"{{handle}}"</span></template>
            with <span class="text-green-m-main">{{ (invitation.company && invitation.company.name) ? invitation.company.name : 'creatorsaurus' }}</span> 
        </h2>
        <div class="host">{{host}}</div>
    </div>

    <div class="form-wrapper">

        <div class="form-container">

            <alert class="mb-8" type="danger" v-if="error">
                {{ error }}
                <div v-if="error.includes('You have already linked this facebook account')">
                        You can try <a
                            @click.prevent="$router.push(`/login?t=${$route.query.t}`)" 
                            href="#"
                            :class="`
                                text-sm leading-5 font-medium text-gray-m-dark 
                                hover:opacity-75 transition-opacity 
                                focus:outline-none focus:underline
                            `"
                        >
                            Logging In
                        </a>
                </div>
            </alert>

            <FormulateForm 
                class="form" 
                name="social-login-form" 
                @submit="login"
            >

                <FormulateInput
                    type="email"
                    label="Email address"
                    v-model="email"
                    :disabled="invitation.target || error"
                    :readonly="invitation.target || error"
                    autocomplete="off"
                    validation="required|email"
                    validation-name="Email"
                    class="w-full mt-6"
                />

                <facebook-login
                    v-if="networkId == 6"
                    label="Login with Facebook"
                    class="mb-6"
                    :handle="handle"
                    @success="onFacebookLoginSuccess"
                />

                <tiktok-login
                    v-if="networkId == 14"
                    label="Login with Tiktok"
                    :target-user-id="targetUserId"
                    @success="onTikTokLoginSuccess"
                    @loading="val => loading = val"
                    @error="handleError"
                />

                <!--

                    <google-login
                        v-if="!networkId"
                        label="Login with Google"
                        @success="onGoogleLoginSuccess"
                    />

                    <twitter-login
                        v-if="!networkId"
                        label="Login with Twitter"
                        @success="onTwitterLoginSuccess"
                    />

                -->

            </FormulateForm>

        </div>
    </div>
</div>
</template>

<script>
import { isValidString } from '../../common';
import Alert from '../../components/Alert';
import FacebookLogin from '../../components/FacebookLogin'
import TiktokLogin from '../../components/TiktokLogin.vue'
export default {
    components: {
        Alert,
        FacebookLogin,
        TiktokLogin
    },
    data() {
        return {
            invitation: {},

            email: '',
            handle: null,  // if there is a target user id we save the handle to match it when a fb login is done

            error: null,
            loading: false,

            targetUserId: null,
            networkId: null,
            profileId: null,
            socialEmail: null,
            accounts: null,
            accessToken: null,
            
            isValidString
        }
    },

    beforeMount(){
        this.$store.dispatch('auth/logout');
        this.$setTitle('Social Login')
    },

    mounted(){       
        let token = this.$route.query.t
        if(token){
            this.getInvitation()
        }else{
            this.error = 'An invitation is required to connect your account with a social platform'
        }
    },

    computed: {
        host(){
            return window.location.host
        }
    },

    methods: {

        async getInvitation() {
            try {
                const { data } = await this.$http.get(`/api/invite/${this.$route.query.t}`);
                if(data){
                    this.invitation = data
                    if(data.target){
                        this.targetUserId = data.target.id
                        this.email = data.target.email
                        // TODO should also work if they have multiple handles.
                        const igNetworkUser = data.target.social_accounts?.find(element => element.network_id === 2)
                        if(igNetworkUser && igNetworkUser.handle){
                            this.handle = igNetworkUser.handle
                        }
                    }
                    if(data.network_id){
                        this.networkId = data.network_id
                    }
                }
            }
            catch(e) {
                console.log(e);
                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }
                this.error = 'An unknown error occurred. Please try again.';
            }
        },

        handleError(e) {
            console.log(e);
            if (e.response && e.response.data) {
                this.error = e.response.data.message;
            } else {
                this.error = 'An unknown error occurred. Please try again.';
            }
        },

        async login() {
            try {
                this.loading = true
                this.error = null

                const { data } = await this.$http.post(`/api/auth/login`, { 
                    email: this.email,
                    ...(this.networkId ? { 
                        user_id: this.targetUserId,
                        network_id: this.networkId, 
                        profile_id: this.profileId,
                        social_email: this.socialEmail,
                        accounts: this.accounts,
                        access_token: this.accessToken
                    } : {})
                })

                if (data && data.access_token) {

                    await this.$store.dispatch('auth/login', data)

                    // accept the invitation (if it exists) once the user is logged in
                    // and do the login again to save the new company on the jwt
                    if(this.invitation && this.invitation.active && this.invitation.company){
                        await this.acceptInvitation()
                        this.invitation.active = false
                        return this.login()
                    }

                    await this.$store.dispatch('auth/getMe')
                    await this.$store.dispatch('colors/getColors')
                    await this.$store.dispatch('statuses/getStatuses')
                    await this.$store.dispatch('boards/getBoards')
                    await this.$store.dispatch('company/getCompany')
                    await this.$store.dispatch('fb/getLoginStatus');
                    this.$store.dispatch('fb/generateAudienceData', data.id);
                    this.$router.push('/home')
                }
            } catch(e) {
                
                console.log(e)

                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }

                this.error = 'An unknown error occurred. Please try again.';
            } finally {
                this.loading = false
            }
        },

        async acceptInvitation(){
            try {
                const { data } = await this.$http.post(`/api/invite/${this.$route.query.t}/accept`)
                console.log(data)
            }
            catch(e){
                console.log(e);
                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }
                this.error = 'An unknown error occurred. Please try again.';
            }
        },

        onFacebookLoginSuccess(data){
            this.setSocialLogin(6, data, true);
        },

        onTikTokLoginSuccess(data) {
            this.setSocialLogin(14, data, true);
        },

        setSocialLogin(networkId, data, login = false) {
            this.profileId = data.userId
            this.socialEmail = data.email
            this.accessToken = data.accessToken
            this.accounts = data.accounts ? data.accounts : null
            if (login) {
                this.login()
            }
        }

    }
}
</script>
<style lang="scss" scoped>
    .social-login-container {
        @apply min-h-screen bg-purple-m-main flex flex-col justify-center overflow-y-auto;
    }
    .header {
        @apply mx-auto w-full max-w-md;
        .logo {
            @apply mx-auto h-12 w-auto;
        }
        .title {
            @apply mt-6 text-center text-2xl leading-9 font-extrabold text-white;
        }
        .host {
            @apply flex justify-center mt-2 text-sm text-gray-m;
        }
    }
    .form {
        @apply flex flex-col items-center;
    }

    .form-wrapper{
        @apply mt-8 mx-auto w-full max-w-md;
        .form-container{
            @apply bg-white py-8 px-4 shadow rounded-lg px-10;
        }
    }

</style>