<template>
<main class="project-settings flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="px-4 py-10 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div class="flex-1 min-w-0">
            <h1 class="text-2xl font-bold text-black sm:truncate">
               Edit {{ displayName }}
            </h1>
        </div>
    </div>

    <div class="px-8 py-4 w-1/2">
            
        <div class="mb-4">
            <div class="font-semibold text-2xl text-black">General Information</div>
        </div>

        <FormulateForm
            name="form"
            @submit="submit()"
            v-model="user"
            class="flex flex-col gap-y-4"
        >

            <avatar
                class="max-w-none h-6 w-6 rounded-full text-white shadow-solid"
                :user="user"
                size="x-large"
            />


            <FormulateInput
                type="text"
                validation="required"
                name="first_name"
                label="First Name"
                placeholder="First Name"
                validation-name="First name"
            />

            <FormulateInput
                type="text"
                validation="required"
                name="last_name"
                label="Last Name"
                placeholder="Last Name"
                validation-name="Last name"
            />

            
            <FormulateInput
                type="email"
                validation="required"
                name="email"
                label="Email"
                placeholder="Email"
                validation-name="Email"
            />


            <base-button
                :disabled="loading" 
                @action="$formulate.submit('form')"
                class="mt-4"
                submit
                label="Save"
            />

        </FormulateForm>


        <div class="mt-10 mb-4">
            <div class="font-semibold text-2xl text-black">Change Password</div>
        </div>

        <FormulateForm
            name="change-password-form"
            @submit="changePassword()"
            class="flex flex-col gap-y-4"
        >
            
            <FormulateInput
                type="password"
                validation="required"
                v-model="newPassword"
                label="New Password"
                placeholder="New Password"
                validation-name="New Password"
            />


            <base-button
                :disabled="changePasswordLoading" 
                @action="$formulate.submit('change-password-form')"
                class="mt-4"
                size="lg"
                submit
                label="Change Password"
            />

        </FormulateForm>

    </div>

</main>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            user: {},
            loading: false,

            newPassword: '',
            changePasswordLoading: false
        }
    },

    beforeMount() {
        this.$setTitle('Edit Creator');
    },

    mounted(){
      this.getUser()
    },

    computed: {
        ...mapGetters(['isAdmin', 'isSuperAdmin']),
        displayName(){
            return (this.user.first_name || this.user.last_name ) ? `${this.user.first_name || this.user.last_name}` : 'User'
        }
    },

    methods: {
        async getUser() {
            this.loading = true
            const { data } = await this.$http.get(`/api/users/${this.$route.params.userId}`);
            this.user = data
            this.loading = false
        },

        async submit(){
            this.loading = true
            const { data } = await this.$http.put(`/api/users/${this.$route.params.userId}`, {
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                email: this.user.email
            });
            if(data){
                this.$notify({ title: 'Success', text: 'User updated successfully', type: 'success' });
            }
            this.loading = false
        },

        async changePassword() {
            this.changePasswordLoading = true
            const { data } = await this.$http.put(`/api/users/${this.$route.params.userId}/change-password`, {
                password: this.newPassword
            });
            if(data){
                this.$notify({ title: 'Success', text: 'Password changed successfully', type: 'success' });
            }
            this.changePasswordLoading = false
        }
    }
}
</script>
