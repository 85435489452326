<template>
    <base-modal
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="flex flex-col justify-center items-center">
            <avatar 
                :user="creator"
            />
            <div class="text-ps font-semibold purple-m-main mt-6">Optional</div>
            <div class="text-h1 font-bold text-purple-m-secondary text-center mt-4">Would you like to assign this creator to the project?</div>
            <div class="py-3 mt-4 flex gap-3 justify-end">
                <base-button 
                    label="Assign Creator"
                    class="font-semibold"
                    @action="assignCreatedCreatorToProject"
                />
                <base-button 
                    label="No Thanks"
                    type="secondary"
                    class="font-semibold"
                    @action="$emit('done')"
                    size="md"
                />
            </div>
        </div>
    </base-modal>
</template>

<script>
export default {
    props: {
        projectId: {
            type: [String, Number],
            required: false
        },
        creator: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },

    data(){
        return {
            loading: false
        }
    },

    methods: {
        async assignCreatedCreatorToProject() {
            this.$http.post(`/api/projects/${this.projectId}/assign-creator`, {
                id: this.creator.id
            }).then(res => {
                this.$emit('done')
                this.$notify({ title: 'Success', text: 'Creator assigned successfully', type: 'success' })
            }).catch((err) => {
                this.$notify({ title: 'Error', text: 'Error trying to assign creator', type: 'error' })
                console.error(err);
            });
        }
    }

}
</script>