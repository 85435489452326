<template>
    <div class="default-container">

        <sidebar 
            @close="sidebarOpen = false"
        />

        <!-- Main column -->
        <div class="default-content">
            <profile-completion />
            <creator-onboarding />
            <slot />
        </div>
    </div>  
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import ProfileCompletion from '@/components/ProfileCompletion.vue';
import CreatorOnboarding from '@/components/CreatorOnboarding/index.vue';
import { mapGetters } from 'vuex';
import CreatorHome from '../views/CreatorHome';
export default {
    components: {
        Sidebar,
        ProfileCompletion,
        CreatorOnboarding,
        CreatorHome
    },

    data() {
        return {
            sidebarOpen: false,
            loading: null,
        }
    },

    beforeMount () {
        this.loading = this.$loading;
    },

    watch: {
        loader (val) {
            if (val) {
                this.loading = this.$loading.show();
            } else {
                if (this.loading !== null) {
                    this.loading.hide();
                }
            }
        },

        restorer: {
            deep: true,
            handler: function (val) {
                if (val.show) {
                    let notification = val.notification !== undefined ? val.notification : { title: 'Success', text: val.message, type: 'success' };

                    if (val.action) {
                        let fn = async () => {
                            try {
                                if (val.route !== undefined) {
                                    const {data} = await this.$http.post(val.route, val.data);
                                    if (data) {
                                        this.$notify({title: 'Success', text: val.successed, type: 'success'});
                                        if (val.fn) {
                                            let payload = 0;
                                            if (val.receivePayload) {
                                                payload = data;
                                            }
                                            val.fn(payload);
                                        }
                                    }
                                } else {
                                    if (val.fn) {
                                        val.fn(0);
                                    }
                                }
                            } catch (e) {
                                console.log(e);
                                this.$notify({ title: 'Warning', text: val.failed, type: 'warn' });
                            }
                        };

                        notification.data = {undo: true, fn};
                        if (val.data && val.data.undoLabel !== undefined) {
                            notification.data.undoLabel = val.data.undoLabel;
                        }
                        notification.duration = 6000;
                    }
                    this.$notify(notification);

                    if (val.forceFn && val.fn) {
                        val.fn();
                    }
                }
            }
        }
    },

    computed: {
        ...mapGetters(['user', 'loader', 'restorer']),
    }
}
</script>

<style lang="scss">
.default-container{
    @apply flex h-screen overflow-hidden bg-white;
}
.default-content{
    @apply bg-white flex flex-col w-0 flex-1 overflow-hidden;
}
.breadcrumb a {
    @apply text-gray-500;

    &:hover {
        @apply text-gray-800;
    }
}

</style>
